import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { shareReplay, tap } from 'rxjs/operators';

import * as moment from 'moment';

import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';

import { environment } from '../../environments/environment';

import { LinkedUserData } from '../model/linkeduserdata.model';
import { LinkedUserInfo } from '../model/linkeduserinfo.model';

import { UserInfo } from '../model/userinfo.model';
import { CountData } from '../model/countdata.model';

@Injectable()
export class UserSubAccountService {

    SubuserSystemCnt: CountData = null;

    private subuserSystemCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.SubuserSystemCnt);

    subuserSystemCount$: Observable<CountData> = this.subuserSystemCountSubj$.asObservable();

    private userSubAcctUpdates: Subject<any> = new Subject<any>();

    private userSubAcctList: any[];

    // CJ - Add and Delete operations will sendUserSubAcctUpdates(array) to
    // send the group id for the updated member list as an observable.
    // Components using the service can subscribe using the
    // getUserSubAcctUpdates() method to receive a refreshed list of
    // UserInfo[] that is the user sub account list.

    sendUserSubAcctUpdate(slist: any) {
       this.userSubAcctUpdates.next(slist);
    }
    clearUserSubAcctUpdates() {
      this.userSubAcctUpdates.next(null);
    }
    getUserSubAcctUpdates(): Observable<any> {
      return this.userSubAcctUpdates.asObservable();
    }

    constructor(private http: HttpClient) { }

//*********** Linked User Functions

    getAllLinkedUsers(owner_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser')
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserData[];
                    },
               (e) => {
                        console.log('user-subaccount.service: getAllLinkedUsers',  e);
                        throw e;
                    });
    }

    getLinkedUserGuests(owner_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/' + owner_uid)
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserData[];
                    },
               (e) => {
                        console.log('user-subaccount.service: getLinkedUserGuests',  e);
                        throw e;
                    });
    }

    getLinkedUserGuest(owner_uid, users_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/guest/' + owner_uid + '/' + users_uid)
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserData[];
                    },
               (e) => {
                        console.log('user-subaccount.service: getLinkedUserGuest',  e);
                        throw e;
                    });
    }

    getIsLinkedUser(users_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/guest/find/' + users_uid)
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserData;
                    },
               (e) => {
                        console.log('user-subaccount.service: getLinkedUserGuest',  e);
                        throw e;
                    });
    }

//*********** Linked User Info Functions

    getAllLinkedUserInfo(owner_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/info')
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserInfo[];
                    },
               (e) => {
                        console.log('user-subaccount.service: getAllLinkedUsers',  e);
                        throw e;
                    });
    }

    getLinkedUserInfoGuests(owner_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/info/' + owner_uid)
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserInfo[];
                    },
               (e) => {
                        console.log('user-subaccount.service: getLinkedUserGuests',  e);
                        throw e;
                    });
    }


    getLinkedUserGuestInfo(owner_uid, users_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/info/guest/' + owner_uid + '/' + users_uid)
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserInfo[];
                    },
               (e) => {
                        console.log('user-subaccount.service: getLinkedUserGuest',  e);
                        throw e;
                    });
    }

    getIsLinkedUserInfo(users_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/info/guest/find/' + users_uid)
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    return x as LinkedUserInfo[];
                    },
               (e) => {
                        console.log('user-subaccount.service: getIsLinkedUserInfo',  e);
                        throw e;
                    });
    }

    //*********** User Subaccount Functions

    getUserSubAccts(owner_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/' + owner_uid)
          .toPromise()
          .then(
               (x) => {
                    // console.log('user-subaccount.service: getUserSubAccounts success =', x);
                    // console.log('x=', x);
                    this.userSubAcctList = x as UserInfo[];
                    this.sendUserSubAcctUpdate(this.userSubAcctList);
                    return this.userSubAcctList;
                    },
               (e) => {
                        console.log('user-subaccount.service: getUserSubAccounts error =', e);
                        throw e;
                    });
    }


    getOwnerSubAcctsCount(owner_uid) {
       return this.http.get(environment.apiUrl + '/linkeduser/count/owner/' + owner_uid)
          .toPromise()
          .then(
               (x) => {
                    return x as CountData;
                    },
               (e) => {
                        console.log('user-subaccount.service: getOwnerSubAccountCount error =', e);
                        throw e;
                    });
    }

    getUserSubAcct(sub): Promise<UserInfo> {
            return this.http.get(environment.apiUrl + '/user/' + sub)
            .toPromise()
            .then(
                (x: UserInfo) => {
                   return x as UserInfo;
                },
                (e) => {
                   console.log('ERROR: ', e);
                   throw e;
                   }
            );
    }

    addUserSubAccount(owner_uid, userEmail, grp_admin=false): Promise<any> {
        console.log('usrsubacctsvc addSub owner_uid=', owner_uid);
        console.log('usrsubacctsvc addSub userEmail=', userEmail);
        console.log('usrsubacctsvc addSub grp_admin=', grp_admin);
        const data = {
            owner_uid: owner_uid,
            email: userEmail,
            group_admin: grp_admin
        };
        //return this.http.put(environment.apiUrl + '/linkeduser/' + owner_uid, data)
        return this.http.post(environment.apiUrl + '/linkeduser',  data)
        .toPromise()
        .then(
            (x) => {
                this.sendUserSubAcctUpdate(this.userSubAcctList);
                return x;
            },
            (e) => {
                  console.log('user-subacct service error addSub(ix) user ',
                  userEmail);
                  throw e;
            }
        );
    }

    addUserSubAccountCaptcha(owner_uid, userEmail, recaptcha): Promise<any> {
        console.log('usrsubacctsvc addSub userInfo uuid=', owner_uid);
        console.log('usrsubacctsvc addSub userEmail=', userEmail);
        const data = {
            owner_uid: owner_uid,
            email: userEmail,
            captcha: recaptcha
        };
        return this.http.put(environment.apiUrl + '/linkeduser/' + owner_uid, data)
        .toPromise()
        .then(
            (x) => {
                this.sendUserSubAcctUpdate(this.userSubAcctList);
                return x;
            },
            (e) => {
                  console.log('user-subacct service error addSub(ix) user ',
                  userEmail);
                  throw e;
            }
        );
    }

    removeUserSubAcct(owner_uid, subuid) {
        console.log('user-subacct service REMOVE', subuid);

        return this.http.delete(environment.apiUrl + '/linkeduser/' + owner_uid + '/' +  subuid).toPromise()
        .then(
              (x) => {
                  this.sendUserSubAcctUpdate(this.userSubAcctList);
                  console.log('user-subacct service removed subacct: ', subuid);
                  return x;
              },
              (e) => {
                console.log('user-subacct service error removing subacct: ', e);
                throw e;
              }
        );
    }

  // *** Stats methods

   getUserSubacctSystemCount$(){
      this.http.get<any>(environment.apiUrl + '/linkeduser/count/system')
      .subscribe(cnt => {
                       console.log('subuser cnt =', cnt);
                       this.subuserSystemCountSubj$.next(cnt);
                       return cnt as CountData;
             })
   }

} // UserSubaccountService

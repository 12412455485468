export const appinfo = {
    name: 'markadoc',
    version: '1.0.7.0',
    build_date: '20241113'
}

// maybe add build variable set by gitlab build pipeline to the above when
// we get more dev going for traceability. Or maybe commit tags.

// Note: dont read name and version from package.json as it is a vulnerabiity.

import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Component, OnInit, Input, Output, ViewChild, AfterViewInit,
         ViewContainerRef, Inject, EventEmitter, ElementRef, OnChanges,
         SimpleChanges, OnDestroy } from '@angular/core';
import { MatDialogModule, MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { Form, FormBuilder, FormGroup } from '@angular/forms';


// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectionList } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
// import { SubaccountEditComponent } from '../subaccount-edit/subaccount-edit.component';

import { GroupData } from '../model/groupdata.model';
import { GroupMember } from '../model/groupmember.model';

// import { FileSelector} from '../../model/fileselector.model';

import { MarkerData } from '../../model/markerdata.model';
import { MarkerComment } from '../../model/markercomment.model';
import { MarkerApproval } from '../../model/markerapproval.model';

import { AuthService } from '../../service/auth.service';
import { GroupService } from '../../service/group.service';
import { GroupMemberService } from '../../service/group-member.service';
import { UserService } from '../../service/user.service';
import { ToasterService } from '../../service/toaster.service';
// import { environment } from '../../environments/environment';


import { ActionPromptDialogComponent } from '../../dialog/action-prompt/action-prompt.dialog';
import { UserInfoDialogComponent } from '../../dialog/user-info/user-info.dialog';

// import { UserSubAccountService } from '../../service/user-subaccount.service';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';


export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

export const EDIT_MEMBERS    = 1;
export const ADD_MEMBER      = 2;
export const ADD_MULTIPLE    = 3;
export const SIDENAV_MEMBERS = 4;
export const MAT_NAV_LIST    = 5;
export const EDIT_APPROVALS  = 6;
export const OLD_MAT_NAV_LIST = 99;

export interface MemberData {
  uid: string;
  groups_uid: string;
  name: string;
  last_name: string;
  email: string;
  company: string;
  phone: string;
}

export interface GroupMemberInput {
   organization?: any;
   groupList?: GroupData[];
   groupUID?: string;
   groupMembers?: MemberData[];
}


@Component({
   selector: 'app-group-member-list',
   templateUrl: './group-member-list.component.html',
   styleUrls: ['./group-member-list.component.css'],
})
export class GroupMemberListComponent implements OnChanges, OnDestroy,
             AfterViewInit {
   @Input()  template: number;
   @Input()  orgUID: string;
   @Input()  userData: UserData;
   @Input()  userInfo: UserInfo;
   @Input()  group: GroupData;
   @Input()  groupUID: string;
   @Input()  groupList: GroupData[];
   @Input()  itemData: MarkerData;
   @Input()  approvalScope: string;
   @Input()  isPolling = false;   
   @Input()  pollData = true;
   @Input()  pollInterval = 5000;

   // @Output() getMembersEvent = new EventEmitter<any>();
   @Output() addMemberEvent = new EventEmitter<any>();
   @Output() removeMemberEvent = new EventEmitter<any>();

   @Output() refreshMembersEvent = new EventEmitter<any>();
   @Output() refreshSubsEvent = new EventEmitter<any>();
   @Output() countMembersEvent = new EventEmitter<number>();
   @Output() displayUserInfoEvent = new EventEmitter<any>();
   @Output() selectMembersEvent = new EventEmitter<any>();

   @ViewChild('memberlist', {static: false}) memberListView: ElementRef;
   @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild('grpMemberList', {static: false}) grpMemberList: MatSelectionList;

   // Constants
   EDIT_MEMBERS    = 1;
   ADD_MEMBER      = 2;
   ADD_MULTIPLE    = 3;
   SIDENAV_MEMBERS = 4;
   MAT_NAV_LIST    = 5;
   EDIT_APPROVALS  = 6;
   OLD_MAT_NAV_LIST = 99;

   // Service Messaging Subscription
   memberSubscription: Subscription;

   // Table Data
   displayedColumns = ['email', 'full_name', 'company', 'role', 'remove'];
   // displayedMemberColumns = ['email', 'full_name', 'company', 'role', 'access', 'privs', 'remove'];
   // dataSource = new MatTableDataSource<any>();;
   dataSource: any;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;

   // dialogs
   dialogRef: any = null;
   actionPromptDialog: MatDialog = null;

   // View vars
   toggleFiberMembers = false;
   isMembersVisible = false;
   emailText: any;
   emailAddress: string;
   stepMessage: string;

   // Instance vars
   emailAddressList: string[];
   userUID: string;
   // userData: UserData;
   userSubList: any;
   userSubLimit: number;
   groupName: string;

   loading: boolean;

   updateInterval: any;

   newUserCnt: number;
   newMemberCnt: number;
   newUserErr: number;
   newMemberErr: number;

   // group member info
   groupMembers: GroupMember[];
   selectedGroupMemberObj: GroupMember;
   selectedGroupMember: string;
   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;
   selectedName;
   selectedGroupObj: any;

   constructor(
      private auth: AuthService,
      private groupsvc: GroupService,
      private groupmembersvc: GroupMemberService,
      private usersvc: UserService,
      private toast: ToasterService,
      public userInfoDialog: MatDialog,      
      ) {
           if ( !this.groupUID && !this.group) {
              console.log('group-member this.groupUID is undefined!');
              return;
           }

           if (this.groupList) {
              console.log('group-member this.groupList.length=',
                          this.groupList.length);
           } else {
             console.log('GroupMemberComponent WARNING groupList not defined, using group svc..');
             }
           if (this.userInfo) {
                 this.userSubLimit = this.userInfo.accounts;
                 console.log('GM uinfo=', this.userInfo);
          } else {
                  console.log('GroupMemberComponent WARNING userInfo not defined.');
                  // this.userInfo = await this.usersvc.getUserInfo(this.userData.uid);
                 }
          if (this.group) {
                 console.log('GM group=', this.group);
          } else {
                  console.log('GroupMemberComponent WARNING group not defined.');
                 }
          if (this.groupUID) {
                 console.log('GM groupUID=', this.groupUID);
          } else {
                  console.log('GroupMemberComponent WARNING groupUID not defined.');
                 }

      } // constructor

   async ngAfterViewInit() {

      // console.log('GMLC userInfo=', this.userInfo);
      // console.log('GMLC this.groupUID=', this.groupUID );

      if (this.userInfo) {
            this.userSubLimit = this.userInfo.accounts;
            console.log('GM uinfo=', this.userInfo);
      } else {
               console.log('GroupMemberComponent WARNING userInfo not defined.');
               this.userInfo = await this.usersvc.getUserInfo(this.userData.uid);
              }

      if (this.template === this.SIDENAV_MEMBERS) {
         this.scroll_up();
      }

      if (!this.groupUID && this.group) {
        this.groupUID = this.group.uid;
      }

      if (!this.groupList) {
        console.log('GroupMemberComponent groupList using group svc..');
        this.groupList = await this.groupsvc.getUserGroups(this.groupUID);
      }

      // Set the group info based on group UID
      if (this.groupUID || this.group) {
         this.groupChange(this.groupUID);
      }

     /****
      try {
        this.userSubList = this.get_subs(this.userInfo.uid);
        console.log('subs=', this.userSubList );
      } catch (e) {
           console.log('subs=', e );
        }
      ****/

      this.memberSubscription = this.groupmembersvc
                               .getGroupMemberUpdates().subscribe( gid => {
         console.log('groupmemberlist: groupUID = gid, refreshing..');
         if (gid) {
            if (this.groupUID === gid) {
               this.groupChange(gid);
            } else {
                 console.log('groupmembersvc: groupUID != gid');
              }
         } else {
             console.log('groupmembersvc: empty message sent.');
           }
      });

        // Get updates on updat eInterval every pollInterval milli-seconds..
        this.updateInterval = setInterval(
         () => {
            if (this.pollData && !this.isPolling) {
              this.isPolling = true;
              this.refreshList();
              this.isPolling = false;
            }
         }, this.pollInterval);
   }

   refreshList() {
      if ( this.group && this.group.uid ) {
         this.groupUID = this.group.uid;
      }
      if ( this.pollData && this.groupUID ) {
         this.groupChange(this.groupUID);
      }
   }

   // Take action if inputs change
   ngOnChanges(changes: SimpleChanges): void {
     // console.log('GMC ngOnChanges=', changes);
     // console.log('GMC groupUID=', this.groupUID);
     // console.log('GMC changes.currentValue', changes.currentValue);
     if (changes.groupUID) {
        this.groupUID = changes.groupUID.currentValue;
        this.groupChange(this.groupUID);
     }
     if (changes.group) {
        this.group = changes.group.currentValue;
        if (this.group && this.group.uid) {
           this.groupChange(this.group.uid);
        }
     }
   }

  ngOnDestroy() {
     // unsubscribe to ensure no memory leaks
     if (this.memberSubscription) {
        this.memberSubscription.unsubscribe();
     }
     clearInterval(this.updateInterval);
  }

  // Table manipulation functions
  //
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.  */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

   // Scroll functions
   // member list scroll
   scroll_down(){
      console.log('GMLC memberlistview = ', this.memberListView);
      const md = this.memberListView.nativeElement;
      const su = this.scrollUp.nativeElement;
      const sd = this.scrollDown.nativeElement;
      const scrollPosition = md.scrollTop;
      const scrollHeight = md.scrollHeight;
      // console.log('md = ', md);
      // console.log('su = ', su);
      // console.log('sd = ', sd);

      if ( scrollPosition === (scrollHeight - 135)){
          sd.style.opacity = '.4';
      } else {
          sd.style.opacity = '1';
      }
      su.style.opacity = '1';
      md.scrollTop += 20;
    }

    scroll_up(){
       console.log('memberlist = ', this.memberListView);
       const md = this.memberListView.nativeElement;
       const su = this.scrollUp.nativeElement;
       const sd = this.scrollDown.nativeElement;
       const scrollPositionTop = md.scrollTop;
       const scrollHeight = md.scrollHeight;
       // console.log('md = ', md);
       // console.log('su = ', su);
       // console.log('sd = ', sd);

       if (scrollPositionTop === 0 ) {
            su.style.opacity = '.4';
        } else {
            su.style.opacity = '1';
        }
       sd.style.opacity = '1';
       md.scrollTop -= 20;
    }

    show_members(guid){
        this.isMembersVisible = !this.isMembersVisible;
        this.groupChange(guid);
    }

    countMembers(){
        let cnt = 0;
        if (this.groupMembers != null && this.groupMembers.length > 0) {
              cnt = this.groupMembers.length;
        }
        this.countMembersEvent.emit(cnt);
        return cnt;
    }


   // Group selector change handler
   // Gets the groupData and modifies the members table data source
   //
   async groupChange(g) {
      // console.log('GMLC groupChange g = ', g);
      // console.log('GMLC groupList = ', this.groupList);

      // this.selectedGroup = g;

      if (!g) {
        this.selectedGroupObj = null;
        return;
      }

      this.selectedGroupObj = await this.groupsvc.getGroup(g);

      // GET THE GROUP MEMBERS HERE..
      // PROBLEM HERE
      try{
         this.groupMembers = await this.groupmembersvc.getGroupMembersPromise(g);
         this.countMembers();
      } catch (e) {
          console.log('GMLC error retrieving groupMember list: ', e);
      }

      // this.getMembersEvent.emit(g);
      // this.countMembers();

      // console.log('GMLC  groupChange groupmembers=', this.groupMembers);
      // console.log('GMLC typeof groupMembers=', typeof this.groupMembers);

      // set the table data source
      this.dataSource = this.groupMembers;
      // this.dataSource.filter ='';

      // console.log('GMLC groupChange dataSource=', this.dataSource);
      if (this.selectedGroupObj) {
         this.selectedOwner = this.selectedGroupObj.owner;
         this.selectedName = this.selectedGroupObj.name;
         // console.log('GMLC groupobj = ', this.selectedGroupObj);
         // console.log('GMLC groupowner = ', this.selectedOwner);
         // console.log('GMLC groupname = ', this.selectedName);
       } else {
            this.selectedOwner = null;
            this.selectedName = null;
         }
  }

  async OLDdisplayUserInfo(uuid: string) {
     const mbrInfo = await this.usersvc.getUserInfo(uuid);
     // console.log('GMLC mbrInfo =', mbrInfo);
     this.displayUserInfoEvent.emit(mbrInfo);
  }

  async displayUserInfo(uuid: string) {
     // console.log('GMLC mbrInfo uuid =', uuid);  
     const mbrInfo = await this.usersvc.getUserInfo(uuid);
     console.log('GMLC mbrInfo =', mbrInfo);
     this.displayUserInfoEvent.emit(mbrInfo);
  }

  openUserInfoDialog(uinfo) {
      console.log('gml usrInfoDialog uinfo=', uinfo);

      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '75vw';
      dialogConfig.maxHeight    = '99vh';

      if (!uinfo) {
         this.toast.pop('error', 'ERROR!',
                        'Must pass a UserInfo record to display');
      }
      dialogConfig.data = {
         UserInfo: uinfo,
         UserUID: uinfo.uid
      };

      // Open action prompt dialog (delete group intent)
      this.dialogRef = this.userInfoDialog.open(UserInfoDialogComponent, dialogConfig);

      // Callback after save from dialog
      // None at present.
      this.dialogRef.afterClosed().subscribe();
  }

  handleRemoveMember(gid, uid) {
      /***
      const memberdata = {
         guid: gid,
         uuid: uid
      };
      this.removeMemberEvent.emit(memberdata);
      ***/
      console.log('group-member-list removeMemberEvent gid=', gid);
      console.log('group-member-list removeMemberEvent uid=', uid);
      this.openRemoveMemberDialog(gid, uid);
  }

  /****
  handleRemoveMember(evt) {
      console.log('group-member-list removeMemberEvent evt=', evt);
      this.openRemoveMemberDialog(evt.guid, evt.uuid);
  }
  ***/

  async removeMember(grp, mbr) {
      console.log('main removeMember grp=', grp);
      console.log('main removeMember mbr=', mbr);
      try {
         await this.groupmembersvc.removeGroupMember(grp.uid, mbr.uid);
         console.log('GMC removed group member', mbr);
         this.toast.pop('success', 'Remove Group Member',
         'Group member ' + mbr.name + ' ' + mbr.last_name + '(' +
          mbr.email + ') successfully removed.');
         // this.refreshMembersEvent.emit(this.groupUID);
         // this.groupChange(this.groupUID);
      } catch (e)  {
         console.error('main error removeMember grp=', grp);
         console.error('main error removeMember mbr=', mbr);
         console.log('main error removeMember e=', e);           this.toast.pop('error', 'Remove Group Member',
              'Error removing group member ' + mbr.email +
              ' from group ' + grp.name );
      }
  }

   async openRemoveMemberDialog(guid, uuid) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const groupName = null;
      const groupUID = null;

      console.log('gml removeMemberDialog guid=', guid);
      console.log('gml removeMemberDialog uuid=', uuid);
      const grp = await this.groupsvc.getGroup(guid);
      const gname = grp.name;
      const mbrlist = await this.groupmembersvc.getGroupMembers(guid);
      console.log('main removeMemberDialog mbrlist=', mbrlist);
      const mbr = await mbrlist.find(m => m.uid === uuid);
      // console.log('main removeMemberDialog groupMembers=', this.groupMembers);
      console.log('gml removeMemberDialog grp=', grp);
      console.log('gml removeMemberDialog mbr=', mbr);
      const mbrname = mbr.name + ' ' + mbr.last_name + ' (' + mbr.email + ') ';

      // Pass the seletected group member data
      const grpMbrData = {
         groupUID: guid,
         userUID: uuid,
         groupName: gname,
         memberName: mbrname
         };

      const msg = 'Are you sure you want to remove member ' +
                 mbrname + ' from group ' + grp.name + '?';
      const msg2 = '';

      dialogConfig.data = {
         intent: 'deleteMember',
         title: 'Remove Group Member',
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Remove',
         dialogData: grpMbrData
      };

      // Open action prompt dialog (delete group intent)
      this.dialogRef = this.actionPromptDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after save from dialog
      let intent = null;
      let choice = null;
      let returnData = null;
      this.dialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('action-prompt return=', data.dialogData);
            }
            this.removeMember(grp, mbr);
          } //
       );
  }

  getUserInfo(): Promise<any> {
      return this.auth.getInfo().toPromise().then(
            (x: UserInfo) => {
                console.log('x=', x);
                return x;
               },
               (e) => {
                  console.log('ERROR: ', e);
                  throw e;
               });
    }

  setMemberApprovals(e, v) {
     console.log('onMemberSelected e=', e);
     console.log('onMemberSelected v=', v);
     //console.log('request selected=', e.options);
     e.options.forEach( (mbr, i) => {
        console.log('mbr.value=', mbr.value);
        console.log('mbr.selected=', mbr.selected);
        if ( mbr.selected ) {
           // add request
           const x = this.addApprovalRequest( mbr.value.email, mbr.value.uid );
           console.log('added request for ' + mbr.value.email + ' x=', x);
           console.log('approvals=', this.itemData.approvals);
        } else {
             // remove request
             const y = this.removeApprovalRequest( mbr.value.email );
           console.log('removed request for ' + mbr.value.email + ' y=', y);
             console.log('approvals=', this.itemData.approvals);
          }
     });
     //v.forEach( (item, i) => {
     //   console.log('item.value=', item.value);
     //   console.log('item.selected=', item.selected);
     //});
     console.log('request items=', this.grpMemberList.selectedOptions.selected);
  }

  emitSelectedMembers(evt) {
     console.log('emitSelectedMembers evt=', evt);
     this.selectMembersEvent.emit(evt);
  }

  emitCancelSelection() {
     console.log('emitCancelSelection');
  }

  approvalRequestExists(email: string): boolean {
      if( ! this.itemData ) {
          return false;
      }
      if( ! this.itemData.approvals || this.itemData.approvals.length === 0 ) {
          // itemData.approvals does not exist error
          // console.error('approvalRequestExists itemData.approvals is null or undefined or empty');
          return false;
      }
      const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
      if( index === -1 ) {
         // console.log('approvalRequestExists is false for ', email);
         return false;
      } else {
          // console.log('approvalRequestExists is true for ', email);
          return true;
        }
   }

   addApprovalRequest(email: string, uid: string) {
      if( this.itemData.approvals ) {
         //
         const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
         // item exists do nothing
         if (index > -1) {
           console.log('addApprovalRequest already exists!');
           return false;
         }
      } else {
          // itemData.approvals does not exist error
          console.error('addApprovalRequest itemData.approvals is null or undefined');
          return false;
        }

      // Add the approval request
      const ar = new MarkerApproval();
      if ( this.itemData && this.itemData.approvalScope ) {
         ar.scope = this.itemData.approvalScope;
      } else {
         ar.scope = 'D';
        }
      ar.created_at = Date.now();
      ar.ownerUID = this.userInfo.uid;
      ar.ownerEmail = this.userInfo.email;
      ar.responderUID = uid;
      ar.responderEmail = email;
      ar.response = 'P';
      ar.responseTimestamp = null;
      // this.itemData.approvalsPending++;
      this.itemData.approvals.push(ar);
      // if ( this.userInfo.email === email ) {
      //   this.userApprovalRequest = ar;
      //   this.userApprovalRequested = true;
      // }
      return true;
   }

   removeApprovalRequest(email: string): boolean {
      if( ! this.itemData.approvals || this.itemData.approvals.length === 0 ) {
          // itemData.approvals does not exist error
          console.error('removeApprovalRequest itemData.approvals is null or undefined or empty');
          return false;
      }

      //
      const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
      // item exists do nothing
      if (index === -1) {
        console.log('removeApprovalRequest request not found!');
        return false;
      } else {
           const ar = this.itemData.approvals[index];
           // if (this.userInfo.email === email) {
           //   this.userApprovalRequest = null;
           //   this.userApprovalRequested = false;
           // }
           this.itemData.approvals.splice(index, 1);
           return true;
        }
    }

} // group-member-list

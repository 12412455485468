<mat-menu #objMenu="matMenu">
  <button *ngIf="objData && objData.uid"
	  mat-menu-item enabled (click)="openObj()">
    <mat-icon>domain</mat-icon>              
    <span style="font-size: 0.9em">Open {{objLabel}}</span>
  </button>
  <button mat-menu-item enabled (click)="viewObj()">
    <mat-icon>domain</mat-icon>              
    <span style="font-size: 0.9em">View {{objLabel}} Info</span>
  </button>
  <button *ngIf="userData && objData && isObjAdmin"
	  mat-menu-item enabled (click)="editObj()">
          <mat-icon>edit</mat-icon>  
          <span style="font-size: 0.9em">Edit {{objLabel}} Info</span>
  </button>
  <button *ngIf="userData && objData && !iam && isObjAdmin"
          mat-menu-item enabled (click)="createObj()">
          <mat-icon>add</mat-icon> 
          <span style="font-size: 0.9em">Upload New {{objLabel}}</span>
  </button>
  <button *ngIf="userData && objData && isObjAdmin"
	  mat-menu-item enabled (click)="deleteObjDialog()">
          <mat-icon>delete</mat-icon>
          <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
  </mat-menu>

  
<ng-template #nodata>
   <span>No Data Found</span>
</ng-template>

<ng-template #loading>
  <div>
    <span>Loading Data...</span>
  </div>
</ng-template>

<!-- border: 1px solid #1A567A;  -->
<div>

<div #grp *ngIf="template === DisplayTemplate.FIELD_NAME">
 <div *ngIf="(uid && objData$ | async) as doc">
        <mat-form-field class="form-field" [floatLabel]="'always'" appearance="">	  
           <mat-label class="form-label">{{nameLabel}}</mat-label>
           <mat-icon matPrefix style="margin-right: 1rem">file</mat-icon>
           <input matInput placeholder=""
                  type="text" name="name" class="form-control"
		  [value]="doc.name">
        </mat-form-field>
  </div>	
</div>

<div #grp *ngIf="template === DisplayTemplate.SIDENAV_NAME">
  <div *ngIf="objData">
    <span class="sidenav-name">{{objData.name}}</span>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <mat-icon *ngIf="objData && objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em" (click)="viewObjDialog()">domain</mat-icon>
    <mat-icon *ngIf="objData && !objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em" (click)="viewObjDialog()">person</mat-icon>    
    <span *ngIf="objData && selectedObj && selectedObj.uid === objData.uid; else defaultObjName" style="font-size 1.5rem; font-weight: bold; background: #1A567A; color: white; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em; justify-content: space-between;">
	{{objData.name}}
    </span>    	
    <mat-icon *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid"    style="align-self: center;padding-left: 0.4em; padding-right: 0.5em; padding-top: 0.525em; padding-bottom: 0.7em; background: #1A567A; color: white;">
	check
    </mat-icon>
    
    <ng-template #defaultObjName>
       <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;">
         {{objData.name}}
       </span>
    </ng-template>
    <button *ngIf="showDeleteButton"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #objListDiv *ngIf="template === DisplayTemplate.SELECT_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;">
  <div #objItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 98% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <div #objItemRow style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 95% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <div #grpItemCol style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 95% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
  <!---
   <div #grpItemColRow style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
    <div *ngIf="(orgData$ | async) as org">	
       <span style="font-size: 0.7rem;">{{org.org_name}}</span>
    </div>
    <div *ngIf="(ownerData$ | async) as owner">
     <span style="font-size: 0.7rem; margin-left: 1rem;">{{owner.email}}</span>
    </div>
   </div>

    <button *ngIf="selectedGroup.owner === userInfo.uid || objData.users_uid === userInfo.uid" mat-raised-button  color="warn" (click)="deleteObjDialog()" id="delete-button"><mat-icon id="mbl-delete-icon" style="color: white;">delete</mat-icon>
    </button>
    --->
    <mat-icon *ngIf="objData && objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em; z-index: 15000" (click)="viewObjDialog()">domain</mat-icon>
    <mat-icon *ngIf="objData && !objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em; z-index: 15000" (click)="viewObjDialog()">person</mat-icon>
    <!--
    <mat-icon *ngIf="objData.users_uid === userInfo.uid" id="mbl-delete-icon" (click)="deleteObjDialog()" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em; margin-right: 1rem; color: red;">delete</mat-icon> 
    -->
    <span *ngIf="selectedObj && selectedObj.uid === objData.uid; else slNotSelected"
	  style="font-size 1.5rem; font-weight: bold; background: white; color: #1A567A; width: 95%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      {{objData.name}}
    <span *ngIf="userData.uid===objData.users_uid" style="font-size: 0.8rem"> &#9733;</span>          
    </span>
    <ng-template #slNotSelected>
    <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 85%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      {{objData.name}}
    <span *ngIf="userData.uid===objData.users_uid" style="font-size: 0.8rem"> &#9733;</span>
    </span>
    </ng-template>
    <mat-icon *ngIf="selectedObj && selectedObj.uid === objData.uid"
	      style="align-self: center;padding-left: 0.4em; padding-right: 0.5em; padding-top: 0.525em; padding-bottom: 0.7em; background: white; color: #1A567A;">
	check
    </mat-icon>
  </div>    
    <button *ngIf="showDeleteButton && userData.uid===objData.users_uid"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center; margin-left: 0rem; margin-right: 2rem; padding-right: 0.4em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
    </div>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.EXPAND_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;" (click)="setSelectedObj()">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="setSelectedObj()">
    <button mat-stroked-button
            (click)="toggleDetail()"
      style="margin-right: 1.5em; color: white; background-color: #2274A5;">
      <mat-icon>expand_more</mat-icon>
    </button>
    <span *ngIf="objData && selectedObj && selectedObj.uid === objData.uid; else defaultObjName"
          style="font-size 1rem; font-weight: bold; color: #2274A5; width: 100%">
          {{objData.name}}
    </span>
    <ng-template #defaultObjName>
       <span style="font-size: bold; font-weight: normal; color: black; width: 100%" (click)="setSelectedObj()"> 
         {{objData.name}}
       </span>
    </ng-template>
  </div>

  <div #objItemButtons (click)="setSelectedObj()"
       style="display: flex; flex-flow: row; justify-content: space-around; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em;">
  <mat-icon style="color: #2274A5; align-self: flex-end;" *ngIf="selectedObj && selectedObj.uid === objData.uid">check</mat-icon>    
 </div>
</div>

<!-- border: 1px solid #1A567A;  -->
<div #objdetail *ngIf="template === DisplayTemplate.DETAIL"
     style="display: flex; flex-flow: column; justify-content: space-between; align-items: space-between; width: 90% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="toggleDetail()">
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
    <button *ngIf="this.mode === DisplayMode.LIST || this.mode === DisplayMode.EXPAND_LIST"
	  mat-stroked-button
          (click)="toggleDetail()"
          style="margin-right: 1.5em;">
    <mat-icon>expand_less</mat-icon>
    </button>
    <span style="width: 100%;">Group {{objData.name}}</span>
    <mat-icon *ngIf="objData && selectedObj && (objData.uid===selectedObj.uid)"
        style="margin-left: 0.5em; margin-right: 0.4em; color: #2274A5">
	check
    </mat-icon>
  </div>
  <span style="margin-left: 1.5em; width: 100%"></span><br>
 <div style="display: flex; flex-flow: column; margin-left: 0.5em; margin-right: 1em; border: 1px solid #1A567A; width: 95%% !important;">  
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
  </div>
  <div style="display:flex; flex-flow: column; justify-content: space-between; margin-left: 0.5em">
    <div *ngIf="(orgData$ | async) as org">
      <span style="">Company/Org. Name : {{org.org_name}}</span>
    </div>
    <div *ngIf="(userData$ | async) as owner">
      <span style="">User/Owner Email Address : {{owner.email}}</span>
    </div>
  </div>
  <span style=""></span><BR>
    <span style=""></span><BR>
  </div>
 </div>
</div>

<div #orgform *ngIf="template===DisplayTemplate.FORM">
 <div 
     style="display: flex; flex-flow: column; justify-content: space-around; align-content; center; min-width: 90vw; margin-left: 1rem; margin-right: 1rem; overflow: hidden !important; margin-bottom: 7rem;">  

  <div *ngIf="showFormTitle">
   <div class="form-title" *ngIf="isAddMode">Create Group</div>
   <div class="form-title" *ngIf="mode === DisplayMode.EDIT">Edit Group File</div>
   <div class="form-title" *ngIf="mode === DisplayMode.VIEW">View Group File</div>  
  </div>
  
  <form id="objForm" [formGroup]="objForm">
  <!---
  <form id="objForm" [formGroup]="objForm" (ngSubmit)="onSubmit()">
  <mat-tab-group style="overflow: hidden !important;">

    <mat-tab class="form-tab" label="Info">
      --->
      
    <ng-container *ngIf="isAddMode">      
       <app-org-list #orgList class="form-field"
         style="margin: 0; padding: 0; width: 80vw; max-width: 80vw; height: 100%: overflow-y: auto"
	 [iam]="iam"		 
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showMenuButton]="false"
         [showAddButton]="false"		 
         [showListViewButton]="false"		    
         [selectedObj]="selectedOrg"
         [titleFilter]="''"
         (selectObjEvent)="setSelectedOrg($event)"		     
	 >
       </app-org-list>
       </ng-container>

    <ng-container *ngIf="isAddMode">      
       <app-group-list #grpList class="form-field"
         style="margin: 0; padding: 0; width: 80vw; max-width: 80vw; height: 100%: overflow-y: auto"
	 [iam]="iam"		 
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showMenuButton]="false"
         [showAddButton]="false"		     
         [showListViewButton]="false"		    
         [selectedObj]="selectedGroup"
         (selectObjEvent)="setSelectedGroup($event)"		     
	 >
       </app-group-list>
       </ng-container>
    
    <mat-form-field *ngIf="showHidden"
		    class="form-field" [appearance]="formFieldStyle"
		    [style.display]="'block'" [floatLabel]="'always'">
      <mat-label class="form-label">UID</mat-label>
      <input matInput placeholder="File UID" 
             type="text" name="uid" class="form-control"
	     formControlName="uid">
    </mat-form-field>

    <mat-form-field 
       class="form-field" [appearance]="formFieldStyle"
       [style.display]="'block'" [floatLabel]="'always'">
      <mat-label class="form-label">File Name</mat-label>
      <input matInput placeholder="File Name" 
             type="text" name="name" class="form-control"
	     formControlName="name">
    </mat-form-field>
    <mat-form-field 
       class="form-field" [appearance]="formFieldStyle"
       [style.display]="'block'" [floatLabel]="'always'">
      <mat-label class="form-label">File Description</mat-label>
      <input matInput placeholder="" 
             type="text" name="description" class="form-control"
	     formControlName="description">
    </mat-form-field>

    
    <!-- *ngIf="showHidden"  -->
    <mat-form-field *ngIf="showHidden && objData.org_uid" class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company/Org. UID</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
      <input matInput placeholder="" 
             type="text" name="org_uid" class="form-control" formControlName="org_uid">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.org_uid.touched || submitted) && objForm.controls.org_uid.errors?.required">
	Org UID is required.</span>	
    </mat-error>

    <mat-form-field *ngIf="(orgData$ | async) as gorg"
		    class="form-field" [appearance]="formFieldStyle"
		    [floatLabel]="'always'" [hidden]="false">
      <mat-label class="form-label">Company/Org. Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
      <input matInput placeholder="" 
             value="{{gorg.org_name}}"
             [ngModel]="gorg.org_name"
             type="text" name="org_name" class="form-control"
	     formControlName="org_name">
    </mat-form-field>  
    <mat-error class="form-error"> 
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.org_name.touched || submitted) && 
                objForm.controls.org_name.errors?.required">
	Org Name is required.</span>	
    </mat-error>

    <!-- *ngIf="showHidden" -->
    <mat-form-field *ngIf="showHidden"
		    class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Group UID</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
      <input matInput placeholder="Group UID" 
             type="text" name="groups_uid" class="form-control"
	     formControlName="groups_uid">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.groups_uid.touched || submitted) && 
                objForm.controls.groups_uid.errors?.required">
	Group UID is required.</span>	
    </mat-error>

    
    <mat-form-field *ngIf="(groupData$ | async) as grp"
		    class="form-field" [appearance]="formFieldStyle"
		    [floatLabel]="'always'" [hidden]="false">
      <mat-label class="form-label">Group Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">group</mat-icon>
      <input matInput placeholder="" 
             value="{{grp.name}}"
             [ngModel]="grp.name"
             type="text" name="group_name" class="form-control"
	     formControlName="group_name">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.group_name.touched || submitted) && objForm.controls.group_name.errors?.required">
	Group Name is Required!</span>	
    </mat-error>
    
    <mat-form-field *ngIf="showHidden"
		    class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">User/Owner UID</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>
      <input matInput placeholder="User UID" 
             type="text" name="users_uid" class="form-control" formControlName="users_uid">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.users_uid.touched || submitted) && 
                objForm.controls.users_uid.errors?.required">
	File Owner is required.</span>	
    </mat-error>
    
    <mat-form-field *ngIf="(userData$ | async) as usr" class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">User/Owner Email</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>
      <input matInput placeholder="User/Owner Email" 
             value="{{usr.email}}"
             [ngModel]="usr.email"
             type="text" name="user_email" class="form-control" formControlName="user_email">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="objForm && (objForm.controls.user_email.touched || submitted) && objForm.controls.user_email.errors?.required">
        File Onwer Email is required.</span>	
    </mat-error>

    
    <!---
    </mat-tab>
  </mat-tab-group>
  --->
  
  <div class="form-bottom-div">
    <mat-divider></mat-divider>
    <span class="form-error"
    *ngIf="objForm && objForm.valid===false && submitted===true">
      Please fix errors on one or more tabs and try again
    </span>

    <div *ngIf="showActions" class="form-btn-div">
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="prevObj()">
          <mat-icon>arrow_back_ios</mat-icon>
       </button>
       <button mat-stroked-button class="submit-btn" (click)="onCancel()">
               Cancel</button>
       <button *ngIf="mode === DisplayMode.ADD"
	       mat-stroked-button class="normal-btn" (click)="resetForm()">
               Reset</button>
       <button *ngIf="mode !== DisplayMode.VIEW" class="submit-btn"
	       mat-stroked-button class="submit-btn" (click)="onSubmit()">
         Submit</button>
<!--       *ngIf="objData && userData && getIsObjAdmin(objData, userData) && (mode === DisplayMode.VIEW)" class="submit-btn" -->
       <button *ngIf="isObjAdmin && mode !== DisplayMode.EDIT && !isAddMode"
               mat-stroked-button class="submit-btn"(click)="editObj(true)">
               Edit</button>
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="nextObj()">
         <mat-icon>arrow_forward_ios</mat-icon>
         </button>
    </div>
  </div>
  
  </form>
 </div>
</div>

 <!-- Delete Template -->
 <div #grpDelete *ngIf="template === DisplayTemplate.DELETE">
        <div *ngIf="selectedObj; else noObjSelected"
	     class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Delete group {{selectedObj.name}}
	  </span>
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Are You Sure?
	  </span>
        </div>
	<ng-template #noObjSelected>
	  <div class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Error: No group selected!
	  </span>
          </div>
	</ng-template>
        <div class="form-btn-div">
             <button mat-stroked-button id="cancel-delete-obj" class="normal-btn" type="button" value="Cancel" (click)="onCancel()">Cancel</button>	  
             <button mat-stroked-button *ngIf="selectedObj" id="delete-obj" class="delete-btn" type="submit" value="Yes" (click)="deleteObjData(selectedObj.uid)">Yes</button>
        </div>
 </div>

<!-- group component -->    

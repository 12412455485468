import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, Output,
         ViewChild, EventEmitter, ElementRef, OnDestroy, ChangeDetectorRef }
         from '@angular/core';

import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm,
         Validators } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';

// import { lastValueFrom } from 'rxjs';
import { Subject, Observable, Subscription } from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';

// import { SelectLists } from '../../mdtools/select-lists.common';

import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AuthService } from '../../service/auth.service';
import { ToasterService } from '../../service/toaster.service';
import { UserService } from '../../service/user.service';
import { GroupService } from '../../service/group.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';

import { ActionPromptDialogComponent } from '../../dialog/action-prompt/action-prompt.dialog';
import { FormDialogComponent } from '../../dialog/form/form.dialog';

import { CountData } from '../../model/countdata.model';
import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { GroupData } from '../../model/groupdata.model';
import { OrgData } from '../model/orgdata.model';
import { OrgUserInfo } from '../model/orguserinfo.model';
import { OrgUserData } from '../model/orguserdata.model';


// import * as GRP from './group.component';
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';
import * as FORMTOOLS from '../../mdtools/formtools.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;


/** Error when invalid control is dirty, touched, or submitted. */
export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
   selector: 'app-org-user',
   templateUrl: './org-user.component.html',
   styleUrls: ['./org-user.component.css'],
})
export class OrgUserComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

   DisplayModel: typeof STATIC.Model = STATIC.Model;
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   private oid: string;

   @Input()  iam = false;
   @Input()  isObjAdmin = false;
   @Input()  mode: string = this.DisplayMode.SELECT;
   @Input()  userInfo: UserInfo | null;
   @Input()  userData: UserData | null;
   @Input()  template: number;
   @Input()  groupData: GroupData | null;
   @Input()  selectedObj: any | null;
   @Input()  selectedOrg: OrgData | null = null;
   @Input()  objData: any | null;
   @Input()  isSelected = false;
   @Input()  showActions = true;
   @Input()  doSubmit = false;
   @Input()  uid: string | null = null;
   @Input()  nameLabel: string | null  = 'Group Name';
   @Input()  useMenuActionDialog = true;
   @Input()  showHidden = false;
   @Input()  showOrgInfo = false;
   @Input()  showFormTitle = false;
   @Input()  showInfoButton = false;
   @Input()  showMenuButton = false;
   @Input()  showDeleteButton = false;
   @Input()  doSubmit$: Subject<boolean>;
   @Input()  doReset$: Subject<boolean>;
   @Input()  doEdit$: Subject<boolean>;
   @Input()  doDelete$: Subject<boolean>;
   @Input()  doView$: Subject<boolean>;

   @Input()
   public get org_uid() {
       return this.oid;
   }
   public set org_uid(oid: string) {
       this.oid = oid;
       // this.get_org(this.oid);
       // this.changeDetector.markForCheck();
   }

   @Output() toggleObjDetail = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() deleteErrorEvent = new EventEmitter<any>();
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() selectGroupEvent = new EventEmitter<any>();
   @Output() refreshEvent = new EventEmitter<any>();
   @Output() submitEvent = new EventEmitter<any>();
   @Output() cancelEvent = new EventEmitter<any>();
   @Output() doneEvent = new EventEmitter<any>();
   @Output() prevEvent = new EventEmitter<any>();
   @Output() nextEvent = new EventEmitter<any>();
   @Output() modeEvent = new EventEmitter<any>();
   @Output() isObjAdminEvent = new EventEmitter<any>();

   @ViewChild("usrEmail") usrEmail: ElementRef;
   @ViewChild("expByDate") expByDate: ElementRef;

   // Dialog vars
   // public actionPromptDialog: MatDialog;
   // public objectDialog: MatDialog;
   // public helpDialog: MatDialog;

   // DialogRef vars
   actionPromptDialogRef: any = null;
   formDialogRef: any = null;
   helpDialogRef: any = null;

   // View vars
   objLabel = 'Company/Org. User';
   showDetail = false;
   viewOnly = false;
   isExpanded = false;

   // Add Record Quota Flags and initial values
   checkQuota = true;
   quotaError = false;
   quotaErrorType: string | null = '';
   newEmpsAvailable: CountData = null;
   newGuestsAvailable: CountData = null;

   // Observables for usage/quotas
   orgStorageUsed$: Observable<any> = null;

   public totalEmpOrgUsers$: Observable<any> = null;
   public activeEmpOrgUsers$: Observable<any> = null;
   public inactiveEmpOrgUsers$: Observable<any> = null;
   public availEmpOrgUsers$: Observable<any> = null;

   public totalGuestOrgUsers$: Observable<any> = null;
   public activeGuestOrgUsers$: Observable<any> = null;
   public inactiveGuestOrgUsers$: Observable<any> = null;       public availGuestOrgUsers$: Observable<any> = null;

   // isSelected = false;

   // Org Admin
   orgData: OrgData = null;
   isOrgUserAdmin = false;
   isOrgOwner = false;

   // Form vars
   formFieldStyle = 'outline';  // fill or outline
   objForm: FormGroup;
   // orgStatusForm: FormGroup;
   isAddMode: boolean;
   isFormReady: boolean;
   loading = false;
   submitted = false;
   userType = "";

//   public orgTypes = LISTS.SelectLists.orgTypes;
//   public orgCategories = LISTS.SelectLists.orgCategories;
//   public statusCodes = LISTS.SelectLists.orgStatuses;
   // public stateCodes = LISTS.SelectLists.stateCodes;
//   public countryCodes = LISTS.SelectLists.countryCodes;

   public objData$: Observable<any | null> = null;

   public orgData$: Observable<any | null> = null;
   public userData$: Observable<any | null> = null;
   public userInfo$: Observable<any | null> = null;

   public emailUserInfo: UserInfo | null = null;

   public orgTypes = LISTS.orgTypes;
   public orgCategories = LISTS.orgCategories;
   public statusCodes = LISTS.orgStatuses;
   // public stateCodes = LISTS.stateCodes;
   public countryCodes = LISTS.countryCodes;

   public orgUserTypes = LISTS.orgUserTypes;
   public orgUserStatuses = LISTS.orgUserStatuses;

   public errorMatcher = new FormErrorStateMatcher();

   orgNbrStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; margin-right: 1rem",
     selected: "font-weight: bold; color: #2274A5; margin-right: 1rem"
   }

   orgNameStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; width: 100%;",
     selected: "font-weight: normal; color: #2274A5; margin-right: 1rem"
   }

   orgNbrStyle=this.orgNbrStyles.default;
   orgNameStyle=this.orgNameStyles.default;

   // Instance vars
   is_app_mgr = false;

   orgUserData: OrgUserData;
   orgUserSubscription: any;
   doSubmitSubscription: any;

   constructor(
      public toast: ToasterService,
      private el: ElementRef,
      private fb: FormBuilder,
      private auth: AuthService,
      private cd: ChangeDetectorRef,
      public orgsvc: OrganizationService,
      public orgusersvc: OrgUserService,
      public groupsvc: GroupService,
      public usersvc: UserService,
      public actionPromptDialog: MatDialog,
      public formDialog: MatDialog,
      public helpDialog: MatDialog,
      ) {
      } // constructor

   async ngOnInit() {
      console.log('org-user iam=', this.iam);
      this.userData = this.auth.getTokenInfo();
      this.refreshData();
      this.checkQuota = true;
      this.quotaError = false;
      this.quotaErrorType = '';

      // this.orgsvc.orgItem$.next(null);
      //if ( this.uid ) {
      //   this.objData$ = this.groupsvc.getGroup$( this.uid );
      // }
      if ( this.doSubmit$ ) {
        this.doSubmit$.subscribe( v => {
           console.log('org-user onInit doReset v=', v);
           if ( v === true ) {
             this.onSubmit();
             // this.doSubmit = false;
           }
        });
      }
      if ( this.doReset$ ) {
        this.doReset$.subscribe( v => {
           if ( v === true ) {
             this.onReset();
           }
        });
      }
      if ( this.doEdit$ ) {
        this.doEdit$.subscribe( v => {
           if ( v === true ) {
             this.onEdit();
           }
        });
      }
      if ( this.doDelete$ ) {
        this.doDelete$.subscribe( v => {
           if ( v === true ) {
             this.onDelete();
           }
        });
      }
      if ( this.doView$ ) {
        this.doView$.subscribe( v => {
           if ( v === true ) {
             this.onView();
           }
        });
      }

      this.isObjAdmin = await this.getIsObjAdmin( this.userData );
      this.isOrgUserAdmin = await this.getIsOrgUserAdmin();
      this.isOrgOwner = await this.getIsOrgOwner();

      this.setModeTemplate(this.mode);
   }

   ngAfterViewInit() {
      // console.log('org-user avi selectedObj=', this.selectedObj);
      // console.log('org-user avi objData=', this.objData);
   }

   async ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      // console.log('org-user changes=', changes);
      if ( changes.uid ) {
         this.objData$ = this.orgusersvc.getOrgUser$( changes.uid.currentValue );
      }
      if ( changes.selectedObj ) {
         this.selectedObj = changes.selectedObj.currentValue;
         // this.getObjFieldRefs(this.selectedObj);
      }
      if ( changes.selectedOrg ) {
         this.selectedOrg = changes.selectedOrg.currentValue;
         this.isObjAdmin = await this.getIsObjAdmin( this.userData );
         this.isOrgUserAdmin = await this.getIsOrgUserAdmin();
         this.isOrgOwner = await this.getIsOrgOwner();
         this.getOrgStats();
      }
      if ( changes.objData ) {
         this.setObjData(changes.objData.currentValue);
         console.log('obj isObjAdmin=', this.isObjAdmin);
         this.getObjFieldRefs(changes.objData.currentValue);
         this.isObjAdmin = await this.getIsObjAdmin( this.userData );
         this.isOrgUserAdmin = await this.getIsOrgUserAdmin();
         this.isOrgOwner = await this.getIsOrgOwner();
         this.getOrgStats();
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
      }
      //
      this.refreshData();
   }

   ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     /****
     if ( this.doSubmit$ ) {
        this.doSubmit$.unsubscribe();
     }
     ***/
   }

   async get_org(uid) {
       this.orgData  = await this.orgsvc.getOrganization(uid);
   }

   getObjFieldRefs(obj) {
     if ( this.mode !== this.DisplayMode.SELECT_LIST ) {
      if ( obj && obj.owner ) {
       this.userInfo$ = this.usersvc.getUserInfo$(obj.user_uid);
      }
      if ( obj && obj.org_uid ) {
       this.orgData$ = this.orgsvc.getOrganization$(obj.org_uid);
      }
     }
   }

   getOrgStats() {
     if ( this.selectedOrg && this.selectedOrg != null ) {
        // this.orgDocumentCount$ = this.documentsvc.getOrgDocumentCount$(this.selectedOrg.uid);
        this.orgStorageUsed$ = this.orgsvc.getOrgStorage$(this.selectedOrg.uid);

        this.totalEmpOrgUsers$ = this.orgusersvc.getOrgEmpRecordCount$(this.selectedOrg.uid);
        this.inactiveEmpOrgUsers$ = this.orgusersvc.getOrgInactiveEmpCount$(this.selectedOrg.uid);
        this.availEmpOrgUsers$ = this.orgusersvc.getOrgAvailableEmpCount$(this.selectedOrg.uid);

        this.totalGuestOrgUsers$ = this.orgusersvc.getOrgGuestRecordCount$(this.selectedOrg.uid);
        this.inactiveGuestOrgUsers$ = this.orgusersvc.getOrgInactiveGuestCount$(this.selectedOrg.uid);
        this.availGuestOrgUsers$ = this.orgusersvc.getOrgAvailableGuestCount$(this.selectedOrg.uid);

        // this.orgUserGuestCount$ = this.orgusersvc.getOrgUserGuestCount$(this.selectedOrg.uid);
        //this.groupsvc.getOrgGroupCount$();
        //this.groupsvc.getGroupSystemCount$();
     }
   }

   async refreshData() {
      if (this.userData) {
          // console.log('org-list userdata=', this.userData);
          this.is_app_mgr = this.userData.iam;
          this.orgUserData = null;
      } else {
             console.log('OrgListComponent WARNING userData not defined.');
        }

      // DONT emit refresh here, causes infinite loop in list container
      // this.refreshEvent.emit();
      // this.setModeTemplate(this.mode);
      this.getObjFieldRefs(this.objData);

      // console.log('mode=', this.mode);
      // console.log('template=', this.template);
      // console.log('DisplayMode=', this.DisplayMode);
   }

   setModeTemplate(m) {
      // console.log('setMode called m=', m);
      this.isAddMode = false;
      this.viewOnly = false;

      this.modeEvent.emit(m);

      switch(m) {
         case this.DisplayMode.SELECT: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.COMBO: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.EXPAND_LIST: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             break;
         }
         case this.DisplayMode.XFER_LIST: {
             this.template = this.DisplayTemplate.XFER_LIST;
             break;
         }
         case this.DisplayMode.SELECT_LIST: {
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayMode.LIST: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.FORM: {
             // Important for Form Field Permissions
             this.userType = 'G';
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayMode.ADD: {
             // Important for Form Field Permissions
             // show validate Quota template first.
             // Then either error or reset mode to pass test
             this.userType = 'G';
             this.template = this.DisplayTemplate.FORM;
             this.viewOnly = true;
             this.isAddMode = true;
             if (this.userData.iam || this.isObjAdmin) {
                 this.viewOnly = false;
             }
             this.isFormReady = false;
             this.createForm();
             this.validateQuotas();
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.EDIT: {
             // Important for Form Field Permissions
             this.userType = 'G';
             this.template = this.DisplayTemplate.FORM;
             // Allow if iam or org_admin
    // console.log('org-user setModeTemplate EDIT objData=', this.objData);
             if (this.userData.iam || this.isObjAdmin) {
                 this.viewOnly = false;
    // console.log('org-user setModeTemplate EDIT isObjAdmin=', this.isObjAdmin);
             } else {
                 this.viewOnly = true;
             }
    // console.log('org-user setModeTemplate EDIT viewOnly=', this.viewOnly);
             this.isAddMode = false;
             this.isFormReady = false;
             this.createForm();
             this.setForm();
             if ( this.objData ) {
                this.getObjFieldRefs(this.objData);
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.VIEW: {
             // Important for Form Field Permissions
             this.userType = 'G';
             this.template = this.DisplayTemplate.FORM;
             this.isAddMode = false;
             this.isFormReady = false;
             this.viewOnly = true;
             this.createForm();
             this.setForm();
             if ( this.userData ) {
                this.objForm.patchValue({owner: this.userData.uid});
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.DELETE: {
             this.template = this.DisplayTemplate.DELETE;
             this.isAddMode = false;
             this.isFormReady = false;
             break;
         }
         case this.DisplayMode.FIELD_NAME: {
             this.template = this.DisplayTemplate.FIELD_NAME;
             break;
         }
      }
      // console.log('org setModeTemplate mode=', this.mode);
      // console.log('org setModeTemplate template=', this.template);
   }

   async validateQuotas() {
      let add_user_type = null;
      this.quotaError = false;
      this.checkQuota = true;
      // Maybe just modify ADD template..
      // this.template = this.DisplayTemplate.QUOTA;

      this.newEmpsAvailable = await this.orgusersvc.getOrgAvailableEmpCountPromise( this.selectedOrg.uid );
      this.newGuestsAvailable = await this.orgusersvc.getOrgAvailableGuestCountPromise( this.selectedOrg.uid );
      const empsAvail = this.newEmpsAvailable['count'];
      const guestsAvail = this.newGuestsAvailable['count'];

      if ( this.objForm && this.objForm.get('org_user_type') ) {
         add_user_type = this.objForm.get('org_user_type').value;
         this.quotaErrorType = null;
         if ( add_user_type !== 'G' && empsAvail <= 0 ) {
              this.quotaError = true;
              this.quotaErrorType = 'E';
         }
         if ( add_user_type === 'G' && guestsAvail <= 0 ) {
            this.quotaError = true;
            this.quotaErrorType = 'G';
         }
      }
      this.checkQuota = false;
      // Test moving quota html to .FORM template
      // this.setModeTemplate(this.DisplayMode.ADD);
   }

   public async setObjData(obj) {
      this.objData = obj;
      if (this.objData) {
        this.isObjAdmin = await this.getIsObjAdmin( this.userData );
        this.isOrgUserAdmin = await this.getIsOrgUserAdmin();
        this.isOrgOwner = await this.getIsOrgOwner();
        this.getObjFieldRefs(this.objData);
      } else {
          this.isObjAdmin = false;
        }
   }

   async setSelectedObj() {
      // console.log('org-user setSelectedObj=', this.objData);
      this.selectedObj = this.objData;
      this.selectObjEvent.emit(this.selectedObj);
   }

   setSelectListObj(evt) {
      // console.log('org-user setSelectListObj evt=', evt);
      // console.log('org-user setSelectListObj objData=', this.objData);
      // this.selectedObj = this.objData;
      this.setSelectedObj();
      // this.selectObjEvent.emit(this.objData);
      // this.doneEvent.emit(this.objData);
   }

   async getIsObjAdmin(uData: UserData) {
      const org = this.selectedOrg;
      let obj_admin = false;
      if (org && 'uid' in org && uData && 'uid' in uData) {
         const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(org.uid, uData.uid);
         //console.log('org-user orgusr=', orgusr);
         //console.log('orgusr.org_admin is', orgusr.org_admin);
         if ( orgusr && orgusr.org_admin ) {
             obj_admin = true;
         } else {
             obj_admin = false;
           }
      }
      // console.log('getIsObjAdmin this.iam=', this.iam);
      // console.log('getIsObjAdmin udata.iam=', uData.iam);
      if ( uData.iam && this.iam ) {
         obj_admin=true;
      }
      this.isObjAdminEvent.emit(obj_admin);
      // this.updateFormControls(obj_admin);
      console.log('org-user getIsObjAdmin isObjAdmin=', obj_admin);
      return obj_admin;
   }

   async getIsOrgOwner() {
      const obj = this.objData;
      let obj_owner = false;
      if (obj && 'uid' in obj && 'org_user_uid' in obj && 'org_uid' in obj) {
         const uinfo = await this.usersvc.getUserInfoPromise(obj.org_user_uid);
         const org = await this.orgsvc.getOrganizationPromise(obj.org_uid);
         // console.log('org getIsObjAdmin orgusr=', orgusr);
         if (uinfo.email === org.org_owner_email ) {
             obj_owner = true;
         } else {
             obj_owner = false;
           }
      }
      // console.log('org getIsOrgAdmin isObjAdmin=', obj_admin);
      // if ( this.objForm ) {
      //  this.updateFormControls(obj_admin);
      // }
      return obj_owner;
   }

   async getIsOrgUserAdmin() {
      const obj = this.objData;
      let obj_admin = false;
      if ( obj && obj.org_admin ) {
          obj_admin = true;
      } else {
          obj_admin = false;
        }
      // console.log('org-usr getIsOrgAdmin=', obj_admin);
      // this.isObjAdminEvent.emit(obj_admin);
      console.log('org-user getIsObjAdmin isObjAdmin=', obj_admin);
      return obj_admin;
   }

   compareObjs( o1: any, o2: any ) {
     if( !o1 || !o2 )
       { return false; }
     else {
        return o1.uid === o2.uid;
     }
   }

   onDone() {
     console.log('org-user onDone()');
     this.doneEvent.emit();
   }

   onCancel() {
     console.log('org-user onCancel()');
     this.cancelEvent.emit(this.objForm);
   }

   async onSubmit() {
     console.log('onSubmit form=', this.objForm);
     console.log('onSubmit form value=', this.objForm.value);
     console.log('onSubmit raw form=', this.objForm.getRawValue());

     this.submitted = true;
     if (this.objForm.valid) {
       // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
       // console.log('FORM IS VALID');
       // console.table(this.objForm.value);

       if ( this.isAddMode ) {
          this.objData = await this.addRecord( this.objForm );
       } else {
             this.objData = await this.editRecord( this.objForm );
       }
       console.log('org-user onSubmit this.objData=', this.objData);
       this.setSelectedObj();
       this.submitEvent.emit(this.objData);
     } else {
          // console.error('FORM IS NOT VALID');
          this.objForm.markAllAsTouched();
          this.scrollToFirstInvalidControl();
       }
   }

   onDelete() {
     // this.deleteRecord( this.selectedObj );
     this.deleteRecord( this.objData );
   }

   onReset() {
     this.resetForm();
   }

   onEdit() {
     this.setModeTemplate(this.DisplayMode.EDIT);
     this.modeEvent.emit(this.DisplayMode.EDIT);
   }

   onView() {
     this.setModeTemplate(this.DisplayMode.VIEW);
   }

   /***
   private scrollToFirstInvalidControl() {
     // "form .ng-invalid"
     const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
       "mat-form-field-invalid"
     );

     firstInvalidControl.focus(); //without smooth behavior
  }
   ****/

   private scrollToFirstInvalidControl() {
      const form = document.getElementById('objForm'); // <-- your formID
      const firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      if (firstInvalidControl) {
        firstInvalidControl.scrollIntoView();
        (firstInvalidControl as HTMLElement).focus();
      }
      console.log('org-user objForm error:', this.objForm);
   }

   viewObj() {
      // console.log('view obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.VIEW );
      } else {
         this.viewObjDialog();
        }
   }

   createObj() {
      // console.log('create bj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.ADD );
      } else {
         this.createObjDialog();
        }
   }

   editObj(dontUseDialog=false) {
      // console.log('org-user edit obj called. dontUseDialog=', dontUseDialog);
      // console.log('org-user edit obj called. objData=', this.objData);
      // this.setSelectedObj();
      if ( dontUseDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.mode = this.DisplayMode.EDIT;
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
        return;
      }
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
      } else {
         this.editObjDialog();
        }
   }

   deleteObj() {
      console.log('delete obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.DELETE );
      } else {
         this.deleteObjDialog();
        }
   }

   titleAction() {
      if ( this.mode === this.DisplayMode.SELECT ||
           this.mode === this.DisplayMode.LIST  ||
           this.mode === this.DisplayMode.XFER_LIST  ||
           this.mode === this.DisplayMode.EXPAND_LIST ) {
         this.setSelectedObj();
      }
   }

   toggleDetail() {
      switch(this.template) {
          case this.DisplayTemplate.EXPAND_LIST: {
             this.template = this.DisplayTemplate.DETAIL;
             this.showDetail = true;
             break;
          }
          case this.DisplayTemplate.DETAIL: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             this.showDetail = false;
             break;
          }
      }
   }

   cancelObj() {
      this.cancelEvent.emit(true);
      console.log('Cancel');
   }

   prevObj() {
      this.prevEvent.emit(true);
      console.log('prevObj');
   }

   nextObj() {
      this.nextEvent.emit(true);
      console.log('nextObj');
   }

   setSelectedOrg(o) {
     this.selectedOrg = o;
     if ( this.objForm ) {
        this.objForm.patchValue( {org_uid: o.uid} );
        this.objForm.patchValue( {org_name: o.org_name} );
     }
   }

   updateExpirationDate() {
     const now = new Date();
     const nextMonth = new Date(now);

     nextMonth.setMonth(nextMonth.getMonth() + 1);
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     let expiration = null;
     if (this.objForm) {
       if (this.objData && (this.objData.org_user_expires !== null)) {
         console.log('org-user checking ISO DateTime...');
         expiration = FORMTOOLS.checkISODateTime(this.objData.org_user_expires);
         this.objForm.patchValue({org_user_expires: expiration});
         console.log('org-user set expiration=', expiration);
       } else if (snm) {
                console.log('org-user set expires snm=', snm);
                this.objForm.patchValue({org_user_expires: snm});
              } else {
                  console.log('org-user set expires to null');
                  this.objForm.patchValue({org_user_expires: null});
                }
     }
   }

   requireExpirationDate() {
      this.updateExpirationDate();
      // FORMTOOLS.updateExpirationDate('org_user_expires');
      this.objForm.controls.org_user_expires.setValidators([Validators.required, Validators.min(16), FORMTOOLS.dateTimeValidator]);
      this.objForm.controls.org_user_expires.updateValueAndValidity();
   }

   clearExpirationDate() {
      this.objForm.controls.org_user_expires.setValidators(null);
      this.objForm.controls.org_user_expires.setValue(null);
      this.objForm.controls.org_user_expires.updateValueAndValidity();
   }

   toggleExpirationByDate() {
     if (this.objForm ) {
       if ( this.objForm.get('org_user_expire_by_date').value === true) {
         // this.objForm.get('org_user_expire_by_date').patchValue(false);
         this.objForm.patchValue({org_user_expire_by_date: false});
         this.clearExpirationDate();
       } else {
           this.objForm.patchValue({org_user_expire_by_date: true});
           this.requireExpirationDate();
       }
     }
   }

   toggleExpirationByGroup() {
     if (this.objForm ) {
       if ( this.objForm.get('org_user_expire_by_last_group').value === true) {
         this.objForm.get('org_user_expire_by_last_group').patchValue(false);
       } else {
           this.objForm.get('org_user_expire_by_last_group').patchValue(true);
       }
     }
   }

   async checkUserEmail(evt) {
      // console.log('checkUserEmail, evt=', evt);
      // console.log('checkUserEmail usrEmail=', this.usrEmail);
      console.log('checkUserEmail usrEmail=', this.usrEmail.nativeElement.value);
      const val = this.usrEmail.nativeElement.value;
      /****
      const emailCtl = this.objForm.get('org_user_email');
      if ( emailCtl ) {
        if (emailCtl.hasError('required')) {
          console.log('org-user email has email required!');
        }
        if (emailCtl.hasError('email')) {
          console.log('org-user email has email error!');
        }
        if (!emailCtl.hasError('email')) {
          console.log('org-user email NOT has email error.');
          this.userData$ = this.usersvc.getUserInfoByEmail$(emailCtl.value);
        }
        const usr = await this.usersvc.getUserByEmailPromise(emailCtl.value);
        console.log('checkUserEmail usersvc.User=', this.usersvc.User);
        console.log('checkUserEmail usersvc.User=', usr);
      }
      ****/
      if (val) {
        this.emailUserInfo = await this.usersvc.getUserByEmailPromise(val);
        this.userInfo$ = this.usersvc.getUserByEmail$(val)
        console.log('checkUserEmail usersvc.User=', this.usersvc.User);
        console.log('checkUserEmail this.userInfo=', this.emailUserInfo);
      }
   }

   getOrgUserCounts() {
       this.availEmpOrgUsers$ = this.orgusersvc.getOrgAvailableEmpCount$( this.selectedOrg.uid );
       this.availGuestOrgUsers$ = this.orgusersvc.getOrgAvailableGuestCount$( this.selectedOrg.uid );
   }

   createForm() {

     this.submitted = false;

     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // console.log('createForm snow=', snow);
     // console.log('createForm snm=', snm);

     // console.log('createForm mode =', this.mode );
     // console.log('createForm isAddMode =', this.isAddMode );
     // console.log('createForm isObjAdmin =', this.isObjAdmin );
     // console.log('createForm isOrgOwner =', this.isOrgOwner );

     // console.log('createForm objData=', this.objData);

     // console.log('createform selectedOrg=', this.selectedOrg);

     /****
     this.isOrgOwner =
           (this.selectedOrg && this.objData && this.selectedOrg.org_owner_email===this.objData.org_user_email) ?
           true : false;
     ****/

     // this.isObjAdmin = await this.getIsOrgAdmin( this.selectedOrg, this.userData);
     console.log('createForm this.isOrgOwner=', this.isOrgOwner);
     console.log('createForm this.viewOnly=', this.viewOnly);
     console.log('createForm this.isObjAdmin=', this.isObjAdmin);
     // console.log('createForm this.fb.group=', this.fb.group);

     const editAdmin = (!this.viewOnly && this.isObjAdmin && !this.isOrgOwner);
     console.log('createForm editAdmin=', editAdmin);

     const oid = (this.selectedOrg && this.selectedOrg.uid) ?
               this.selectedOrg.uid : this.objData.org_uid;

     this.objForm = this.fb.group({
           uid: [{value: '', disabled: true}],  // OK
           org_uid: [{value: oid, disabled: true}],  // OK
           org_name: [{value: '', disabled: true}],  // OK
           org_user_uid: [{value: '', disabled: true}],

           org_user_type: (!this.viewOnly && this.isObjAdmin && !this.isOrgOwner) ?
                          ['E', [Validators.required]] :
                          [{value: 'E', disabled: true}],

           org_user_email: [{value: '', disabled: true}, [Validators.required, Validators.email]],

           user_name: [{value: '', disabled: true}],
           user_last_name: [{value: '', disabled: true}],

           org_user_active: [{value: true, disabled: true}, [Validators.required] ],

           org_user_emp_id: this.viewOnly ?
                       [{value: '', disabled: true}] :
                       [''],

           org_admin: (!this.viewOnly && this.isObjAdmin && !this.isOrgOwner) ?
                      [false, [Validators.required]] :
                      [{value: false, disabled: true}],

           org_billing_admin: (!this.viewOnly && this.isObjAdmin && !this.isOrgOwner) ?
                              [false, [Validators.required]] :
                              [{value: false, disabled: true}],

           org_guest_user_admin:
                      (!this.viewOnly   && this.isObjAdmin && !this.isOrgOwner) ?
                      [false, [Validators.required]] :
                      [{value: false, disabled: true}],

           org_user_group_admin:
                      (!this.viewOnly && this.isObjAdmin && !this.isOrgOwner) ?
                      [true, [Validators.required]] :
                      [{value: true, disabled: true}],

           org_user_role: [{value: 'USER', disabled: true}],
           org_user_subscription_id: [{value: '', disabled: true}],

           org_user_expire_by_date: (!this.viewOnly && this.isObjAdmin && !this.isOrgOwner) ?
                                    [false]:
                                    [{value: false, disabled: true}],

           org_user_expires: (this.objData && this.objData.org_user_expire_by_date) ?
                             [{value: null, disabled: false}, [Validators.required, Validators.min(16), FORMTOOLS.dateTimeValidator]] :
                             [{value: null, disabled: true}],

           // org_user_expires: [{value: null, disabled: true}],

           org_user_expire_by_last_group: [{value: false, disabled: true}],

           created: [{value: '', disabled: true}],
           modified: [{value: '', disabled: true}],
           modified_by: [{value: this.userData.uid, disabled: true}],
           timestamp: [{value: epoch_time, disabled: true}]
     },{updateOn: 'blur'});

     // this.usrEmail.nativeElement.focus();

     this.objForm.get('org_user_type').valueChanges.subscribe( chg => {
        console.log('user_type =', chg);
        if ( this.isAddMode ) {
          this.getOrgUserCounts();
        }
        if ( chg === 'G' || this.isOrgOwner ) {
          let val = false;
          if ( this.isOrgOwner ) {
            val = true;
            // this.objForm.get('org_user_type').disable();
            this.objForm.get('org_user_group_admin').setValue(val);
          }
          this.objForm.get('org_admin').disable();
          this.objForm.get('org_admin').setValue(val);
          this.objForm.get('org_billing_admin').disable();
          this.objForm.get('org_billing_admin').setValue(val);
          this.objForm.get('org_guest_user_admin').disable();
          this.objForm.get('org_guest_user_admin').setValue(val);
        } else {
                // this.objForm.get('org_user_type').enable();
                this.objForm.get('org_admin').enable();
                this.objForm.get('org_billing_admin').enable();
                this.objForm.get('org_user_group_admin').enable();
                this.objForm.get('org_guest_user_admin').enable();
          }
        this.cd.detectChanges();
     });


     /****
     this.objForm.get('org_user_expire_by_date').valueChanges.subscribe( chg => {
        console.log('expire_by_date =', chg);
        if ( chg ) {
          this.objForm.get('org_user_expires').enable();
          // this.objForm.patchValue({org_user_expires: snm});
        } else {
            this.objForm.get('org_user_expires').disable();
            this.objForm.patchValue({org_user_expires: null});
          }
        // this.expByDate.nativeElement.focus();
        this.cd.detectChanges();
     });
     ***/

     console.log('createForm objForm=', this.objForm);

     // console.log('createForm =', this.objForm);

   }

   updateFormControls(isAdmin) {
      console.log('updateFormControls isAdmin=', isAdmin);
      const enableFields = (!this.viewOnly && isAdmin);
      console.log('updateFormControls enableFields=', enableFields);
      if (this.objForm) {
         (enableFields && !this.isOrgOwner) ?
                        this.objForm.controls.org_user_type.enable() :
                        this.objForm.controls.org_user_type.disable();
         (enableFields && this.mode === this.DisplayMode.ADD) ?
                        this.objForm.controls.org_user_email.enable() :
                        this.objForm.controls.org_user_email.disable();
         (enableFields && !this.isOrgOwner) ?
                        this.objForm.controls.org_user_active.enable() :
                        this.objForm.controls.org_user_active.disable();
         enableFields ? this.objForm.controls.org_user_emp_id.enable() :
                        this.objForm.controls.org_user_emp_id.disable();

         (enableFields && this.userType !== 'G' && isAdmin && !this.isOrgOwner) ?
                        this.objForm.controls.org_admin.enable() :
                        this.objForm.controls.org_admin.disable();

         (enableFields && this.userType !== 'G' && isAdmin && !this.isOrgOwner) ?
                        this.objForm.controls.org_billing_admin.enable() :
                        this.objForm.controls.org_billing_admin.disable();

         (enableFields && this.userType !== 'G' && isAdmin && !this.isOrgOwner) ?
                        this.objForm.controls.org_guest_user_admin.enable() :
                        this.objForm.controls.org_guest_user_admin.disable();

         (enableFields && isAdmin && !this.isOrgOwner) ?
                        this.objForm.controls.org_user_group_admin.enable() :
                        this.objForm.controls.org_user_group_admin.disable();

         enableFields ? this.objForm.controls.org_user_expire_by_date.enable() :
                        this.objForm.controls.org_user_expire_by_date.disable();
         enableFields ? this.objForm.controls.org_user_expires.enable() :
                        this.objForm.controls.org_user_expires.disable();
      } // if
   }

   org_admin_change(e) {
      console.log('org_admin changed e=', e);
      if ( !e.checked ) {
        // Ask are you sure to remove privs
        this.openAdminChangeDialog('A', 'Administrator');
      }
   }

   org_billing_admin_change(e) {
      console.log('org_billing_admin changed e=', e);
      if ( !e.checked ) {
        // Ask are you sure to remove privs
        this.openAdminChangeDialog('B', 'Billing Administrator');
      }
   }

   org_user_guest_user_admin_change(e) {
      console.log('org_user_guest_user_admin changed e=', e);
      /*****
      if ( !e.checked ) {
        // Ask are you sure to remove privs
        this.openAdminChangeDialog('B', 'Billing Administrator');
      }
      *****/
      this.updateFormControls(this.isObjAdmin);
   }

   org_user_group_admin_change(e) {
      console.log('org_user_group_admin changed e=', e);
      /*****
      if ( !e.checked ) {
        // Ask are you sure to remove privs
        this.openAdminChangeDialog('B', 'Billing Administrator');
      }
      *****/
      this.updateFormControls(this.isObjAdmin);
   }

   resetForm() {
      this.setForm();
      this.objForm.markAsPristine();
      this.objForm.markAsUntouched();
   }

   setForm() {

     if(!this.objData || !this.objData.uid || this.objData.uid == null) {
        return;
     }

     this.submitted = false;

     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));


     // this.isObjAdmin = await this.getIsOrgAdmin( this.selectedOrg, this.userData);

     // console.log('setForm snow=', snow);
     // console.log('setForm snm=', snm);
     console.log('setForm viewOnly=', this.viewOnly);
     console.log('setForm isObjAdmin=', this.isObjAdmin);
     console.log('setForm isOrgOwner=', this.isOrgOwner);
     console.log('setForm objData:')
     console.table(this.objData);
     console.log('setForm selectedOrg:', this.selectedOrg);
     console.log('setForm selectedObj:', this.selectedObj);

     //console.table('setForm objData=', this.objData);

     // Get user record info
     if (this.objData.user_uid) {
       this.userInfo$ = this.usersvc.getUserInfo$(this.objData.user_uid);
     }

     if ( this.selectedOrg && this.objForm ) {
        this.objForm.patchValue({org_name: this.selectedOrg.org_name });
     }

     this.isOrgOwner =
            (this.selectedOrg && this.objData && this.selectedOrg.org_owner_email===this.objData.org_user_email) ?
            true : false;
     // console.log('createForm this.isOrgOwner=', this.isOrgOwner);

     // console.log('setForm objData=', this.objData);
     // console.log('setForm selectedOrg=', this.selectedOrg);

     // (this.selectedOrg)  ?
     //   this.objForm.patchValue({org_uid: this.selectedOrg.uid }) :

     if (this.objData) {
       this.userType = this.objData.org_user_type;

       this.objForm.patchValue({org_uid: this.objData.org_uid });
       this.objForm.patchValue({uid: this.objData.uid });
       this.objForm.patchValue({org_user_uid: this.objData.org_user_uid });
       this.objForm.patchValue({org_user_email: this.objData.org_user_email });
       this.objForm.patchValue({org_user_type: this.objData.org_user_type });
       this.objForm.patchValue({org_user_active: this.objData.org_user_active });
       this.objForm.patchValue({org_user_emp_id: this.objData.org_user_emp_id });

       this.objForm.patchValue({org_admin: this.objData.org_admin });
       this.objForm.patchValue({org_billing_admin: this.objData.org_billing_admin });
       this.objForm.patchValue({org_guest_user_admin: this.objData.org_guest_user_admin });
       this.objForm.patchValue({org_user_group_admin: this.objData.org_user_group_admin });

      if (this.objData.org_user_type === 'G') {
        this.objForm.get('org_admin').disable();
        this.objForm.get('org_billing_admin').disable();
        this.objForm.get('org_guest_user_admin').disable();
        this.objForm.patchValue({org_admin: false });
        this.objForm.patchValue({org_billing_admin: false });
        this.objForm.patchValue({org_guest_user_admin: false });
      }

      // org owner form constraints
      if (this.isOrgOwner) {
       this.objForm.get('org_user_active').disable();
       this.objForm.get('org_admin').disable();
       this.objForm.get('org_billing_admin').disable();
       this.objForm.get('org_guest_user_admin').disable();
       this.objForm.get('org_user_group_admin').disable();
       this.objForm.patchValue({org_user_active: true});
       this.objForm.patchValue({org_admin: true});
       this.objForm.patchValue({org_billing_admin: true});
       this.objForm.patchValue({org_guest_user_admin: true});
       this.objForm.patchValue({org_user_group_admin: true});
      }

       if ( this.userType === 'G') {
          this.objForm.get('org_admin').disable();
          this.objForm.get('org_admin').setValue(false);
          this.objForm.get('org_billing_admin').disable();
          this.objForm.get('org_billing_admin').setValue(false);
          // this.objForm.get('org_user_group_admin').disable();
          // this.objForm.get('org_user_group_admin').setValue(false);
          this.objForm.get('org_guest_user_admin').disable();
          this.objForm.get('org_guest_user_admin').setValue(false);
       } else if (this.isOrgOwner) {
                 // this.objForm.get('org_user_type').disable();
                 this.objForm.get('org_admin').disable();
                 this.objForm.get('org_billing_admin').disable();
                 this.objForm.get('org_user_group_admin').disable();
                 this.objForm.get('org_guest_user_admin').disable();
                 this.objForm.patchValue({org_user_active: true});
                 this.objForm.patchValue({org_admin: true});
                 this.objForm.patchValue({org_billing_admin: true});
                 this.objForm.patchValue({org_guest_user_admin: true});
                 this.objForm.patchValue({org_user_group_admin: true});
               } else {
                    // this.objForm.get('org_user_type').enable();
                    this.objForm.get('org_admin').enable();
                    this.objForm.get('org_billing_admin').enable();
                    this.objForm.get('org_user_group_admin').enable();
                    this.objForm.get('org_guest_user_admin').enable();
                 }

      this.objForm.patchValue({org_user_role: this.objData.org_user_role });
      this.objForm.patchValue({org_user_subscription_id: this.objData.org_user_subscription_id });
      this.objForm.patchValue({org_user_expire_by_date: this.objData.org_user_expire_by_date });
      this.objForm.patchValue({org_user_expire_by_last_group: this.objData.org_user_expire_by_last_group });

        /*****
        let expiration = null;
        console.log('org-user expiration=', expiration);
        if (this.objData.org_user_expires) {
           expiration = FORMTOOLS.checkISODateTime(this.objData.org_user_expires);
           this.objForm.patchValue({org_user_expires: expiration});
        } else {
             this.objForm.patchValue({org_user_expires: null});
          }
        ****/
        console.log('org-user org_user_expires=', this.objData.org_user_expires);
        this.updateExpirationDate();

        if (this.objForm.get('created').value) {
           this.objForm.patchValue({created: this.objData.created });
        } else {
           this.objForm.patchValue({created: snow });
        }
        this.objForm.patchValue({modified: snow});
        this.objForm.patchValue({modified_by: this.userData.uid});
        this.objForm.patchValue({timestamp: epoch_time});

        this.updateFormControls(this.isObjAdmin);

        this.getObjFieldRefs(this.objData);
     }

   } // setForm()

   clearForm() {

     this.submitted = false;

     const epoch_time = new Date().getTime();
     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     this.objForm.patchValue({uid: '' } );
     this.objForm.patchValue({org_uid: '' } );
     this.objForm.patchValue({org_name: '' } );
     this.objForm.patchValue({org_user_uid: '' } );
     this.objForm.patchValue({org_user_type: '' } );
     this.objForm.patchValue({org_user_emp_id: '' } );
     this.objForm.patchValue({org_user_active: true } );
     this.objForm.patchValue({org_admin: false } );
     this.objForm.patchValue({org_billing_admin: false } );
     this.objForm.patchValue({org_guest_user_admin: false } );
     this.objForm.patchValue({org_user_group_admin: false } );
     this.objForm.patchValue({org_role: '' } );
     this.objForm.patchValue({org_user_subscription_id: '' } );
     this.objForm.patchValue({org_user_expire_by_date: false } );
     this.objForm.patchValue({org_user_expire_by_last_group: false } );
     this.objForm.patchValue({org_user_expires: null });
     this.objForm.patchValue({created: snow });
     //this.objForm.patchValue({modified: snow });
     //this.objForm.patchValue({modified_by: this.userData.uid });
     //this.objForm.patchValue({timestamp: epoch_time });
   }

   async addRecord( form ) {
      console.log('group addRecord:', form.getRawValue());
      const data = form.getRawValue();
      delete data.uid;
      delete data.created_at;
      delete data.timestamp;
      delete data.org_name;
      delete data.user_name;
      delete data.user_last_name;
      if (data.org_user_expires) {
        data.org_user_expires = FORMTOOLS.checkISODateTime(data.org_user_expires);
      }
      console.table('org-user addRecord data:', data);
      return await this.addObjData( data );
   }

   async editRecord( form ) {
      const uid = form.getRawValue().uid;
      const data = form.getRawValue();
      // remove any extraneous form data
      delete data.uid;
      delete data.user_name;
      delete data.user_last_name;
      delete data.org_name;
      delete data.created_at;
      delete data.timestamp;
      if (data.org_user_expires) {
        data.org_user_expires = FORMTOOLS.checkISODateTime(data.org_user_expires);
      }
      console.table('org editRecord:', data );
      return await this.editObjData( uid, data );
   }

   async deleteRecord(obj) {
      if ( obj ) {
        console.log('org-user deleteRecord:', obj);
        return await this.deleteObjData( obj );
      }
   }

   refreshList(obj) {
      console.log('refreshList obj=', obj);
      this.refreshEvent.emit(obj);
   }

   async getOrgUserInfo(oid, uid) {
      let result = null;
      try {
         result = await this.orgusersvc.getOrgUserByOrgAndUidPromise(oid, uid);
      } catch (e)  {
          console.error('org - cant get orguserinfo e=', e);
      }
      return result;
   }

   async addObjData( data ) {
      console.log('add org user data=', data);
      let result = null;
      try {
          result = await this.orgusersvc.addOrgUserPromise( data );
          this.submitEvent.emit(result);
          this.toast.pop('success', 'Add Company/Org. User', 'Company/Org. User Saved');            console.log('add org user result=', result);
      } catch (e)  {
           console.error('org - cant add orgdata e=', e);
           this.toast.pop('error', 'Add Company/Org. User', 'Add Company/Org. User Failed! e=', e);
      }
      console.log('add org data=', result);
      this.refreshEvent.emit();
      return result;
   }

   async editObjData( oid, data ) {
      let result = null;
      console.log('edit org user data=', data);
      try {
         result = await this.orgusersvc.updateOrgUserPromise(oid, data);
         this.setObjData(result);
         this.setSelectedObj();
         this.submitEvent.emit(result);
         this.getObjFieldRefs(result);
         console.log('orguser edit result=', result);
         this.toast.pop('success', 'Update Company/Org. User', 'Company/Org. User Updated');
      } catch (e)  {
           console.error('org - cant edit orgdata e=', e);
           this.toast.pop('error', 'Update Company/Org. User', 'Company/Org. User Update failed! e=', e);
      }
      this.refreshEvent.emit();
      return result;
   }

   async deleteObjData( obj ) {
      let result = null;
      console.table( 'delete orguser obj=', obj );
      console.log( 'delete orguser isObjAdmin=', this.isObjAdmin );
      if ( ! this.userData.iam && ! this.isObjAdmin ) {
        console.error('No Permission to delete company/org. user!');
        return;
      }
      try {
         result = await this.orgusersvc.removeOrgUserPromise(obj.uid);
         console.log( 'delete orguser result =', result );
         this.toast.pop('success', 'Delete Company/Org. User', 'Deleted Company/Org User', obj.org_user_email);
         setTimeout(() => {
           console.log('delete org-user success.');
         }, 2000);
         this.deleteObjEvent.emit(obj);
         this.refreshEvent.emit();
         if ( this.selectedObj && this.selectedObj.uid &&
            ( this.selectedObj.uid === obj.uid ) )  {
               this.selectObjEvent.emit( null);
         }
      } catch (e)  {
           console.error('company/org - cant delete error e=', e);
           this.toast.pop('error', 'Delete Company/Org.', 'Delete Company/Org. Failed for ', obj.org_user_email);
           const erec = { name: obj.org_user_email,
                          error: e};
           this.deleteErrorEvent.emit(erec);
      }
      return result;
   }

   async xferOrgUserData( fromOrgUser, toOrgUser=null ): Promise<boolean> {
      let status = false;
      console.table( 'xfer orguser from=', fromOrgUser );
      console.table( 'xfer orguser to=', toOrgUser );
      console.log( 'xfer orguser isObjAdmin=', this.isObjAdmin );
      //
      // ****** Needs to check isOrgAdmin also ****
      //
      if ( ! this.userData.iam && ! this.isObjAdmin ) {
        console.error('No Permission to transfer company/org. content!');
        this.toast.pop('error', 'Transfer Company/Org. User Content', 'Transfer Company/Org. Content Failed (no permissions) for ' +  fromOrgUser.org_user_email + ' to ' + toOrgUser.org_user_email);
        return;
      }
      try {
         const result = await this.orgusersvc.xferOrgUserContentPromise(fromOrgUser.uid, toOrgUser.uid);
         status = true;
         console.log( 'xfer orguser result =', result );
         this.toast.pop('success', 'Transfer Company/Org. User Content', 'Transferred  Company/Org User' + fromOrgUser.org_user_email + ' to ' + toOrgUser.org_user_email);
         setTimeout(() => {
           console.log('xfer org-user success.');
         }, 2000);
      } catch (e)  {
           console.error('company/org - cant transfer content error e=', e);
           this.toast.pop('error', 'Transfer Company/Org. User Content', 'Transfer Company/Org. Content Failed for ' +  fromOrgUser.org_user_email + ' to ' + toOrgUser.org_user_email);
      }
      return status;
   }

   viewObjDialog() {
      console.log('org-user viewObjDialog');
      this.openFormDialog( this.DisplayModel.ORG_USR, this.DisplayMode.VIEW, this.objData);
   }

   createObjDialog() {
      this.openFormDialog( this.DisplayModel.ORG_USR, this.DisplayMode.ADD, this.objData);
   }

   editObjDialog() {
      this.openFormDialog( 'ORGUSER', 'E', this.objData);
   }

   deleteObjDialog() {
      this.openDeleteFormDialog( this.objData );
  }

   OLDdeleteObjDialog() {
      this.openDeleteObjDialog( this.objData.uid, this.objData.org_user_email, null );
  }

  xferContentDialog() {
      this.openXferContentDialog( this.objData );
  }


   async openAdminChangeDialog(type, name) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const msg = 'Are you sure you want to remove ' + name + ' privileges for this user?';
      let msg2 = '';
      if (type === 'A') {
        msg2 = 'Note: If this is your user account you wont be able to undo the change.';
      }
      const msg3 = '';

      const obj_data = {
         type: type,
         name: name,
         obj: this.objData
      }

      dialogConfig.data = {
         intent: type,
         title: 'Are You Sure? ',
         message: msg,
         message2: msg2,
         message3: msg3,
         message1Color: 'red',
         button1Color: 'red',
         button1Text: 'Yes, Remove Admin. Role',
         button2Color: 'black',
         button2Text: 'No, Keep Admin. Role',
         dialogData: obj_data
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open( ActionPromptDialogComponent, dialogConfig);
      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         async data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('deleteObj action-prompt intent=',
                            data.intent);
               console.log('deleteObj action-prompt return=',
                            data.choice);
               console.log('deleteObj action-prompt return=',
                            data.dialogData);
            }
            // if data returned take default action
            console.log('openAdminChangeDialog data=', data);
            switch (data.intent) {
              case 'A': if (this.objForm && data.choice===1) {
                          this.objForm.patchValue({org_admin: false});
                        } else {
                            this.objForm.patchValue({org_admin: true});
                          }
                        this.isObjAdmin = await this.getIsObjAdmin(this.userData);
                        this.updateFormControls(this.isObjAdmin);
                        break;
              case 'B': if (this.objForm && data.choice===1) {
                          this.objForm.patchValue({org_billing_admin: false});
                        } else {
                            this.objForm.patchValue({org_billing_admin: true});
                          }
                        this.isObjAdmin = await this.getIsObjAdmin(this.userData);
                        this.updateFormControls(this.isObjAdmin);
                        break;
            }
            this.cd.detectChanges();
          } // data
      );
    } // openAdminChangeDialog

   async openDeleteObjDialog(uid, name, xfdata) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      console.log('org-user openDeleteObjDialog xfdata=', xfdata);
      console.log('org-user openDeleteObjDialog objdata=', xfdata.objData);
      const from_ou = xfdata.objData;
      console.log('org-user openDeleteObjDialog from_ou=', from_ou);

      const contentXferType = xfdata.objContentXferType;
      const to_ou = xfdata.objContentXferToOrgUser;

      console.log('org-user openDeleteObjDialog Type=', contentXferType);
      console.log('org-user openDeleteObjDialog ToUser=', to_ou);

      const msg = 'Are you sure you want to permanently delete ' +
                  this.objLabel + ' ' + name + '?';
      let msg2 = '';
      switch(contentXferType) {
        case 0:
                 msg2 = 'Note: All Company groups and documents owned by ' + from_ou.org_user_email + ' will be reassigned to the Company/Org owner and this user will be removed from all Company/Org. groups and from the Company/Org subscription.';
                 break;
        case 1:
                 msg2 = 'Note: All Company groups and documents owned by ' + from_ou.org_user_email + ' will be reassigned to the Company/Org User ' + to_ou.org_user_email + ' and ' + from_ou.org_user_email + ' will be removed as a member from all Company/Org. groups before being removed from the Company/Org subscription.';
                 break;
        case 2:
                 msg2 = 'Note: All Company groups and documents owned by the Company/Org User ' + from_ou.org_user_email + ' will be permanently removed.'
                 break;
        default:
                 msg2 = 'Note: All company groups and documents will continue to be owned by user ' + name;
                 break;
      }
      const msg3 = '';

      const obj_data = {
         uid: uid,
         name: name,
         contentXferType: contentXferType,
         contentXferFromUser: from_ou,
         contentXferToUser: to_ou
      }

      dialogConfig.data = {
         intent: 'deleteOrgUser',
         title: 'Delete ' + this.objLabel,
         message: msg,
         message2: msg2,
         message3: msg3,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Delete',
         dialogData: obj_data
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open( ActionPromptDialogComponent, dialogConfig);
      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         async data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('deleteObj action-prompt intent=',
                            data.intent);
               console.log('deleteObj action-prompt choice=',
                            data.choice);
               console.log('deleteObj action-prompt data=',
                            data);
               console.log('deleteObj action-prompt returnData=',
                            returnData);
               console.log('deleteObj action-prompt data.contentXferType=', data.data.contentXferType);
              // if data returned take default action
              if ( data.data.contentXferType === 0 ) {
                 console.log('org-user xfer org user to owner and delete...');
                 this.deleteObjData( this.objData );
              }
              if ( data.data.contentXferType === 1 ) {
                 console.log('org-user xfer org user content...');
                 console.log('org-user xfer org user data=', data.data);
                 console.log('org-user xfer org from ou=', data.data.contentXferFromUser);
                 console.log('org-user xfer org to ou=', data.data.contentXferToUser);
                 const ok = this.xferOrgUserData( data.data.contentXferFromUser,
                                                  data.data.contentXferToUser);
                 console.log('org-user xfer org user content ok=', ok);
                 if (ok) {
                   this.deleteObjData( this.objData );
                 }
              }
            } // if data
          } // data
      );
    } // openDeleteObjDialog

   openDeleteFormDialog(obj_data) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const model = this.DisplayModel.ORG_USR_CONTENT_XFER;
      const mode = this.DisplayMode.DELETE;

      const title = 'Company/Org. User Delete';
      // console.log('org-user delete form dialog mode=', mode);

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG_USR_CONTENT_XFER,
         objMode: this.DisplayMode.XFER_LIST,
         objData: obj_data,
         dialogTitle: title,
         selectedObj: this.selectedObj,
         selectedOrg: this.selectedOrg,
         // selectedOrgUser: this.selectedOrgUser,
         /***
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               console.log('formDialog data=', data);
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
               this.openDeleteObjDialog( obj_data.uid, obj_data.org_user_email, data );
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
            setTimeout(() => {
               this.refreshEvent.emit();
            }, 1100);
          } // data
      );
   } // openDeleteFormDialog

   openXferContentDialog(obj_data) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const model = this.DisplayModel.ORG_USR_CONTENT_XFER;
      const mode = this.DisplayMode.DELETE;

      const title = 'Company/Org. User Content Owner Transfer';
      // console.log('org-user delete form dialog mode=', mode);

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG_USR_CONTENT_XFER,
         objMode: this.DisplayMode.XFER_LIST,
         objData: obj_data,
         dialogTitle: title,
         selectedObj: this.selectedObj,
         selectedOrg: this.selectedOrg,
         // selectedOrgUser: this.selectedOrgUser,
         /***
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               console.log('formDialog data=', data);
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
               this.xferOrgUserData( data.objData, data.objContentXferToOrgUser );
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
            setTimeout(() => {
               this.refreshEvent.emit();
            }, 1100);
          } // data
      );
   } // openXferContentDialog

   openFormDialog(model, mode, obj_data) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      let title = 'Company/Org. User';
      switch (mode) {
        case this.DisplayMode.VIEW:
              title = 'View ' + this.objLabel;
              break;
        case this.DisplayMode.ADD:
              title = 'Create ' + this.objLabel;
              break;
        case this.DisplayMode.EDIT:
              title = 'Edit ' + this.objLabel;
              break;
        case this.DisplayMode.DELETE:
              title = 'Delete ' + this.objLabel;
              break;
      }
      console.log('org-user dialog mode=', mode);

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.ORG_USR,
         objMode: mode,
         objData: obj_data,
         dialogTitle: title,
         selectedObj: this.selectedObj,
         selectedOrg: this.selectedOrg,
         // selectedOrgUser: this.selectedOrgUser,
         /***
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
            setTimeout(() => {
               this.refreshEvent.emit();
            }, 1100);
          } // data
      );
    } // openFormDialog

} // org-user component

<div #memberView style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; height: 100%; !important; width: 100% !important; height:100% !important border: 1px solid #1A567A">
    <!--
     <div class="filter-div">
       <mat-form-field>
         <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Filter">
       </mat-form-field>
    </div>      
    -->

   <mat-table *ngIf="template===this.EDIT_MEMBERS" #table [dataSource]="this.groupMembers" style="width: 100%; height: 100%;">

  <!-- <mat-table #table [dataSource]="dataSource"> -->

    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </mat-cell>
    </ng-container>

    <!-- Action -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>      
      <mat-cell *matCellDef="let row">
        <button mat-button
		*ngIf="selectedOwner===userInfo.uid && row.uid !== userInfo.uid"
		[matMenuTriggerFor]="mbrMenu" xPosition="after">
                <mat-icon id="li-more-icon">more_vert</mat-icon>	  
	</button>
        <mat-menu #mbrMenu="matMenu">
            <button mat-menu-item disabled>
               <mat-icon>admin_panel_settings</mat-icon>
               <span>Set Privileges</span>
            </button>	  
            <button mat-menu-item (click)="handleRemoveMember(this.groupUID, row.uid)">
               <mat-icon>person_remove</mat-icon>
               <span>Remove Member</span>
            </button>
        </mat-menu>
      </mat-cell>      
    </ng-container>      

    <!-- Privs Action -->
    <ng-container matColumnDef="privs">
      <mat-header-cell *matHeaderCellDef>Set Privileges</mat-header-cell>      
      <mat-cell *matCellDef="let member">
        <button mat-icon-button disabled
		*ngIf="this.selectedOwner===this.userInfo.uid">
             <mat-icon>admin_panel_settings</mat-icon>
          </button>	  
      </mat-cell>      
    </ng-container>      

   <!-- Remove Member Action -->
    <ng-container matColumnDef="remove">
      <mat-header-cell *matHeaderCellDef>Remove Group Member</mat-header-cell>      
      <mat-cell *matCellDef="let member">
        <button mat-icon-button
		*ngIf='selectedGroupObj && selectedGroupObj.owner === userInfo.uid && userInfo.uid !== member.uid'
		(click)="removeMember(this.groupUID, member.uid);">
             <mat-icon>person_remove</mat-icon>
          </button>
      </mat-cell>      
    </ng-container>      

    <!-- Member Role  -->
    <ng-container matColumnDef="role">
      <mat-header-cell *matHeaderCellDef>Role</mat-header-cell>
      <mat-cell *matCellDef="let member">
	    <div *ngIf="this.selectedOwner===member.uid;else mbr">
	      <b>owner</b>
	    </div>
            <ng-template #mbr>
               <div>member</div>
            </ng-template>
      </mat-cell>      
    </ng-container>      

    <!-- Member Access  -->
    <ng-container matColumnDef="access">
      <mat-header-cell *matHeaderCellDef>Privileges</mat-header-cell>
      <mat-cell *matCellDef="let member">
	    <div *ngIf="this.selectedOwner===member.uid;else mbr">
	      <b>View, Markup, Add, Delete</b></div>
              <ng-template #mbr>
                 <div>View, Markup, Add</div>
              </ng-template>
      </mat-cell>      
    </ng-container>      
    
    <!-- email Column -->
    <ng-container matColumnDef="email">
      <mat-header-cell *matHeaderCellDef>Group Member Email</mat-header-cell>
      <mat-cell *matCellDef="let member"
		(click)="displayUserInfo(member.uid)"
		style="word-wrap: break-word; width: 20%; cursor: default"
		>{{member.email}}</mat-cell>
    </ng-container>
    
    <!-- First Name Column -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>First Name</mat-header-cell>
      <mat-cell *matCellDef="let member">{{member.name}}</mat-cell>
    </ng-container>

    <!-- last Name Column -->
    <ng-container matColumnDef="last_name">
      <mat-header-cell *matHeaderCellDef>Last Name</mat-header-cell>
      <mat-cell *matCellDef="let member">{{member.last_name}}</mat-cell>
    </ng-container>

    <!-- Full Name Column -->
    <ng-container matColumnDef="full_name">
      <mat-header-cell *matHeaderCellDef>Group Member Name</mat-header-cell>
      <mat-cell *matCellDef="let member"
		style="cursor: default"
                (click)="displayUserInfo(member.uid)">
	        {{member.name}} {{member.last_name}}</mat-cell>
    </ng-container>

    <!-- company Column -->
    <ng-container matColumnDef="company">
      <mat-header-cell *matHeaderCellDef>Company</mat-header-cell>
      <mat-cell *matCellDef="let member"> {{member.company}} </mat-cell>
    </ng-container>

    <!-- Company Column -->
    <ng-container matColumnDef="symbol">
      <mat-header-cell *matHeaderCellDef> Company </mat-header-cell>
      <mat-cell *matCellDef="let member"> {{member.company}} </mat-cell>
    </ng-container>

    <!-- Phone Column -->
    <ng-container matColumnDef="phone">
      <mat-header-cell *matHeaderCellDef> Phone </mat-header-cell>
      <mat-cell *matCellDef="let member"> {{member.phone}} </mat-cell>
    </ng-container>
   
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

  <!-- Group Member SideNav component Template -->

   <!-- show buttons for members below ui -->
  <!-- <div *ngIf='g.owner === userInfo.uid'> -->
    <div *ngIf="template===SIDENAV_MEMBERS"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 99%; margin-bottom: 0.5rem">
 <!--
    <button mat-button-legacy class="members-button"
	    style="align: center"
	    (click)="show_members(this.groupUID);">Members
    </button>
  -->
    <div>{{countMembers()}} Users</div>
    <div #scrUp class="scroll" id="scroll-up" (click)="scroll_up();">
      <mat-icon style="align: middle">arrow_drop_up</mat-icon>
    </div>
    <div #memberlist class="group-members" id="member-div">
      <!-- Issue #300 -->		      
         <ul id="ul-member" >
           <li *ngFor="let u of this.groupMembers">
               <span *ngIf='selectedGroupObj.owner === userInfo.uid && userInfo.uid !== u.uid'>
		 <button mat-icon-button
   		         (click)="removeMember(this.groupUID, u.uid)">
		   <mat-icon id="li-remove-icon">
		   person_remove</mat-icon>
	         </button>
	       </span>
            <!-- <span id="fl-init"></span> -->
            <div *ngIf="u.last_name;else disp_email"
		 (click)="displayUserInfo(u.uid)">
                   {{u.name}} {{u.last_name}}
		   <mat-icon *ngIf="selectedGroupObj.owner === u.uid"
			     id="admin-star">star</mat-icon> 
               </div>                               
               <ng-template #disp_email>
                 <div (click)="displayUserInfo(u.uid)">{{u.email}}
		 </div>
	       </ng-template>
	    </li>
         </ul>
      </div>
    <div #scrDn class="scroll" id="scroll-down"
	   style="opacity 1;"
	   (click)="scroll_down();">
	<mat-icon>arrow_drop_down</mat-icon>
      </div>
  </div>
    <!-- END Sidenav Template  -->
  <div *ngIf="template === MAT_NAV_LIST"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 98%">
    <mat-divider></mat-divider>	  
    <div *ngFor="let u of this.groupMembers" style="min-height: 3.5em">
      <div style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; min-height: 3em !important; font-size: 1.25em;">
        <div *ngIf="u.name && u.last_name;else disp_email"
	      style="margin-top: 1em;"
               (click)="displayUserInfo(u.uid)">
               {{u.name}} {{u.last_name}}
               <mat-icon *ngIf="selectedGroupObj.owner === u.uid"
                         id="admin-star">star</mat-icon> 
        </div>                               
        <ng-template #disp_email>
             <div style="margin-top: 1em;" (click)="displayUserInfo(u.uid)">{{u.email}}</div>
             <mat-icon *ngIf="selectedGroupObj.owner === u.uid" id="admin-star">star</mat-icon> 
        </ng-template>
        <div style="margin-top: 0.25em">
          <button *ngIf='selectedGroupObj.owner === userInfo.uid && userInfo.uid !== u.uid'
	            mat-icon-button
                    style="margin-left: 0em; margin-right: 2em; margin-top: 0; margin-bottom: 0; font-size: 1em;"
                    (click)="removeMember(this.group, u)">
	            <mat-icon class="li-remove-icon">
		    person_remove</mat-icon>
          </button>
	  <!--                  (click)="displayUserInfo(u.uid)"> -->
          <button mat-icon-button
                 style="margin-left: 2em; margin-right: 2em; margin-top: 0; margin-bottom: 0; font-size: 1em;"
	         (click)="openUserInfoDialog(u)">
                 <mat-icon>info</mat-icon>
          </button>
        </div>
      </div> <!-- Row -->
      <mat-divider></mat-divider>	        
    </div> <!-- ngFor -->
  </div> <!-- template -->

  <!-- MAT_NAV_LIST Template -->
    <div *ngIf="template === OLD_MAT_NAV_LIST"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 98%">
      <mat-nav-list>
	<mat-divider></mat-divider>
<!--	
	<mat-list-item>
          <div>{{countMembers()}} Users</div>
	</mat-list-item>
-->
	<mat-divider></mat-divider>
      </mat-nav-list>
      <mat-nav-list #mblmemberlist
		    class="mbl-group-members"
		    id="mbl-member-div">		    
	<mat-list-item id="nav-list-member"
		       *ngFor="let u of this.groupMembers">
          <span *ngIf='selectedGroupObj.owner === userInfo.uid && userInfo.uid !== u.uid'>	  	  
          <button mat-icon-button
                    style="margin-left: 0em; margin-right: 1em; margin-top: 0; margin-bottom: 0; font-size: 1em;"
                    (click)="removeMember(this.groupUID, u.uid)">
	            <mat-icon class="li-remove-icon">
		    person_remove</mat-icon>
          </button>
          </span>
          <div *ngIf="u.last_name;else disp_email"
               (click)="displayUserInfo(u.uid)">
               {{u.name}} {{u.last_name}}
               <mat-icon *ngIf="selectedGroupObj.owner === u.uid"
                         id="admin-star">star</mat-icon> 
          </div>                               
          <ng-template #disp_email>
               <div (click)="displayUserInfo(u.uid)">{{u.email}}
               </div>
          </ng-template>
          <span *ngIf='selectedGroupObj.owner === userInfo.uid && userInfo.uid !== u.uid'>	  	  
          <button mat-icon-button
                 style="margin-left: 0em; margin-right: 1em; margin-top: 0; margin-bottom: 0; font-size: 1em;"
                 (click)="displayUserInfo(u.uid)">
                 <mat-icon>info</mat-icon>
          </button>
          </span>
          <mat-divider></mat-divider>	  
      </mat-list-item>
    </mat-nav-list>
  </div>  <!-- END MAT_NAV_LIST Template  -->

  <!-- EDIT_APPROVALS Template -->
    <div *ngIf="template === this.EDIT_APPROVALS"
	 style="display: flex; flex-flow: column nowrap; justify-content: center; align-items: stretch; width: 98%">
      <div>
        <label style="margin-top: 3px; margin-left: 5%;">
	    Select Group Members
        </label>
         <input id="emit-selected-members" class="select-item-button" type="submit" value="Done" (click)="emitSelectedMembers(grpMemberList.selectedOptions)"/>	
        <mat-selection-list #grpMemberList
 (selectionChange)="setMemberApprovals($event, grpMemberList.selectedOptions)">
          <mat-list-option *ngFor="let mbr of this.groupMembers"
			   [value]="mbr"
			   [selected]="approvalRequestExists(mbr.email)"
			   >
           {{mbr.email}}
           </mat-list-option>
        </mat-selection-list>
      </div>
      <div class="marker-button-row">
         <input id="cancel-select-members" class="cancel-button" type="button" value="Cancel" (click)="emitCancelSelection()"/>
      </div>
  </div>  <!-- END EDIT_APPROVALS Template  -->
    
</div> <!-- group-member component -->

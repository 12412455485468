import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges,
         OnDestroy,  Input, Output, ViewChild, EventEmitter, ElementRef,
         ComponentRef } from '@angular/core';

import { ChangeDetectorRef } from '@angular/core';
import { NgFor, AsyncPipe} from '@angular/common';
// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { combineLatest, Observable } from "rxjs";
import { filter, map, startWith, tap } from "rxjs/operators";
import { SelectionModel } from '@angular/cdk/collections';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

//import { OrgModule } from '../../org/org.module';
//import { OrgComponent } from '../../org/org.module';

import { ToasterService } from '../../service/toaster.service';
import { AuthService } from '../../service/auth.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';
import { GroupService } from '../../service/group.service';
import { UserService } from '../../service/user.service';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
// import { GroupData } from '../../model/groupdata.model';

import { OrgComponent } from '../org/org.component';
import { OrgData } from '../model/orgdata.model';
import { OrgUserData } from '../model/orguserdata.model';
import { OrgUserInfo } from '../model/orguserinfo.model';

import { SortKey } from '../../pipe/sortkey.pipe';
import { FilterKeys } from '../../pipe/filterkeys.pipe';
import { NameFilter } from '../../pipe/name-filter.pipe';

// import * as GRPLIST from './group-list.component'
import * as ORG from '../org/org.component'
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

@Component({
   selector: 'app-org-list',
   templateUrl: './org-list.component.html',
   styleUrls: ['./org-list.component.css'],
})
export class OrgListComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   ObjDisplayMode: typeof STATIC.Mode = STATIC.Mode;

   @Input()  iam = false;
   @Input()  isObjAdmin = false;   
   @Input()  mode = this.DisplayMode.SELECT;
   @Input()  showNoneOption = false;   
   @Input()  showTitleFilter = true;
   @Input()  showClearButton = false;   
   @Input()  showSearchButton = true;
   @Input()  showSelectButton = true;
   @Input()  useMenuActionDialog = true;
   @Input()  showActions = true;
   @Input()  showAddButton = true;
   @Input()  showInfoButton = false;
   @Input()  showEditButton = false;
   @Input()  showDeleteButton = true;
   @Input()  showMenuButton = true;
   @Input()  showRefreshButton = true;
   @Input()  showListViewButton = true;
   @Input()  template = this.DisplayTemplate.SELECT;
   @Input()  objUID: string;
   @Input()  userInfo: UserInfo;
   @Input()  userData: UserData;
   @Input()  selectedObj: any | null = null;
   @Input()  objData: any[];
// @Input()  selectedOrg: OrgData;
   @Input()  title: string;
   @Input()  titleFilter: string;
   @Input()  isPolling = false;   
   @Input()  pollData = true;
   @Input()  pollInterval = 5200;

   // @Output() getMembersEvent = new EventEmitter<any>();
   // @Output() addMemberEvent = new EventEmitter<any>();
   // @Output() removeMemberEvent = new EventEmitter<any>();

   @Output() refreshListEvent = new EventEmitter<any>();
   // @Output() refreshEvent = new EventEmitter<any>();   
   @Output() viewObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() doneEvent = new EventEmitter<any>();
   @Output() modeEvent = new EventEmitter<any>();
   
   @ViewChild(MatTable, {static: false})table: MatTable<any>;
   @ViewChild(MatSort, { static: false }) sort: MatSort;
   @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
   @ViewChild('orgTable', {static: false}) orgTable: ElementRef;
   @ViewChild('orglist', {static: false}) orglist: ElementRef;
   @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild('orgListItem', {static: false}) objListItem: OrgComponent;
   @ViewChild('orgFormItem', {static: false}) objFormItem: OrgComponent;
   //@ViewChild('orgListItem', {static: false}) orgListItem: OrgComponent;
   //@ViewChild('orgFormItem', {static: false}) orgFormItem: OrgComponent;
   @ViewChild('autoCombo') public autoComplete: MatAutocomplete;
   @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

   // Form
   objLabel = 'Company/Org.';
   comboForm: FormGroup;
   objNameInput = new FormControl('');
   orgSelectForm: FormGroup;
   formFieldStyle = 'fill';  // or outline
   viewOnly = false;
   showObjSelect = true;

   updateInterval: any;
   
   // Mode
   prevMode: string = null;

   // Combo Filter
   filteredOptions: Observable<any[] | null>;
   filteredOrgs: Observable<OrgData[]>;

   // Org and User Info
   objMode = this.ObjDisplayMode.LIST;
   selectedUser;
   selectedUserList: any[] = [];

   // Obj/Org Admin
   // see Input isObjAdmin = false;

   // Form Data
   objSelectForm: FormGroup;
   tableForm: FormGroup;
   // orgControl = new FormControl(this.selectedOrg);

   // Table Data
   displayedColumns: string[];
   adminDisplayedColumns: string[];
   dataSource: MatTableDataSource<any>;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;
   searchText: string;

   // View vars
   toggleFiberOrgs = false;
   isOrgsVisible = false;
   filterValue: string;

   // Instance vars
   orgCount: number;
   orgList: OrgData[] = [];

   loading: boolean;

   // subscriptions
   orgListSubscription: any;
   groupListSubscription: any;
   // orgUserSubscription: any;

   constructor(
      public toast: ToasterService,
      private fb: FormBuilder,
      private auth: AuthService,
      private usersvc: UserService,
      public groupsvc: GroupService,
      public orgsvc: OrganizationService,
      public orgusersvc: OrgUserService,
      public changeDetectorRef: ChangeDetectorRef,
      ) {
      } // constructor

   ngOnInit(): void {
      // console.log('org-list iam=', this.iam);
      this.userData = this.auth.getTokenInfo();

      if (! this.userData) {
        console.error('OrgListComponent WARNING userData not defined.');
      }

      // if (! this.userInfo) {
      //  console.error('OrgListComponent WARNING userInfo not defined.');
      // }

      this.refreshList();

      if ( !this.template ) {
         this.template = this.DisplayTemplate.SELECT;
      }

      this.objNameInput = new FormControl();
      this.comboForm = new FormGroup( {
         objNameInput: new FormControl()
      });

      if (this.selectedObj) {
         // console.log('group-list afterViewInit selectedObj=', this.selectedObj);
         this.setSelectedObj(this.selectedObj);
      }
   }

   async ngAfterViewInit(): Promise<void> {
      // this.refreshOrgList();
      this.userData = this.auth.getTokenInfo();


      this.refreshList();

      if ( !this.template ) {
         this.template = this.DisplayTemplate.SELECT;
      }

      if (this.selectedObj) {
         // console.log('org-list afterViewInit selectedObj=', this.selectedObj);
         this.setSelectedObj(this.selectedObj);
      }
      if (this.objData) {
         this.isObjAdmin = await this.getIsObjAdmin(this.objData, this.userData);      
      }

      this.updateInterval = setInterval(
        () => {
                if (this.pollData && !this.isPolling) {
                  this.isPolling = true;
                  this.refreshList();
                  this.isPolling = false;		  
                }
        }, this.pollInterval);
   }

   ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      // console.log('group-list changes=', changes);
      if ( changes.selectedObj )  {
         if ( !changes.selectedObj.currentValue ) {
            this.setSelectedObj(null);
         } else {
              this.setSelectedObj(changes.selectedObj.currentValue);
           }
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
         this.setMode(this.mode);
      }
      if ( changes.titleFilter ) {
         this.applyTableFilter( changes.titleFilter.currentValue );
      }
   }

   applyTableFilter(val) {
     if ( this.dataSource && val ) {
        this.dataSource.filter = val.trim().toLowerCase();
     }
   }

   clearTableFilter(val) {
     if (this.dataSource) {
        this.dataSource.filter = '';
     }
   }

   ngOnDestroy(): void {
     // stop polling updates
     if ( this.updateInterval ) {
       clearInterval(this.updateInterval);
     }
     // unsubscribe to ensure no memory leaks
     if ( this.orgListSubscription ) {
        this.orgListSubscription.unsubscribe();
     }
     if ( this.groupListSubscription ) {
        this.groupListSubscription.unsubscribe();
     }
     // console.log('group-list ondestroy');
   }



   private _filter(value: string): OrgData[] {
     return this.orgsvc.OrgList.filter(obj =>
       obj.org_name.toLowerCase().includes(value.toLowerCase())
     );
   }

   private _filterOrgNames(value: string): OrgData[] {
     // if ( value == '' ) { return this.orgsvc.OrgList }
     // console.log('filter org names filterValue =', filterValue);
     // const newlist = this.orgsvc.OrgList;
     // const olist = newlist.filter (o => {
     //   if (o.org_name.toLowerCase().includes(filterValue)) {
     //    return o;
     //   }
     // });
     // console.log('filter olist = ', olist );
     // return olist;
     // if( value && value !== null) {
     //   if (value == '')
     //     { return new Array<OrgData>(); }
        const filterValue = (''+value).toLowerCase();
        if ( this.orgsvc.OrgList ) {
           return this.orgsvc.OrgList.filter(o => o.org_name.toLowerCase().includes(filterValue));
        } else {
             return null;
          }
     //}
   }

   clearComboInput() {
      this.objNameInput.patchValue('');
   }

   setComboOption(uid: string) {
      const opts = this._auto.autocomplete.options.toArray();
      if ( opts ) {
        const item = opts.find( o => o.value.uid === uid );
        if (item) {
          this.objNameInput.setValue(item.value);
        }
      }
   }

   async refreshList() {
     this.isObjAdmin = false;   

     // console.log('group-list refreshList');

     if (this.selectedObj) {
       this.isObjAdmin = await this.getIsObjAdmin(this.selectedObj, this.userData);
     } 

     this.setMode(this.mode);

     this.refreshListEvent.emit();

     if ( this.userData && this.userData.iam && this.iam) {
         // console.log('App Mgr get all orgs...');
         if ( this.mode === this.DisplayMode.TABLE ) {
            this.refreshObjTable();
         } else {
              // console.log('org-list refresh get all orgs...');
              this.orgsvc.getAllOrganizations$();
           }
     } else {
            // console.log('Get user orgs...');
            if ( this.mode === this.DisplayMode.TABLE ) {
                this.refreshObjTable();
            } else {
                  this.orgsvc.getUserOrganizations$( this.userData.uid );
              }
        }
   }

   createForm() {
      this.orgSelectForm = this.fb.group({
               org_select_field: this.viewOnly ?
                                 [{value: '', disabled: true}] :
                                 [''] });
   }

   setForm() {
     if ( this.objSelectForm && this.selectedObj ) {
        this.objSelectForm.patchValue(
           { obj_select_field: this.selectedObj ? this.selectedObj.uid : null
           }
        );
     }
   }

   async refreshObjTable() {
      if ( this.userData && this.userData.iam && this.iam) {
          this.orgList = await this.orgsvc.getAllOrganizationsPromise();
      } else {
            this.orgList = await this.orgsvc.getUserOrganizationsPromise( this.userData.uid );
        }

      this.dataSource = new MatTableDataSource<OrgData>(this.orgsvc.OrgList);

      if ( this.paginator ) {
         this.dataSource.paginator = this.paginator;
      }
      if ( this.sort ) {
         this.dataSource.sort = this.sort;
      }
   }

   setTemplate(t) {
      this.template = t;
      // console.log('setTemplate t=', t);
   }

   setPrevMode() {
      if ( this.prevMode !== null ) {
          this.setMode( this.prevMode );
      }
   }

   toggleShowObjSelect() {
      this.showObjSelect = !this.showObjSelect;
   }

   setMode(m) {
      this.prevMode = this.mode;
      this.mode = m;
      // console.log('setMode m=', this.mode);

      /***
      if ( this.userData && this.userData.iam) {
         this.displayedColumns = ['org_client_nbr', 'org_name', 'org_type', 'org_contact_email', 'org_actions', 'org_selector'];
         // this.displayedColumns = ['org_client_nbr', 'org_name', 'org_type', 'org_contact_email', 'org_status'];
      } else {
         this.displayedColumns = ['group_name', 'org_name'];
        }
      ***/

      if ( this.userData.iam ) {
         this.displayedColumns = ['org_client_nbr', 'org_name', 'org_type', 'org_status', 'org_owner_email', 'obj_actions', 'obj_selector'];
      } else {
          this.displayedColumns = ['org_client_nbr', 'org_name', 'org_type', 'org_owner_email', 'obj_actions', 'obj_selector'];
        }

      this.modeEvent.emit(this.mode);
      
      switch (this.mode) {
         case this.DisplayMode.SELECT: {
            this.template = this.DisplayTemplate.SELECT;
            this.objMode = this.ObjDisplayMode.SELECT;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            //this.createForm();
            //this.setForm();
            break;
         }
         case this.DisplayMode.COMBO: {
            // this.selectedObj = null;
            this.template = this.DisplayTemplate.COMBO;
            this.objMode = this.ObjDisplayMode.COMBO;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            //this.createForm();
            //this.setForm();
            break;
         }
         case this.DisplayMode.EXPAND_LIST: {
            this.template = this.DisplayTemplate.EXPAND_LIST;
            this.objMode = this.ObjDisplayMode.EXPAND_LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         case this.DisplayMode.SELECT_LIST: {
            this.template = this.DisplayTemplate.SELECT_LIST;
            this.objMode = this.ObjDisplayMode.SELECT_LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.LIST: {
            this.template = this.DisplayTemplate.LIST;
            this.objMode = this.ObjDisplayMode.LIST;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = true;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.VIEW: {
            this.showObjSelect = false;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.VIEW;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.EDIT: {
            this.showObjSelect = false;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.EDIT;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.ADD: {
            this.showObjSelect = false;
            this.selectedObj = null;
            this.template = this.DisplayTemplate.FORM;
            this.objMode = this.ObjDisplayMode.ADD;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            //console.log('GRPLIST ADD mode=', this.mode);
            //console.log('GRPLIST ADD template=', this.template);
            //console.log('GRPLIST ADD orgMode=', this.objMode);
            this.createForm();
            this.setForm();
            break;
         }
         case this.DisplayMode.TABLE || 'T': {
            this.template = this.DisplayTemplate.TABLE;
            this.objMode = this.ObjDisplayMode.TABLE;
            this.titleFilter = '';
            this.showTitleFilter = true;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         case this.DisplayMode.DELETE || 'DEL': {
            this.template = this.DisplayTemplate.DELETE;
            this.objMode = this.ObjDisplayMode.DELETE;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = false;
            this.showSelectButton = false;
            break;
         }
         default: {
            console.error('Error org-list default mode=', this.mode);
            this.template = this.DisplayTemplate.SELECT;
            this.objMode = this.ObjDisplayMode.SELECT;
            this.titleFilter = '';
            this.showTitleFilter = false;
            this.showSearchButton = true;
            this.showMenuButton = true;
            this.showSelectButton = false;
            break;
         }
      }
      // console.log('this.mode=', this.mode);
      // console.log('this.template=', this.template);
      // console.log('this.objMode=', this.objMode);
  }

  /****
  onOrgNameChange(evt) {
      // evt.target.value
      let org = null;
      org = this.orgsvc.OrgList.find(o => {
          let obj = null;
          if (o.org_name.toLowerCase() === evt.target.value.toLowerCase()) {
              obj = o;
          }
          return obj;
      });
      // console.log('orgNameChange orgSelectionChange evt=', evt.target.value);
      // console.log('orgNameChange orgSelectionChange org=', org);
      if (org) {
          // this.objNameInput.patchValue(org);
      } else {
          this.setSelectedObj(null);
      }
  }
  ****/

  toggleChecked(o) {
     // console.log('org-list toggle checked e=', o);
     if ( this.selectedObj && this.selectedObj.uid === o.uid ) {
         this.setSelectedObj(null);
     } else {
          this.setSelectedObj(o);
       }
  }

  onSelectionChange(evt) {
      // console.log('group-list grpSelectionChange evt=', evt);
      this.setSelectedObj(evt.value);
  }

  setSelectedObjEvent(event) {
      // console.log('setSelectedObjEvent called event=', event);
      this.setSelectedObj(event.option.value);
      this.selectObjEvent.emit(event.option.value);
  }

  async setSelectedObj(e) {
      // console.log('group-list setSelectedObj e=', e);

      if ( e === "NONE" ) {
         this.selectedObj = null;
      } else {
           this.selectedObj = e;
           this.setForm();
        }
      this.selectObjEvent.emit(e);

      if ( this.objNameInput && this.selectedObj ) {
        this.objNameInput.patchValue(this.selectedObj.org_name);
        // this.comboForm.patchValue({objNameInput: this.selectedObj.org_name});
        // console.log('patched value for objNameInput=', this.selectedObj.org_name);
      } else {
           this.objNameInput.patchValue('');
           // this.comboForm.patchValue({objNameInput: ''});
           // console.log('patched EMPTY value for objNameInput');
        }
	
      if ( this.mode == this.DisplayMode.SELECT ) {
         this.template = this.DisplayTemplate.SELECT;
      }
      if ( this.objFormItem ) {
         this.objFormItem.setObjData(this.selectedObj);
      }
      // this.isObjAdmin = await this.getIsObjAdmin(this.selectedObj, this.userData);
  }

  nextObj() {
    let idx = 0;

    // console.log('next: value = ', this.orgsvc.getOrgListData());

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid == this.selectedObj.uid) {
                                   return true;
                                }
       })
       // console.log('nextObj current idx=', idx);
       // console.log('nextObj selected=', this.selectedObj);
    }
    if ( idx >= 0 && (idx+1) < this.groupsvc.getUserGroupList().length ) {
       this.setSelectedObj(this.orgsvc.OrgList[idx+1]);
       // console.log('new selectedObj =', this.selectedObj);
    }
  }

  prevObj() {
    let idx = 0;

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid === this.selectedObj.uid) {
                                   return true;
                                }
       })
       // console.log('prevOrg current idx=', idx);
    }
    if ( idx >= 0 ) {
       if ( idx === 0 ) {
           this.selectedObj = null;
       } else {
           this.setSelectedObj(this.orgsvc.OrgList[idx-1]);
         }
    }
  }

  viewObj() {
     // console.log('view obj.');
     this.viewObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.VIEW);
  }

  createObj() {
     // console.log('create obj.');
     this.createObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.ADD);
  }

  editObj() {
     // console.log('edit obj.');
     this.editObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.EDIT);
  }

  deleteObj() {
     // console.log('delete obj.');
     this.deleteObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.DELETE);
  }

  onSubmit(obj: any) {
    // console.log('group-list onSubmit obj=', obj);
    this.showObjSelect = true;
    this.selectedObj = obj;
    this.setPrevMode();
    this.refreshList();
    this.refreshListEvent.emit();         
    if ( this.objNameInput && obj && obj.org_name ) {
      this.objNameInput.patchValue( obj.org_name );
    }
    this.doneEvent.emit(this.selectedObj);
    this.selectObjEvent.emit(this.selectedObj);
    // console.log('group-list onSubmit selectedObj=', this.selectedObj);
  }

  onCancel() {
     // console.log('group-list onCancel event.');
     this.showObjSelect = true;
     this.setPrevMode();
  }

  onDone() {
     // console.log('group-list onDone event.');
     this.doneEvent.emit(this.selectedObj);
     this.showObjSelect = true;
     this.setPrevMode();
  }

  getOptionName(o) {
     // console.log('org-list getOptionName o=', o);
     const name = o ? o.org_name : undefined;
     /****
     let owns = '';
     const star = '&#9733';
     if (this.userData && this.userData.uid && o && o.owner &&
         this.userData.uid===o.owner) {
         owns = star;
     }
     ****/
     // console.log('org-list getOptionName name=', name);
     return name;
  }

  /***
  displayGroupName(grp){
     if(grp) {
        return grp.name;
     }
  }
  ***/

  /***
  updateLocalItem(newItem){
    //let idx = this.objList.findIndex(obj => obj.uid === newItem.uid);
    //this.objList[idx] = newItem;
    //this.objList = Object.assign([], this.objList);
  }
  ***/

  updateItem(newItem) {
     this.groupsvc.updateItem(newItem);
     this.setSelectedObj(newItem);
  }

  compareObjs(o1: any, o2: any): boolean {
     if ( o1 == null || o2 == null )
        { return false; }
     const result = o1.uid === o2.uid;
     // console.log('compare objs result=', result);
     return result;
  }

  async getIsObjAdmin(obj: any, uData: UserData) {
     let obj_admin = false;
     if (obj && 'uid' in obj && uData && 'uid' in uData) {
        const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(obj.uid, uData.uid);
        if ( orgusr && ('org_admin' in orgusr) && orgusr.org_admin ) {
            obj_admin = true;
        } else {
            obj_admin = false;
          }
     }
     if ( uData.iam && this.iam ) {
        return true;
     }
     // console.log('org-list isObjAdmin=', obj_admin);
     return obj_admin;
  }


  // Table Pagination Change
  onTableChangePage(pe:PageEvent) {
    console.log(pe.pageIndex);
    console.log(pe.pageSize);
  }

  // Table manipulation functions
  //
  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.filterValue = filterValue;
    this.dataSource.filter = filterValue;
    // console.log('ObjListComponent filterValue=', filterValue);
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.  */
  masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

   // Scroll functions
   // member list scroll
   scroll_down(): void {
      // console.log('OLC orglist = ', this.orglist);
      const md = this.orglist.nativeElement;
      const su = this.scrollUp.nativeElement;
      const sd = this.scrollDown.nativeElement;
      const scrollPosition = md.scrollTop;
      const scrollHeight = md.scrollHeight;
      // console.log('md = ', md);
      // console.log('su = ', su);
      // console.log('sd = ', sd);

      if ( scrollPosition === (scrollHeight - 135)){
          sd.style.opacity = '.4';
      } else {
          sd.style.opacity = '1';
      }
      su.style.opacity = '1';
      md.scrollTop += 20;
    }

    scroll_up(): void {
       const md = this.orglist.nativeElement;
       const su = this.scrollUp.nativeElement;
       const sd = this.scrollDown.nativeElement;
       const scrollPositionTop = md.scrollTop;
       // const scrollHeight = md.scrollHeight;
       // console.log('md = ', md);
       // console.log('su = ', su);
       // console.log('sd = ', sd);

       if (scrollPositionTop === 0 ) {
            su.style.opacity = '.4';
        } else {
            su.style.opacity = '1';
        }
       sd.style.opacity = '1';
       md.scrollTop -= 20;
    }

} // org-list

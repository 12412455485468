import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToasterService } from '../service/toaster.service';
import { ToastrService } from 'ngx-toastr';

import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { catchError, shareReplay, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { GroupService } from '../service/group.service';
import { GroupData } from '../group/model/groupdata.model';
import { GroupMember } from '../group/model/groupmember.model';
import { CountData } from '../model/countdata.model';
// import { SumData } from '../model/sumdata.model';

@Injectable({
  providedIn: 'root'
})
export class GroupMemberService {
  private groupMemberUpdates: Subject<string> = new Subject<string>();

  GroupMemberCnt: CountData = null;

  public groupMemberCountSubj$: BehaviorSubject<CountData> = new BehaviorSubject<CountData>(this.GroupMemberCnt);
  groupMemberCount$: Observable<CountData> = this.groupMemberCountSubj$.asObservable();

  newMemberCnt: number;
  newMemberErr: number;

  constructor(
     private http: HttpClient,
     private toast: ToasterService,
     private toastr: ToastrService,
     private groupsvc: GroupService
     ) { }

  // CJ - Add and Delete operations will sendGroupMemberUpdate(gid) to
  // send the group id for the updated member list as an observable.
  // Components using the service can subscribe using the
  // getGroupMemberUpdates() method and check the group id and
  // if it matches update the components group member list.

  sendGroupMemberUpdate(gid: string) {
     this.groupMemberUpdates.next(gid);
  }
  clearGroupMemberUpdates() {
    this.groupMemberUpdates.next(null);
  }
  getGroupMemberUpdates(): Observable<any> {
    return this.groupMemberUpdates.asObservable();
  }

  //
  // getGroupMembers$(guid): Observable<GroupData[]> {
  //   const url = environment.apiUrl + '/usergroup/' + guid;
  //   const x = this.http.get(url);
  //   return x as GroupData[];
  // }

  // Get Group Members as Observable<GroupMember[]>
  //
  getGroupMembersObservable(guid: string): Observable<GroupMember[]> {
    const url = environment.apiUrl + '/usergroup/' + guid;
    return this.http.get(url)
           .pipe( map( x => {
              return x as GroupMember[];
              }
           ));
  }

   // Use toPromise so we can use async and await
   // try{
   //   grplist = await this.groupsvc.getGroupsPromise(uuid);
   // } catch (e) { }
   // -----
   // Note in rxjs 8 will need to convert to using plain getGroups Observable
   // and use the following on the component:
   // async loadGroups() {
   //   const groups$ = this.groupsvc.getGroups(uuid);
   //   this.groups = await lastValueFrom(groups$);
   // }
   getGroupMembersPromise(guid: string): Promise<any> {
      return this.http.get<GroupMember[]>
             (environment.apiUrl + '/usergroup/' + guid ).toPromise()
             .then(
                (x) => {
                   return x as GroupMember[];
                },
                (e) => {
                   console.log('groupmembersvc failed to get group members:', e);
                   throw e;
                }
            );
   }

   async getGroupMembers(guid: string): Promise<GroupMember[]> {
      let mlist: GroupMember[] = [];
      try{
         mlist = await this.getGroupMembersPromise(guid);
      } catch (e) {
         console.log('groupmembersvc: getGroupMembers error=', e);
      }
      return mlist;
   }

   getNewMemberCnt(): number {
      return this.newMemberCnt;
   }
   getNewMemberErr(): number {
      return this.newMemberErr;
   }

   clearNewMemberCnt(): void {
      this.newMemberCnt = 0;
   }
   clearNewMemberErr(): void {
      this.newMemberCnt = 0;
   }

   async addMemberPromise(uuid, user, grp): Promise<any> {
      console.log('groupmembersvc: addMember user= ' + user );
      console.log('groupmembersvc: addMember uuid= ' + uuid );
      console.log('groupmembersvc: addMember grp.uid= ' + grp.uid );
      console.log('groupmembersvc: addMember grp.name= ' + grp.name );

      let groupUID = null;
      let validGroup = null;

      // Validate group: Use groupsvc to make sure the group exists
      const grpList = await this.groupsvc.getUserGroupsPromise(uuid);

      if (grpList && grpList !== null) {
         validGroup = await grpList.find(g => g.uid === grp.uid);
         if (validGroup !== null) {
            groupUID = validGroup.uid;
         } else {
             console.log('groupmembersvc: cant find group with uid ', grp.uid);
             this.toast.pop('error', 'Add User to Group',
             'Failed to find group with uid = ' + grp.uid);
             this.newMemberErr++;
           }
      }
      const groupData = JSON.stringify({
         email: user,
         groups_uid: groupUID
      });
      return this.http.post(environment.apiUrl + '/usergroup', groupData)
        .toPromise()
        .then(
         (r) => {
            console.log('add member post r=', r);
            this.newMemberCnt++;
            this.sendGroupMemberUpdate(groupUID);
            return r;
         },
         (e) => {
            console.log('add member post e=', e);
            this.newMemberErr++;
            throw e;
         }
        );
   }

   addGroupMemberPromise$(user, guid) {
       const groupData = JSON.stringify({
           email: user,
           groups_uid: guid
       });
       return this.http.post(environment.apiUrl + '/usergroup', groupData)
            .toPromise()
            .then (
                       (x) => {
                           this.sendGroupMemberUpdate(guid);
                           return x as GroupMember;
                       },
                       (e) => {
                           console.log(e);
                           return null;
                           }
             );

   } // addGroupMember


    removeGroupMember(guid, memberUID): any {
     // remove member from selected group
     // console.log('groupmbrsvc removeGroupMember guid=', guid);
     // console.log('groupmbrsvc removeGroupMember memberUid=', memberUID);
     return this.http.delete(environment.apiUrl + '/usergroup/member/' + guid + '/' + memberUID)
          .toPromise()
          .then(
             (x) => {
                this.sendGroupMemberUpdate(guid);
                return x;
             },
             (e) => {
                console.log(e);
                throw e;
             }
        );
    }

    getGroupMemberCount$(gid: string){
       this.http.get<any>(environment.apiUrl + '/usergroup/count/group/' + gid)
       .subscribe(cnt => {
                       // console.log('group member cnt =', cnt);
                       this.groupMemberCountSubj$.next(cnt);
                       return cnt as CountData;
       })
    }

} // GroupMemberService

// (c) 2023-2024 Markadoc Corp.

export enum Model {
   BILLING_PLAN = 'BP',
   BILLING_PLAN_LIST = 'BPL',   
   DOCUMENT = 'DOC',
   DOCUMENT_LIST = 'DOCL',   
   GROUP = 'GRP',
   GROUP_LIST = 'GL',
   GROUP_MEMBER = 'GM',
   GROUP_FILE = 'GF',
   GROUP_FILE_LIST = 'GFL',
   GROUP_TAB = 'GRTB',
   ORG_TAB = 'OT',
   ORG = 'ORG',
   ORG_USR = 'OU',
   ORG_USR_LIST = 'OUL',   
   ORG_USR_CONTENT_XFER = 'OUCX',
   ORG_USR_XFER_LIST = 'OUXL',   
   ORG_USR_DELETE = 'OUD',   
   ORG_CONTACT = 'OC',
   ORG_CONTACT_LIST = 'OCL',
   PRODUCT = 'PDCT',
   PRODUCT_LIST = 'PDCTL',   
   SUBSCRIPTION = 'SUBS',
   SUBSCRIPTION_LIST = 'SUBSL',
   USER_ACCT_LIST = 'UAL',   
   USER_ACCT = 'UA',
   USER = 'U',
   USER_PWD = 'UP',
   USER_GUEST_ACCT = 'UGA',   
}

export enum Mode {
   SELECT = 'S',
   COMBO = 'C',
   LIST = 'L',
   XFER_LIST = 'XL',
   SELECT_LIST = 'SL',
   DATA_PANELS = 'DP',
   EXPAND_LIST = 'EL',
   TABLE = 'T',
   TABS = 'TABS',   
   FORM = 'F',
   QUOTA = 'QTA',   
   VIEW = 'V',
   ADD = 'A',
   EDIT = 'E',
   DELETE = 'DEL',
   DISPLAY_NAME = 'DN',
   FIELD_NAME = 'FN',
   SIDENAV_NAME = 'SN',
}

export enum Template {
   SELECT = 1,
   COMBO,
   LIST,
   SELECT_LIST,
   DATA_PANELS,   
   EXPAND_LIST,
   DETAIL,
   TABS,   
   TABLE,
   FORM,
   QUOTA,
   DELETE,
   DISPLAY_NAME,   
   FIELD_NAME,
   SIDENAV_NAME,
   XFER_LIST,
}

<ng-template #userSubCheckBox>
     <div *ngIf="isOrgGroup===false"
          style="display: flex; flex-flow: row; margin-top: 2rem; margin-left: 1rem; font-size: 0.8rem">
          <mat-checkbox #guestAccounts class="addGuestAccounts" check
                        style="margin-left: 1rem"
                        labelPosition="before"
		        [disabled]="userSubLimit<=0"
                        [(ngModel)]="addGuestAccounts"
                        (ngModelChange)="toggleAddGuestAccounts()">
               Click to also add the user as a guest sub-account</mat-checkbox>
          <span style="margin-left: 1rem; margin-top: 0.2rem;" >
                ({{userSubAvail}} of {{userSubLimit}} available)</span>	       
     </div>
</ng-template>

<ng-template #orgGuestCheckBox>
     <div *ngIf="isOrgGroup===true"
          style="display: flex; flex-flow: row; margin-top: 2rem; margin-left: 1rem; font-size: 0.8rem">
          <mat-checkbox #guestAccounts class="addGuestAccounts" check
                        style="margin-left: 1rem"
                        labelPosition="after"
		        [disabled]="!isOrgGuestAdmin"
                        [(ngModel)]="addOrgGuestAccounts"
                        (ngModelChange)="toggleAddOrgGuestAccounts()">
               Also add the user as a Company/Org. guest account</mat-checkbox>
          <span style="margin-left: 1rem; margin-top: 0.2rem;" >
                ({{orgGuestAvail}} available of {{orgGuestLimit}})</span>	       
     </div>
</ng-template>

<div #addMemberView style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; height: 100%; !important; width: 100% !important; height:100% !important;">

  <div *ngIf="template===this.ADD_MEMBER" id="AddMember" style="width: 100%; height: 100%;">
    <div *ngIf="!this.group">
      <span style="color: red; margin-left: 1rem;">
        You Must Select A Group Before Adding Members</span>
      <br>
    </div>
    <div *ngIf="this.group && this.group.owner!==this.userInfo.uid">
      <span style="color: red; margin-left: 1rem; margin-top: 1rem;">
        You Must be the Group Owner/Administrator to add members</span>
      <br>
    </div>
    <div *ngIf="this.group && this.group.owner===this.userInfo.uid"
	 style="display: flex; flex-flow: column;">
      <span style="margin-left: 1rem">Add Member to Group</span>
      <div style="margin-left: 1rem">{{this.group.name}}</div>
      <mat-form-field
	     style="width:80% !important; margin-bottom: 2px; display: block; margin-left: 1rem"
	     appearance=standard  class="mat-form-field" id="enter_email_addr">
                <mat-label style="display:block">Email Address</mat-label>
                 <input matInput #emailAddr [(ngModel)]="emailAddress" [ngModelOptions]="{standalone: true}" ngDefaultControl id="email_string_input" style="display: block; font-size: 1rem" (keyup.enter)="handleAddMember()" autocomplete="off"/>
		 <mat-hint>Enter the User Email Address and Press Enter or Click Add User to add them to the Group.<br>If necessary click the checkbox to add a Guest or Free Account first.</mat-hint>
      </mat-form-field>
      <div style="display: flex; flex-flow: row;  margin-top: 0.5rem; margin-left: 1rem; font-size: 0.8rem">
           <ng-content *ngTemplateOutlet="userSubCheckBox">
           </ng-content>
           <ng-content *ngTemplateOutlet="orgGuestCheckBox">
           </ng-content>
      </div>
      <span style="display:block; margin-top: 1rem; font-size: 0.7rem; margin-left: 0.8rem">{{stepMessage}}</span>
         <div style="display: flex; flex-flow: row; margin-top: 1rem; margin-left: 1rem;">	     
         <button mat-raised-button
              [disabled]="!this.isOwnerAdmin()"
              class="action-button"
              style="align-self: flex-end; margin-bottom: 0.5rem"
             (click)="handleAddMember()">Add Member</button>
         <button mat-raised-button class="bottom-buttons"
              style="align-self: flex-end; margin-bottom: 0.5rem; margin-right: 0.5rem"
              (click)="clearEmailAddress()">Clear</button>
         </div>
      </div>    
 </div>

 <div *ngIf="template===this.ADD_MULTIPLE" #AddMultiple id="AddMultiple" >
    <div *ngIf="!this.group">
      <span style="color: red; margin-left: 1rem;">
        You Must Select A Group Before Adding Members</span>
      <br>
    </div>
    <div *ngIf="this.group && this.group.owner!==this.userInfo.uid">
      <span style="color: red; margin-left: 1rem;">
        You Must be the Group Owner/Administrator to add members</span>
      <br>
    </div>
    <span style="margin-left: 1rem">Add Member(s) to Group</span>
    <div *ngIf="this.group" style="margin-left: 1rem">{{this.group.name}}</div>
      <div style="display: flex; flex-flow: column; margin-right: 1em">
        <mat-label style="margin-top: 0.3rem; font-size: 0.8rem; margin-left: 1rem">Email Address List</mat-label>
            <textarea #emailInput id="email-extractor"
              [(ngModel)]="emailText"
	      style="margin-left: 1rem"
	      rows="8" cols="32"
	      class="email-extractor-textarea"
	      placeholer="Enter or paste a list of comma or semi-colon separated email addresses here and then click add users"
	      ></textarea>
            <mat-label style="display:block; margin-top: 0.3rem; font-size: 0.7rem; margin-left: 0.8rem">Enter or paste a list of email addresses separated by , or ; then tap/click Add Members.</mat-label>	    
	    <div style="display: flex; flex-flow: row;  margin-top: 0.5rem; margin-left: 1rem; font-size: 0.8rem">
             <ng-content *ngTemplateOutlet="userSubCheckBox">
             </ng-content>
             <ng-content *ngTemplateOutlet="orgGuestCheckBox">
             </ng-content>
	    </div>
            <span style="display:block; margin-top: 0.3rem; font-size: 0.7rem; margin-left: 0.8rem">{{statusMessage}}</span>	    
	      <div style="display: flex; flex-flow: row; margin-left: 1rem;">
                <button mat-raised-button 
                   style="align-self: flex-tart; margin-left: 1rem; margin-right: 0.5rem; margin-top: 0.2rem; background: white; color: #2274A5;"
                   (click)="clearEmailText()">Clear</button>
                <button mat-raised-button 
                   [disabled]="!this.isOwnerAdmin() || emailText===''"
                   style="align-self: flex-start; background: #2274A5; color: white;"
			(click)="handleAddMemberList()">Add Members</button>
	      </div>
      </div>
   </div>

</div> <!-- group-member component -->

<div mat-dialog-title class="dialog-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle style="width: 100%;">
  <div class="dialog-header-left">
    <mat-icon style="padding-top: 0.2rem;">drag_indicator</mat-icon>        
    <span *ngIf="groupTabIndex===0" class="title" style="width: 40%">Manage Groups</span>
    <span *ngIf="groupTabIndex!==0" class="title" style="margin-right: 0.25em">Manage Group</span>
  </div>

  <mat-select *ngIf="groupTabIndex!==0"
       [(ngModel)]="selectedGroup"
       [ngModelOptions]="{standalone: true}"
       ngDefaultControl selected="selectedGroup"
       style="color: blue; font-size: 0.75rem; width: 30%; border-bottom: 2px solid blue;"
       (selectionChange)="groupChange(selectedGroup)">
       <mat-label>Select or Create a Group</mat-label>
       <mat-option (click)="createGroupDialog()" id="new_group" style="color: blue; font-size: 0.8rem"> + new group...</mat-option>			
       <mat-option *ngFor="let g of this.groupList" [value]="g.uid" style="color: blue: font-size: 0.8rem">{{g.name}}<span *ngIf="userInfo.uid===g.owner" style="font-size: 0.8rem"> &#9733;</span></mat-option>
  </mat-select>
  <div class="dialog-header-center">
    <span class="showall"
	 *ngIf="groupTabIndex===0"
         style="font-size: 0.8rem; align-self: flex-start; margin-right: 0.5rem"
         (click)="showAllGroups = !showAllGroups;handleShowGroups()">Show All
  <mat-checkbox class="showall"
       *ngIf="groupTabIndex===0"
       [(ngModel)]="showAllGroups"
       (ngModelChange)="showAllGroups = !showAllGroups;handleShowGroups()">
  </mat-checkbox>
  </span>
  </div>
  <div class="dialog-header-right">
    <mat-icon [matMenuTriggerFor]="grpMgrMenu"
	      style="align: right; margin-right: 1rem; align: right">more_vert</mat-icon>    
    <mat-icon (click)="close()" style="align: right">close</mat-icon>
  </div>
    <mat-menu #grpMgrMenu="matMenu" xPosition="after">
      <button mat-menu-item (click)="createGroupDialog()"
         style="font-size: 0.75em; margin-left: 0.2rem">
         <mat-icon class="material-icons-outlined">group_add_outline</mat-icon>Create New Group
      </button>
      <span mat-menu-item *ngIf="groupTabIndex===0" style="font-size: 0.8rem; align-self: flex-start; margin-right: 0.5rem">
        <mat-icon>groups</mat-icon>
	Show All Groups
        <mat-checkbox *ngIf="groupTabIndex===0" [(ngModel)]="showAllGroups"
		  (change)="showAllGroups = !showAllGroups;handleShowGroups();"
		  >
        </mat-checkbox>
      </span>
      <button mat-menu-item (click)="helpDialog()"
          style="font-size: 0.75em; margin-left: 0.2rem">	      
          <mat-icon>help_outline</mat-icon>Help
      </button>
      <button mat-menu-item (click)="this.getUserGroups();"
         style="font-size: 0.75em; margin-left: 0.2rem">
         <mat-icon>refresh</mat-icon>Refresh Data
      </button>
      <button mat-menu-item id="close-x" (click)="close()"
          style="font-size: 0.75em; margin-left: 0.2rem;">      
          <mat-icon>close</mat-icon>Close Group Manager
      </button>
    </mat-menu>

  
  <div class="title" style="margin-left: 0rem; border-bottom: 1px solid #1A567A;"></div>    
</div>


<mat-dialog-content #content class="panel-class" style="margin: 0; padding: 0; border-top: 1px solid #1A567A;">
	<app-group-manager #groupMgr
	   [userInfo]=userInfo
	   [selectedGroup]=selectedGroup
           [selectedOrganization]=selectedOrganization
	   [selectedOrgUnit]=selectedOrgUnit
	   [groupTabIndex]=groupTabIndex
	   [groupMembers]=groupMembers
	   (helpEvent)=helpDialog()
	   (groupTabEvent)=handleGroupTabEvent($event)
	   (getGroupsEvent)=sendUpdateDocList($event)
	   (getMembersEvent)=getMembers($event)			   
           (displayUserInfoEvent)=displayUserInfo($event)
           (createGroupEvent)=createGroupDialog()
	   (renameGroupEvent)=renameGroupDialog($event)
           (deleteGroupEvent)=deleteGroup($event)
	   (removeMemberEvent)=removeMember($event)>
	</app-group-manager>	   
</mat-dialog-content>

<mat-dialog-actions style="bottom: 0; border-top: 1px solid #1A567A; margin-top: 0.2rem; align-self: flex-end">
 <div id="actions" style="display:flex; flex-flow: column;">
  <div id="step-message" style="display: block; font-size=0.7rem: margin-bottom: 0.2rem;">{{stepMessage}}</div>
  <div id="action-btns" style="display:flex; flex-flow: row; justify-content: flex-start; align-items: center">

  <button mat-raised-button class="action-button"
	  style="align-self: flex-end; margin-left: 0em;"
          (click)="close()">
    <span style="padding-left: 3%; font-size: 0.8rem;">CLOSE</span>    
  </button>

  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Top"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollTop()">
    <mat-icon>vertical_align_top</mat-icon>    
  </button>
  <button mat-raised-button class="bottom-buttons"
	  matToolTip="Scroll to Bottom"
	  style="align-self: flex-end; margin-left: 1em;"
          (click)="scrollBottom()">
    <mat-icon>vertical_align_bottom</mat-icon>    
  </button>

  
 </div>
 </div>
</mat-dialog-actions>

// selectors reference data typescript data structures
//

// export class SelectLists {

export const countryMap = new Map([
    ["Afghanistan", "AF"],
    ["Åland Islands", "AX"],
    ["Albania", "AL"],
    ["Algeria", "DZ"],
    ["American Samoa", "AS"],
    ["AndorrA", "AD"],
    ["Angola", "AO"],
    ["Anguilla", "AI"],
    ["Antarctica", "AQ"],
    ["Antigua and Barbuda", "AG"],
    ["Argentina", "AR"],
    ["Armenia", "AM"],
    ["Aruba", "AW"],
    ["Australia", "AU"],
    ["Austria", "AT"],
    ["Azerbaijan", "AZ"],
    ["Bahamas", "BS"],
    ["Bahrain", "BH"],
    ["Bangladesh", "BD"],
    ["Barbados", "BB"],
    ["Belarus", "BY"],
    ["Belgium", "BE"],
    ["Belize", "BZ"],
    ["Benin", "BJ"],
    ["Bermuda", "BM"],
    ["Bhutan", "BT"],
    ["Bolivia", "BO"],
    ["Bosnia and Herzegovina", "BA"],
    ["Botswana", "BW"],
    ["Bouvet Island", "BV"],
    ["Brazil", "BR"],
    ["British Indian Ocean Territory", "IO"],
    ["Brunei Darussalam", "BN"],
    ["Bulgaria", "BG"],
    ["Burkina Faso", "BF"],
    ["Burundi", "BI"],
    ["Cambodia", "KH"],
    ["Cameroon", "CM"],
    ["Canada", "CA"],
    ["Cape Verde", "CV"],
    ["Cayman Islands", "KY"],
    ["Central African Republic", "CF"],
    ["Chad", "TD"],
    ["Chile", "CL"],
    ["China", "CN"],
    ["Christmas Island", "CX"],
    ["Cocos (Keeling) Islands", "CC"],
    ["Colombia", "CO"],
    ["Comoros", "KM"],
    ["Congo", "CG"],
    ["Congo, The Democratic Republic of the", "CD"],
    ["Cook Islands", "CK"],
    ["Costa Rica", "CR"],
    ["Cote D\"Ivoire", "CI"],
    ["Croatia", "HR"],
    ["Cuba", "CU"],
    ["Cyprus", "CY"],
    ["Czech Republic", "CZ"],
    ["Denmark", "DK"],
    ["Djibouti", "DJ"],
    ["Dominica", "DM"],
    ["Dominican Republic", "DO"],
    ["Ecuador", "EC"],
    ["Egypt", "EG"],
    ["El Salvador", "SV"],
    ["Equatorial Guinea", "GQ"],
    ["Eritrea", "ER"],
    ["Estonia", "EE"],
    ["Ethiopia", "ET"],
    ["Falkland Islands (Malvinas)", "FK"],
    ["Faroe Islands", "FO"],
    ["Fiji", "FJ"],
    ["Finland", "FI"],
    ["France", "FR"],
    ["French Guiana", "GF"],
    ["French Polynesia", "PF"],
    ["French Southern Territories", "TF"],
    ["Gabon", "GA"],
    ["Gambia", "GM"],
    ["Georgia", "GE"],
    ["Germany", "DE"],
    ["Ghana", "GH"],
    ["Gibraltar", "GI"],
    ["Greece", "GR"],
    ["Greenland", "GL"],
    ["Grenada", "GD"],
    ["Guadeloupe", "GP"],
    ["Guam", "GU"],
    ["Guatemala", "GT"],
    ["Guernsey", "GG"],
    ["Guinea", "GN"],
    ["Guinea-Bissau", "GW"],
    ["Guyana", "GY"],
    ["Haiti", "HT"],
    ["Heard Island and Mcdonald Islands", "HM"],
    ["Holy See (Vatican City State)", "VA"],
    ["Honduras", "HN"],
    ["Hong Kong", "HK"],
    ["Hungary", "HU"],
    ["Iceland", "IS"],
    ["India", "IN"],
    ["Indonesia", "ID"],
    ["Iran, Islamic Republic Of", "IR"],
    ["Iraq", "IQ"],
    ["Ireland", "IE"],
    ["Isle of Man", "IM"],
    ["Israel", "IL"],
    ["Italy", "IT"],
    ["Jamaica", "JM"],
    ["Japan", "JP"],
    ["Jersey", "JE"],
    ["Jordan", "JO"],
    ["Kazakhstan", "KZ"],
    ["Kenya", "KE"],
    ["Kiribati", "KI"],
    ["Korea, Democratic People\"S Republic of", "KP"],
    ["Korea, Republic of", "KR"],
    ["Kuwait", "KW"],
    ["Kyrgyzstan", "KG"],
    ["Lao People\"S Democratic Republic", "LA"],
    ["Latvia", "LV"],
    ["Lebanon", "LB"],
    ["Lesotho", "LS"],
    ["Liberia", "LR"],
    ["Libyan Arab Jamahiriya", "LY"],
    ["Liechtenstein", "LI"],
    ["Lithuania", "LT"],
    ["Luxembourg", "LU"],
    ["Macao", "MO"],
    ["Macedonia, The Former Yugoslav Republic of", "MK"],
    ["Madagascar", "MG"],
    ["Malawi", "MW"],
    ["Malaysia", "MY"],
    ["Maldives", "MV"],
    ["Mali", "ML"],
    ["Malta", "MT"],
    ["Marshall Islands", "MH"],
    ["Martinique", "MQ"],
    ["Mauritania", "MR"],
    ["Mauritius", "MU"],
    ["Mayotte", "YT"],
    ["Mexico", "MX"],
    ["Micronesia, Federated States of", "FM"],
    ["Moldova, Republic of", "MD"],
    ["Monaco", "MC"],
    ["Mongolia", "MN"],
    ["Montserrat", "MS"],
    ["Morocco", "MA"],
    ["Mozambique", "MZ"],
    ["Myanmar", "MM"],
    ["Namibia", "NA"],
    ["Nauru", "NR"],
    ["Nepal", "NP"],
    ["Netherlands", "NL"],
    ["Netherlands Antilles", "AN"],
    ["New Caledonia", "NC"],
    ["New Zealand", "NZ"],
    ["Nicaragua", "NI"],
    ["Niger", "NE"],
    ["Nigeria", "NG"],
    ["Niue", "NU"],
    ["Norfolk Island", "NF"],
    ["Northern Mariana Islands", "MP"],
    ["Norway", "NO"],
    ["Oman", "OM"],
    ["Pakistan", "PK"],
    ["Palau", "PW"],
    ["Palestinian Territory, Occupied", "PS"],
    ["Panama", "PA"],
    ["Papua New Guinea", "PG"],
    ["Paraguay", "PY"],
    ["Peru", "PE"],
    ["Philippines", "PH"],
    ["Pitcairn", "PN"],
    ["Poland", "PL"],
    ["Portugal", "PT"],
    ["Puerto Rico", "PR"],
    ["Qatar", "QA"],
    ["Reunion", "RE"],
    ["Romania", "RO"],
    ["Russian Federation", "RU"],
    ["RWANDA", "RW"],
    ["Saint Helena", "SH"],
    ["Saint Kitts and Nevis", "KN"],
    ["Saint Lucia", "LC"],
    ["Saint Pierre and Miquelon", "PM"],
    ["Saint Vincent and the Grenadines", "VC"],
    ["Samoa", "WS"],
    ["San Marino", "SM"],
    ["Sao Tome and Principe", "ST"],
    ["Saudi Arabia", "SA"],
    ["Senegal", "SN"],
    ["Serbia and Montenegro", "CS"],
    ["Seychelles", "SC"],
    ["Sierra Leone", "SL"],
    ["Singapore", "SG"],
    ["Slovakia", "SK"],
    ["Slovenia", "SI"],
    ["Solomon Islands", "SB"],
    ["Somalia", "SO"],
    ["South Africa", "ZA"],
    ["South Georgia and the South Sandwich Islands", "GS"],
    ["Spain", "ES"],
    ["Sri Lanka", "LK"],
    ["Sudan", "SD"],
    ["Suriname", "SR"],
    ["Svalbard and Jan Mayen", "SJ"],
    ["Swaziland", "SZ"],
    ["Sweden", "SE"],
    ["Switzerland", "CH"],
    ["Syrian Arab Republic", "SY"],
    ["Taiwan, Province of China", "TW"],
    ["Tajikistan", "TJ"],
    ["Tanzania, United Republic of", "TZ"],
    ["Thailand", "TH"],
    ["Timor-Leste", "TL"],
    ["Togo", "TG"],
    ["Tokelau", "TK"],
    ["Tonga", "TO"],
    ["Trinidad and Tobago", "TT"],
    ["Tunisia", "TN"],
    ["Turkey", "TR"],
    ["Turkmenistan", "TM"],
    ["Turks and Caicos Islands", "TC"],
    ["Tuvalu", "TV"],
    ["Uganda", "UG"],
    ["Ukraine", "UA"],
    ["United Arab Emirates", "AE"],
    ["United Kingdom", "GB"],
    ["United States", "US"],
    ["United States Minor Outlying Islands", "UM"],
    ["Uruguay", "UY"],
    ["Uzbekistan", "UZ"],
    ["Vanuatu", "VU"],
    ["Venezuela", "VE"],
    ["Viet Nam", "VN"],
    ["Virgin Islands, British", "VG"],
    ["Virgin Islands, U.S.", "VI"],
    ["Wallis and Futuna", "WF"],
    ["Western Sahara", "EH"],
    ["Yemen", "YE"],
    ["Zambia", "ZM"],
    ["Zimbabwe", "ZW"]
]);

export const countryCodes = [
  {name: 'United States', code: 'US'},
  {name: 'Afghanistan', code: 'AF'},
  {name: 'Åland Islands', code: 'AX'},
  {name: 'Albania', code: 'AL'},
  {name: 'Algeria', code: 'DZ'},
  {name: 'American Samoa', code: 'AS'},
  {name: 'AndorrA', code: 'AD'},
  {name: 'Angola', code: 'AO'},
  {name: 'Anguilla', code: 'AI'},
  {name: 'Antarctica', code: 'AQ'},
  {name: 'Antigua and Barbuda', code: 'AG'},
  {name: 'Argentina', code: 'AR'},
  {name: 'Armenia', code: 'AM'},
  {name: 'Aruba', code: 'AW'},
  {name: 'Australia', code: 'AU'},
  {name: 'Austria', code: 'AT'},
  {name: 'Azerbaijan', code: 'AZ'},
  {name: 'Bahamas', code: 'BS'},
  {name: 'Bahrain', code: 'BH'},
  {name: 'Bangladesh', code: 'BD'},
  {name: 'Barbados', code: 'BB'},
  {name: 'Belarus', code: 'BY'},
  {name: 'Belgium', code: 'BE'},
  {name: 'Belize', code: 'BZ'},
  {name: 'Benin', code: 'BJ'},
  {name: 'Bermuda', code: 'BM'},
  {name: 'Bhutan', code: 'BT'},
  {name: 'Bolivia', code: 'BO'},
  {name: 'Bosnia and Herzegovina', code: 'BA'},
  {name: 'Botswana', code: 'BW'},
  {name: 'Bouvet Island', code: 'BV'},
  {name: 'Brazil', code: 'BR'},
  {name: 'British Indian Ocean Territory', code: 'IO'},
  {name: 'Brunei Darussalam', code: 'BN'},
  {name: 'Bulgaria', code: 'BG'},
  {name: 'Burkina Faso', code: 'BF'},
  {name: 'Burundi', code: 'BI'},
  {name: 'Cambodia', code: 'KH'},
  {name: 'Cameroon', code: 'CM'},
  {name: 'Canada', code: 'CA'},
  {name: 'Cape Verde', code: 'CV'},
  {name: 'Cayman Islands', code: 'KY'},
  {name: 'Central African Republic', code: 'CF'},
  {name: 'Chad', code: 'TD'},
  {name: 'Chile', code: 'CL'},
  {name: 'China', code: 'CN'},
  {name: 'Christmas Island', code: 'CX'},
  {name: 'Cocos (Keeling) Islands', code: 'CC'},
  {name: 'Colombia', code: 'CO'},
  {name: 'Comoros', code: 'KM'},
  {name: 'Congo', code: 'CG'},
  {name: 'Congo, The Democratic Republic of the', code: 'CD'},
  {name: 'Cook Islands', code: 'CK'},
  {name: 'Costa Rica', code: 'CR'},
  {name: 'Cote D\'Ivoire', code: 'CI'},
  {name: 'Croatia', code: 'HR'},
  {name: 'Cuba', code: 'CU'},
  {name: 'Cyprus', code: 'CY'},
  {name: 'Czech Republic', code: 'CZ'},
  {name: 'Denmark', code: 'DK'},
  {name: 'Djibouti', code: 'DJ'},
  {name: 'Dominica', code: 'DM'},
  {name: 'Dominican Republic', code: 'DO'},
  {name: 'Ecuador', code: 'EC'},
  {name: 'Egypt', code: 'EG'},
  {name: 'El Salvador', code: 'SV'},
  {name: 'Equatorial Guinea', code: 'GQ'},
  {name: 'Eritrea', code: 'ER'},
  {name: 'Estonia', code: 'EE'},
  {name: 'Ethiopia', code: 'ET'},
  {name: 'Falkland Islands (Malvinas)', code: 'FK'},
  {name: 'Faroe Islands', code: 'FO'},
  {name: 'Fiji', code: 'FJ'},
  {name: 'Finland', code: 'FI'},
  {name: 'France', code: 'FR'},
  {name: 'French Guiana', code: 'GF'},
  {name: 'French Polynesia', code: 'PF'},
  {name: 'French Southern Territories', code: 'TF'},
  {name: 'Gabon', code: 'GA'},
  {name: 'Gambia', code: 'GM'},
  {name: 'Georgia', code: 'GE'},
  {name: 'Germany', code: 'DE'},
  {name: 'Ghana', code: 'GH'},
  {name: 'Gibraltar', code: 'GI'},
  {name: 'Greece', code: 'GR'},
  {name: 'Greenland', code: 'GL'},
  {name: 'Grenada', code: 'GD'},
  {name: 'Guadeloupe', code: 'GP'},
  {name: 'Guam', code: 'GU'},
  {name: 'Guatemala', code: 'GT'},
  {name: 'Guernsey', code: 'GG'},
  {name: 'Guinea', code: 'GN'},
  {name: 'Guinea-Bissau', code: 'GW'},
  {name: 'Guyana', code: 'GY'},
  {name: 'Haiti', code: 'HT'},
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'},
  {name: 'Holy See (Vatican City State)', code: 'VA'},
  {name: 'Honduras', code: 'HN'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Hungary', code: 'HU'},
  {name: 'Iceland', code: 'IS'},
  {name: 'India', code: 'IN'},
  {name: 'Indonesia', code: 'ID'},
  {name: 'Iran, Islamic Republic Of', code: 'IR'},
  {name: 'Iraq', code: 'IQ'},
  {name: 'Ireland', code: 'IE'},
  {name: 'Isle of Man', code: 'IM'},
  {name: 'Israel', code: 'IL'},
  {name: 'Italy', code: 'IT'},
  {name: 'Jamaica', code: 'JM'},
  {name: 'Japan', code: 'JP'},
  {name: 'Jersey', code: 'JE'},
  {name: 'Jordan', code: 'JO'},
  {name: 'Kazakhstan', code: 'KZ'},
  {name: 'Kenya', code: 'KE'},
  {name: 'Kiribati', code: 'KI'},
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'},
  {name: 'Korea, Republic of', code: 'KR'},
  {name: 'Kuwait', code: 'KW'},
  {name: 'Kyrgyzstan', code: 'KG'},
  {name: 'Lao People\'S Democratic Republic', code: 'LA'},
  {name: 'Latvia', code: 'LV'},
  {name: 'Lebanon', code: 'LB'},
  {name: 'Lesotho', code: 'LS'},
  {name: 'Liberia', code: 'LR'},
  {name: 'Libyan Arab Jamahiriya', code: 'LY'},
  {name: 'Liechtenstein', code: 'LI'},
  {name: 'Lithuania', code: 'LT'},
  {name: 'Luxembourg', code: 'LU'},
  {name: 'Macao', code: 'MO'},
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'},
  {name: 'Madagascar', code: 'MG'},
  {name: 'Malawi', code: 'MW'},
  {name: 'Malaysia', code: 'MY'},
  {name: 'Maldives', code: 'MV'},
  {name: 'Mali', code: 'ML'},
  {name: 'Malta', code: 'MT'},
  {name: 'Marshall Islands', code: 'MH'},
  {name: 'Martinique', code: 'MQ'},
  {name: 'Mauritania', code: 'MR'},
  {name: 'Mauritius', code: 'MU'},
  {name: 'Mayotte', code: 'YT'},
  {name: 'Mexico', code: 'MX'},
  {name: 'Micronesia, Federated States of', code: 'FM'},
  {name: 'Moldova, Republic of', code: 'MD'},
  {name: 'Monaco', code: 'MC'},
  {name: 'Mongolia', code: 'MN'},
  {name: 'Montserrat', code: 'MS'},
  {name: 'Morocco', code: 'MA'},
  {name: 'Mozambique', code: 'MZ'},
  {name: 'Myanmar', code: 'MM'},
  {name: 'Namibia', code: 'NA'},
  {name: 'Nauru', code: 'NR'},
  {name: 'Nepal', code: 'NP'},
  {name: 'Netherlands', code: 'NL'},
  {name: 'Netherlands Antilles', code: 'AN'},
  {name: 'New Caledonia', code: 'NC'},
  {name: 'New Zealand', code: 'NZ'},
  {name: 'Nicaragua', code: 'NI'},
  {name: 'Niger', code: 'NE'},
  {name: 'Nigeria', code: 'NG'},
  {name: 'Niue', code: 'NU'},
  {name: 'Norfolk Island', code: 'NF'},
  {name: 'Northern Mariana Islands', code: 'MP'},
  {name: 'Norway', code: 'NO'},
  {name: 'Oman', code: 'OM'},
  {name: 'Pakistan', code: 'PK'},
  {name: 'Palau', code: 'PW'},
  {name: 'Palestinian Territory, Occupied', code: 'PS'},
  {name: 'Panama', code: 'PA'},
  {name: 'Papua New Guinea', code: 'PG'},
  {name: 'Paraguay', code: 'PY'},
  {name: 'Peru', code: 'PE'},
  {name: 'Philippines', code: 'PH'},
  {name: 'Pitcairn', code: 'PN'},
  {name: 'Poland', code: 'PL'},
  {name: 'Portugal', code: 'PT'},
  {name: 'Puerto Rico', code: 'PR'},
  {name: 'Qatar', code: 'QA'},
  {name: 'Reunion', code: 'RE'},
  {name: 'Romania', code: 'RO'},
  {name: 'Russian Federation', code: 'RU'},
  {name: 'RWANDA', code: 'RW'},
  {name: 'Saint Helena', code: 'SH'},
  {name: 'Saint Kitts and Nevis', code: 'KN'},
  {name: 'Saint Lucia', code: 'LC'},
  {name: 'Saint Pierre and Miquelon', code: 'PM'},
  {name: 'Saint Vincent and the Grenadines', code: 'VC'},
  {name: 'Samoa', code: 'WS'},
  {name: 'San Marino', code: 'SM'},
  {name: 'Sao Tome and Principe', code: 'ST'},
  {name: 'Saudi Arabia', code: 'SA'},
  {name: 'Senegal', code: 'SN'},
  {name: 'Serbia and Montenegro', code: 'CS'},
  {name: 'Seychelles', code: 'SC'},
  {name: 'Sierra Leone', code: 'SL'},
  {name: 'Singapore', code: 'SG'},
  {name: 'Slovakia', code: 'SK'},
  {name: 'Slovenia', code: 'SI'},
  {name: 'Solomon Islands', code: 'SB'},
  {name: 'Somalia', code: 'SO'},
  {name: 'South Africa', code: 'ZA'},
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'},
  {name: 'Spain', code: 'ES'},
  {name: 'Sri Lanka', code: 'LK'},
  {name: 'Sudan', code: 'SD'},
  {name: 'Suriname', code: 'SR'},
  {name: 'Svalbard and Jan Mayen', code: 'SJ'},
  {name: 'Swaziland', code: 'SZ'},
  {name: 'Sweden', code: 'SE'},
  {name: 'Switzerland', code: 'CH'},
  {name: 'Syrian Arab Republic', code: 'SY'},
  {name: 'Taiwan, Province of China', code: 'TW'},
  {name: 'Tajikistan', code: 'TJ'},
  {name: 'Tanzania, United Republic of', code: 'TZ'},
  {name: 'Thailand', code: 'TH'},
  {name: 'Timor-Leste', code: 'TL'},
  {name: 'Togo', code: 'TG'},
  {name: 'Tokelau', code: 'TK'},
  {name: 'Tonga', code: 'TO'},
  {name: 'Trinidad and Tobago', code: 'TT'},
  {name: 'Tunisia', code: 'TN'},
  {name: 'Turkey', code: 'TR'},
  {name: 'Turkmenistan', code: 'TM'},
  {name: 'Turks and Caicos Islands', code: 'TC'},
  {name: 'Tuvalu', code: 'TV'},
  {name: 'Uganda', code: 'UG'},
  {name: 'Ukraine', code: 'UA'},
  {name: 'United Arab Emirates', code: 'AE'},
  {name: 'United Kingdom', code: 'GB'},
  {name: 'United States Minor Outlying Islands', code: 'UM'},
  {name: 'Uruguay', code: 'UY'},
  {name: 'Uzbekistan', code: 'UZ'},
  {name: 'Vanuatu', code: 'VU'},
  {name: 'Venezuela', code: 'VE'},
  {name: 'Viet Nam', code: 'VN'},
  {name: 'Virgin Islands, British', code: 'VG'},
  {name: 'Virgin Islands, U.S.', code: 'VI'},
  {name: 'Wallis and Futuna', code: 'WF'},
  {name: 'Western Sahara', code: 'EH'},
  {name: 'Yemen', code: 'YE'},
  {name: 'Zambia', code: 'ZM'},
  {name: 'Zimbabwe', code: 'ZW'}
];

export const stateCodes = [
    {
        "name": "Alabama",
        "code": "AL"
    },
    {
        "name": "Alaska",
        "code": "AK"
    },
    {
        "name": "American Samoa",
        "code": "AS"
    },
    {
        "name": "Arizona",
        "code": "AZ"
    },
    {
        "name": "Arkansas",
        "code": "AR"
    },
    {
        "name": "California",
        "code": "CA"
    },
    {
        "name": "Colorado",
        "code": "CO"
    },
    {
        "name": "Connecticut",
        "code": "CT"
    },
    {
        "name": "Delaware",
        "code": "DE"
    },
    {
        "name": "District Of Columbia",
        "code": "DC"
    },
    {
        "name": "Federated States Of Micronesia",
        "code": "FM"
    },
    {
        "name": "Florida",
        "code": "FL"
    },
    {
        "name": "Georgia",
        "code": "GA"
    },
    {
        "name": "Guam",
        "code": "GU"
    },
    {
        "name": "Hawaii",
        "code": "HI"
    },
    {
        "name": "Idaho",
        "code": "ID"
    },
    {
        "name": "Illinois",
        "code": "IL"
    },
    {
        "name": "Indiana",
        "code": "IN"
    },
    {
        "name": "Iowa",
        "code": "IA"
    },
    {
        "name": "Kansas",
        "code": "KS"
    },
    {
        "name": "Kentucky",
        "code": "KY"
    },
    {
        "name": "Louisiana",
        "code": "LA"
    },
    {
        "name": "Maine",
        "code": "ME"
    },
    {
        "name": "Marshall Islands",
        "code": "MH"
    },
    {
        "name": "Maryland",
        "code": "MD"
    },
    {
        "name": "Massachusetts",
        "code": "MA"
    },
    {
        "name": "Michigan",
        "code": "MI"
    },
    {
        "name": "Minnesota",
        "code": "MN"
    },
    {
        "name": "Mississippi",
        "code": "MS"
    },
    {
        "name": "Missouri",
        "code": "MO"
    },
    {
        "name": "Montana",
        "code": "MT"
    },
    {
        "name": "Nebraska",
        "code": "NE"
    },
    {
        "name": "Nevada",
        "code": "NV"
    },
    {
        "name": "New Hampshire",
        "code": "NH"
    },
    {
        "name": "New Jersey",
        "code": "NJ"
    },
    {
        "name": "New Mexico",
        "code": "NM"
    },
    {
        "name": "New York",
        "code": "NY"
    },
    {
        "name": "North Carolina",
        "code": "NC"
    },
    {
        "name": "North Dakota",
        "code": "ND"
    },
    {
        "name": "Northern Mariana Islands",
        "code": "MP"
    },
    {
        "name": "Ohio",
        "code": "OH"
    },
    {
        "name": "Oklahoma",
        "code": "OK"
    },
    {
        "name": "Oregon",
        "code": "OR"
    },
    {
        "name": "Palau",
        "code": "PW"
    },
    {
        "name": "Pennsylvania",
        "code": "PA"
    },
    {
        "name": "Puerto Rico",
        "code": "PR"
    },
    {
        "name": "Rhode Island",
        "code": "RI"
    },
    {
        "name": "South Carolina",
        "code": "SC"
    },
    {
        "name": "South Dakota",
        "code": "SD"
    },
    {
        "name": "Tennessee",
        "code": "TN"
    },
    {
        "name": "Texas",
        "code": "TX"
    },
    {
        "name": "Utah",
        "code": "UT"
    },
    {
        "name": "Vermont",
        "code": "VT"
    },
    {
        "name": "Virgin Islands",
        "code": "VI"
    },
    {
        "name": "Virginia",
        "code": "VA"
    },
    {
        "name": "Washington",
        "code": "WA"
    },
    {
        "name": "West Virginia",
        "code": "WV"
    },
    {
        "name": "Wisconsin",
        "code": "WI"
    },
    {
        "name": "Wyoming",
        "code": "WY"
    }
];

//public static orgTypes = [

export const orgTypes = [
  {name: 'Collaboration Team (Non-Business)', code: 'NPTEAM', type: 'TEAM'},
  {name: 'Business/Corporation', code: 'CORP', type: 'CORP'},
  {name: 'Business Association/Org', code: 'BIZORG', type: 'BIZORG'},
  {name: 'Non-Profit Organization', code: 'NPORG', type: 'NPORG'},
  {name: 'Academic School/College/University', code: 'EDU', type: 'EDU'},
  {name: 'Government', code: 'GOV', type: 'GOV'},
];

export const orgSubTypes = [
  {name: 'Friends & Family', code: 'FFTEAM', type: 'TEAM'},
  {name: 'Social Club/Group', code: 'SOCTEAM', type: 'TEAM'},
  {name: 'Special Interests', code: 'SITEAM', type: 'TEAM'},
  {name: 'Sole Proprietor', code: 'SOLEPRO', type: 'CORP'},
  {name: 'Limited Liability Corp/Partnership', code: 'LLC', type: 'CORP'},
  {name: 'Limited Liability Partnership', code: 'LLP', type: 'CORP'},
  {name: 'S Corporation', code: 'SCORP', type: 'CORP'},
  {name: 'C Corporation (Private)', code: 'PVTCORP', type: 'CORP'},
  {name: 'C Corporation (Public)', code: 'CORP', type: 'CORP'},
  {name: 'C Corporation (Public)', code: 'CORP', type: 'CORP'},
  {name: 'Religious Organization', code: 'RLORG', type: 'NPORG'},
  {name: 'Charitable Organization', code: 'CHORG', type: 'NPORG'},
  {name: 'Political Organization', code: 'POLORG', type: 'NPORG'},
  {name: 'Other Non-Profit Organization', code: 'OTHORG', type: 'NPORG'},
  {name: 'Multi-Business Team', code: 'BIZTEAM', type: 'BIZORG'},
  {name: 'Business Association/Org', code: 'BIZORG', type: 'BIZORG'},
  {name: 'Business Consortium', code: 'BIZCON', type: 'BIZORG'},
  {name: 'Public School/College/University', code: 'PUBEDU', type: 'EDU'},
  {name: 'Private School/College/University', code: 'PVTEDU', type: 'EDU'},
  {name: 'Local Government', code: 'GOVLOC', type: 'GOV'},
  {name: 'State/Provincial Government', code: 'GOVSTP', type: 'GOV'},
  {name: 'Federal Government', code: 'GOVFED', type: 'USGOV'}
];


//public static orgContactTypes = [
export const orgContactTypes = [
  {name: 'Primary', code: 'PRIMARY'},
  {name: 'Billing', code: 'BILLING'},
  {name: 'Sales', code: 'SALES'},
  {name: 'Support', code: 'SUPPORT'}
];

//public static orgCategories = [
export const orgCategories = [
  {name: 'Aerospace', code: 'AEROSPACE'},
  {name: 'Accounting', code: 'ACCOUNTING'},
  {name: 'Advertising', code: 'ADVERTISING'},
  {name: 'Automotive', code: 'AUTOMOTIVE'},
  {name: 'Agriculture', code: 'AGRICULTURE'},
  {name: 'Banking & Finance.', code: 'BANKING'},
  {name: 'Business Services', code: 'BUS_SVCS'},
  {name: 'Food Industry/Svcs.', code: 'FOOD'},
  {name: 'E-Commerce', code: 'ECOMMERCE'},
  {name: 'Energy', code: 'ENERGY'},
  {name: 'Engineering', code: 'ENGINEERING'},
  {name: 'Financial Svcs.', code: 'FINSVCS'},
  {name: 'Construction', code: 'CONSTRUCTION'},
  {name: 'Cryptocurrencies', code: 'CRYTPTO'},
  {name: 'Government', code: 'GOVERNMENT'},
  {name: 'Healthcare', code: 'HEALTHCARE'},
  {name: 'Holding Company / Conglomerate'},
  {name: 'Hospitality', code: 'HOSPITALITY'},
  {name: 'Information Technology Svcs.', code: 'ITSVCS'},
  {name: 'Legal Services', code: 'LEGAL'},
  {name: 'Logistics', code: 'LOGISTICS'},
  {name: 'Marketing', code: 'MARKETING'},
  {name: 'Manufacturing', code: 'MANUFACTURING'},
  {name: 'Mining', code: 'MINING'},
  {name: 'Property Management', code: 'PROPMGMT'},
  {name: 'Publishing', code: 'PUBLISHING'},
  {name: 'Real Estate', code: 'REALESTATE'},
  {name: 'Real Estate (Commercial)', code: 'COMREALESTATE'},
  {name: 'Retaurant', code: 'RESTAURANT'},
  {name: 'Retail', code: 'RETAIL'},
  {name: 'Transportation', code: 'TRANSPORT'},
  {name: 'Utilities', code: 'UTILITIES'},
  {name: 'Not Designated', code: 'NA'},
];

export const orgGovCategories = [
  {name: 'Department', code: 'GOVDEPT', type: 'GOV'},
  {name: 'Executive', code: 'GOVEXEC', type: 'GOV'},
  {name: 'Judicial', code: 'GOVCOURT', type: 'GOV'},
  {name: 'Legislature', code: 'GOVLEG', type: 'GOV'},
  {name: 'Law Enforcement', code: 'GOVLAW', type: 'GOV'},
  {name: 'Military', code: 'GOVMIL', type: 'GOV'},
];

// public static getOrgTypes() {
export function getOrgTypes() {
  return this.orgTypes;
}

//public static getOrgContactTypes() {
export function getOrgContactTypes() {
  return this.orgContactTypes;
}

//public static getOrgCategories() {
export function getOrgCategories() {
  return this.orgCategories;
}

//public static orgStatuses = [
export const orgStatuses = [
  {name: 'Sales Prospect', code: 'PROSPECT'},
  {name: 'Free Trial/Demo', code: 'FREETRIAL'},
  {name: 'Active Subscriber', code: 'ACTIVE'},
  {name: 'Paused Subscription', code: 'PAUSED'},
  {name: 'Cancelled Subscriber', code: 'CANCELLED'},
  {name: 'Bill Hold / Unpaid', code: 'BILLHOLD'}
]

export function getOrgStatuses() {
  return this.orgStatuses;
}

//public static orgUserTypes = [
export const orgUserTypes = [
{type: 'CORP', code: 'E', name: 'Employee/Member'},
{type: 'CORP', code: 'G', name: 'Guest User/Collaborator'},
]

//public static getOrgUserTypes() {
export function getOrgUserTypes() {
  return this.orgUserTypes;
}

export const orgUserEMPSubTypes = [
{type: 'E', orgtype: 'CORP', code: 'EMPFT', name: 'Full Time Employee'},
{type: 'E', orgtype: 'CORP', code: 'EMPPT', name: 'Part Time Employee'},
{type: 'E', orgtype: 'CORP', code: 'CONFT', name: 'Full Time Contractor'},
{type: 'E', orgtype: 'CORP', code: 'CONPT', name: 'Part Time Contractor'}
];


//public static orgUserStatuses = [
export const orgUserStatuses = [
  {name: 'Active', code: 'A'},   // also change user free to False
  {name: 'Disabled', code: 'D'}  // also change user free to True
];

//public static getOrgUserStatuses() {
export function getOrgUserStatuses() {
  return this.orgUserStatuses;
}

export const DocTypes = [
  {name: 'PDF', code: 'PDF'},   // also change user free to False
  {name: 'MS Word', code: 'DOCX'}  // also change user free to True
]

//public static getDocTypes() {
export function getDocTypes() {
  return this.DocTypes;
}

/****
  mapItem = {[key: string]: any}

  export select_org_type: mapItem {
   'CORP': 'Corporation',
   'LLC': 'LLC',
   'SOLEP': 'Sole Proprietor',
   'NONPROFIT': 'Non-Profit Organization',
   'TAXORG': 'Taxable Organization'
  }
******/


// } // class SelectLists
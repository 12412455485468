// import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';

import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// import { IonicApp, IonicModule } from '@ionic/angular';
// import { IonicModule } from '@ionic/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule} from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';


// import { PdfViewerModule, PdfViewerComponent } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './app.intercept';
import { AuthService } from './service/auth.service';
import { NotifyQueueService } from './service/notify-queue.service';

import { HttpClientModule } from '@angular/common/http';

/**** FEATURE MODULES ***/
// import { MDToolsModule } from './mdtools/mdtools.module';
// import { MDCommonModule } from './mdcommon/mdcommon.module';
// import { MDDialogModule } from './dialog/md-dialog.module';
import { GroupModule } from './group/group.module';
import { DocumentModule } from './document/document.module';
import { OrgModule } from './org/org.module';
import { PipeModule } from './pipe/pipe.module';
import { DatePipe } from '@angular/common';

/**** COMPONENTS ***/
import { LoginComponent } from './login/login.component';
import { UIComponent } from './ui/ui.component';
import { MainComponent } from './main/main.component';
// import { MainMobileComponent } from './main-mobile/main-mobile.component';

import { SettingsDialogComponent } from './settings-dialog/settings-dialog.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FeedbackDataComponent } from './feedback-data/feedback-data.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { TosAcceptComponent } from './tos-accept/tos-accept.component';
import { TosComponent } from './tos/tos.component';
import { SubaccountEditComponent } from './subaccount-edit/subaccount-edit.component';
import { NewUserSignupComponent } from './new-user-signup/new-user-signup.component';
import { SendDataComponent } from './send-data/send-data.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';

// 56-248 CJ - New dialogs for convert/upload
import { ImageSpecDialogComponent } from './dialog/image-spec/image-spec.dialog';
import { UploadFilesDialogComponent } from './dialog/upload-files/upload-files.dialog';
import { GroupNameDialogComponent } from './dialog/group-name/group-name.dialog';
import { QuickStartDialogComponent } from './dialog/quick-start/quick-start.dialog';
import { NewUserEmailComponent } from './new-user-email/new-user-email.component';
// import { AddGroupMemberComponent } from './add-group-member/add-group-member.component';
import { GroupManagerDialogComponent } from './dialog/group-manager/group-manager.dialog';
import { GroupManagerComponent } from './group-manager/group-manager.component';
// Using Module now...
// import { GroupMemberListComponent } from './group/group-member-list/group-member-list.component';
import { MarkerListComponent } from './marker-list/marker-list.component';
import { UserListComponent } from './user-list/user-list.component';

import { MarkerDialogComponent } from './marker-dialog/marker-dialog.component';
import { CreateLinkComponent } from './create-link/create-link.component';

import { ActionPromptDialogComponent } from './dialog/action-prompt/action-prompt.dialog';
import { FormDialogComponent } from './dialog/form/form.dialog';
import { ProgressDialogComponent } from './dialog/progress/progress.dialog';
import { UserInfoDialogComponent } from './dialog/user-info/user-info.dialog';
import { FileInfoDialogComponent } from './dialog/file-info/file-info.dialog';

import { AboutDialogComponent } from './dialog/about/about.dialog';
import { CreateLinkDialogComponent } from './dialog/create-link/create-link.dialog';
// import { LinkDialogComponent } from './dialog/link/link.dialog';
// import { LinkDialogModule } from './dialog/link/link.module';

import { UserSettingsDialogComponent } from './dialog/user-settings/user-settings.dialog';
import { UserSubAccountsDialogComponent } from './dialog/user-subaccounts/user-subaccounts.dialog';
import { HelpDialogComponent } from './dialog/help/help.dialog';
import { MarkerReportDialogComponent } from './dialog/marker-report/marker-report.dialog';
import { FeedbackDialogComponent } from './dialog/feedback/feedback.dialog';
import { SendTokenDialogComponent } from './dialog/send-token/send-token.dialog';
import { PrintSheetComponent } from './sheet/print/print-sheet.component';
import { DownloadSheetComponent } from './sheet/download/download-sheet.component';
// import { FindSheetComponent } from './sheet/find/find-sheet.component';

// import { MgrMainComponent } from './mgr/mgr-main/mgr-main.component';


import { ToastrModule, ToastrService } from 'ngx-toastr';
import { ToasterService } from './service/toaster.service';
import { PlatformService } from './service/platform.service';
import { DocumentService } from './service/document.service';
import { HelpService } from './service/help.service';
import { ValidationService } from './service/validation.service';
import { GroupService } from './service/group.service';
import { GroupMemberService } from './service/group-member.service';
import { UserService } from './service/user.service';
import { UserSubAccountService } from './service/user-subaccount.service';
import { LayerService } from './service/layer.service';
import { MarkerService } from './service/marker.service';
import { MailService } from './service/mail.service';
import { PdfService } from './service/pdf.service';
import { HyperlinkService } from './service/hyperlink.service';
import { AdminService } from './service/admin.service';
import { OrganizationService } from './service/organization.service';
import { OrgUserService } from './service/orguser.service';
import { OrgUserInfoService } from './service/orguserinfo.service';

// Pipe module
//import { NameFilter } from './pipe/name-filter.pipe';
//import { FilterKeys } from './pipe/filterkeys.pipe';
//import { SortKey } from './pipe/sortkey.pipe';
//import { Linkify } from './pipe/linkify.pipe';

//         MainMobileComponent,
// Removing in hyperlinks pinch: { threshold: 2 },     
export class HammerConfig extends HammerGestureConfig {
  overrides = {
     swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

// SW Removed temporarily from imports:
// ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),

// Removed from imports:
// 	IonicModule.forRoot({
//          swipeBackEnabled: false
//	}),

//         MgrMainComponent,	
@NgModule({
    declarations: [
        AppComponent,
        AboutDialogComponent,
        // AddGroupMemberComponent,
        ActionPromptDialogComponent,
        CreateLinkComponent,	
        CreateLinkDialogComponent,
        DownloadSheetComponent,
        FeedbackDataComponent,
        FeedbackDialogComponent,	
        FileInfoDialogComponent,
        FormDialogComponent,
        GroupNameDialogComponent,
        GroupManagerDialogComponent,
        GroupManagerComponent,
        HelpDialogComponent,
        ImageSpecDialogComponent,	
        LoginComponent,
        MainComponent,
        MarkerListComponent,
        MarkerDialogComponent,
        MarkerReportDialogComponent,
        NewUserSignupComponent,
        NewUserEmailComponent,
        OnboardingComponent,
        // OrgComponent,  Now a module
        // OrgListComponent,
        PasswordResetComponent,
        PrintSheetComponent,	
        ProgressDialogComponent,	
        QuickStartDialogComponent,	
        SettingsDialogComponent,
        SendDataComponent,
        SendTokenDialogComponent,
        SubaccountEditComponent,
        TosAcceptComponent,
        TosComponent,
        UploadFilesComponent,
        UploadFilesDialogComponent,	
        UserInfoDialogComponent,
        UserListComponent,	
        UserSettingsDialogComponent,
        UserSubAccountsDialogComponent,
        UIComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,	
        BrowserAnimationsModule,
        ClipboardModule,
	DocumentModule,
        DragDropModule,	
        FormsModule,
        HammerModule,	
        HttpClientModule,	
        NgxExtendedPdfViewerModule,
	OrgModule,
	GroupModule,	
	PipeModule,
        ReactiveFormsModule,
        ToastrModule.forRoot({
           positionClass :'toast-center-center'
        }),
        MatAutocompleteModule,	
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
	MatFormFieldModule,
        MatIconModule,	
	MatInputModule,
        MatListModule,	
        MatMenuModule,
        MatPaginatorModule,	
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,	
        MatRippleModule,
        MatSortModule,
        MatSelectModule,
        MatSidenavModule,	
        MatSlideToggleModule,
        MatStepperModule,	
        MatToolbarModule,
        MatTabsModule,
        MatTableModule,
        MatTooltipModule,	
    ],
    exports: [
        MatToolbarModule,
        MatSidenavModule,	
	HelpDialogComponent,
    ],
    providers: [
        AuthService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {   provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerGestureConfig,
            deps: []
        },
        DatePipe,
	PlatformService,
        ToasterService,
        ToastrService,
        NotifyQueueService,
        DocumentService,
        PdfService,
        GroupService,
        GroupMemberService,
        HyperlinkService,
        OrganizationService,
        OrgUserService,
        OrgUserInfoService,
        AdminService,
        UserService,
        UserSubAccountService,
        HelpService,
        ValidationService,
        LayerService,	
        MarkerService,
        MailService,
        {  provide: MatDialogRef,
           useValue: {}
        },
        {  provide: MAT_DIALOG_DATA,
           useValue: {}
        },
    ],
    bootstrap: [AppComponent],
    entryComponents: [ ImageSpecDialogComponent,
                       UploadFilesDialogComponent,
                       GroupNameDialogComponent,
                       GroupManagerDialogComponent,
                       QuickStartDialogComponent,
                       ActionPromptDialogComponent,
                       FormDialogComponent,		       
                       ProgressDialogComponent,
                       FileInfoDialogComponent,
                       CreateLinkDialogComponent,
                       UserInfoDialogComponent,
                       UserSettingsDialogComponent,
                       UserSubAccountsDialogComponent,
                       HelpDialogComponent,
                       MarkerReportDialogComponent,
                       FeedbackDialogComponent,
                       AboutDialogComponent,
                       SendTokenDialogComponent,
                       PrintSheetComponent,
                       DownloadSheetComponent
                     ]
})


//     schemas: [ CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA ]

export class AppModule { }

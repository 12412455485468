<ng-template #loading>
  <div>loading...</div>
</ng-template>

<ng-template #groupBtns>
        <div class="group-btns">
          <button mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="openCreateGroupDialog()"
                  matTooltip="Add Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">group_add</mat-icon>
                  <div class="label-text">Add</div>		  
          </button>
          <button *ngIf="selectedGroup && selectedGroup.owner === userData.uid"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="openRenameGroupDialog(selectedGroup)"
                  matTooltip="Rename Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">edit</mat-icon>
                  <div class="label-text">Edit/Rename</div>		  
          </button>
	  <!---
          <button mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="sidenavGroupManager()"
                  matTooltip="Edit Groups & Members">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0;">manage_accounts</mat-icon>
                  <div class="label-text">Manage</div>		  
          </button>
          <button *ngIf="selectedGroupObj"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="sidenavGroupUpload()"
                  matTooltip="Add Files/Content">
                  <mat-icon class="label-icon"
		    style="margin: 0; padding: 0;">note_add_outlined</mat-icon>
                  <div class="label-text">+Files</div>
          </button>
          --->
          <button *ngIf="selectedGroup && selectedGroup.owner === userData.uid"
		  mat-button style="padding: 0; margin: 0;"
		  class="group-btn"
                  (click)="openDeleteGroupDialog(selectedGroup.uid, selectedGroup.name)"
                  matTooltip="Delete Group">
                  <mat-icon class="label-icon"
			    style="margin: 0; padding: 0; color: red;">delete_forever</mat-icon>
                  <div class="label-text">Delete</div>		  
          </button>
        </div>
</ng-template>

<mat-menu #objMenu="matMenu">
    <button mat-menu-item enabled (click)="setSelectedGroup(null)">
          <mat-icon>search_off</mat-icon>  
          <span style="font-size: 0.9em">Clear Selection</span>
    </button>
    <button mat-menu-item enabled (click)="refreshList()">
          <mat-icon>refresh</mat-icon>  
          <span style="font-size: 0.9em">Refresh List</span>
    </button>
   <button mat-menu-item  (click)="createObj()">
     <mat-icon>add</mat-icon>        
     <span style="font-size: 0.9em">Add New {{objLabel}}</span>
   </button>
   <button mat-menu-item disabled="{{!selectedGroup}}" (click)="editObj()">
      <mat-icon>edit</mat-icon>  
      <span style="font-size: 0.9em">Edit {{objLabel}}</span>
   </button>
   <button mat-menu-item disabled="{{!selectedGroup}}" (click)="viewObj()">
      <mat-icon>domain</mat-icon>  
      <span style="font-size: 0.9em">View {{objLabel}}</span>
   </button>
   <button *ngIf="isObjAdmin"
	  mat-menu-item disabled (click)="deleteObj()">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
  </mat-menu>

  <mat-menu #listViewMenu="matMenu">
    <button mat-menu-item enabled (click)="setMode(DisplayMode.COMBO)">
          <mat-icon>input</mat-icon>  
          <span style="font-size: 0.9em">Combo Select</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT)">
          <mat-icon>arrow_drop_down</mat-icon>  
          <span style="font-size: 0.9em">Select</span>
6    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT_LIST)">
          <mat-icon>view_list</mat-icon>  
          <span style="font-size: 0.9em">Select List</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.TABLE); refreshList()">
          <mat-icon>table_rows_narrow</mat-icon>  
          <span style="font-size: 0.9em">Table</span>
    </button>
  </mat-menu>
  
  <mat-menu #rowMenu="matMenu">    
    <ng-template matMenuContent let-obj="obj">
      <button mat-menu-item (click)="setSelectedObj(obj); viewObj()">
         <mat-icon>domain</mat-icon>  
         <span style="font-size: 0.9em">View Info</span>
      </button>
      <button *ngIf="isObjAdmin"
	      mat-menu-item (click)="setSelectedObj(obj); editObj()">
         <mat-icon>edit</mat-icon>  
         <span style="font-size: 0.9em">Edit {{objLabel}}</span>
      </button>
      <button *ngIf="isObjAdmin"
	      mat-menu-item (click)="setSelectedObj(obj); deleteObj()">
         <mat-icon>delete</mat-icon>
         <span style="font-size: 0.9em">Delete {{objLabel}}</span>
      </button>
    </ng-template>    
  </mat-menu>
  
  <mat-menu #objListMenu="matMenu">
    <button mat-menu-item enabled (click)="setSelectedObj(null)">
          <mat-icon>search_off</mat-icon>  
          <span style="font-size: 0.9em">Clear Selection</span>
    </button>
    <button mat-menu-item enabled (click)="refreshList()">
          <mat-icon>refresh</mat-icon>  
          <span style="font-size: 0.9em">Refresh List</span>
    </button>
    <!-- *ngIf="userData.iam" -->
   <button mat-menu-item  (click)="createObj()">
     <mat-icon>add</mat-icon>        
     <span style="font-size: 0.9em">Add {{objLabel}}</span>
   </button>
   <button *ngIf="selectedObj && selectedObj !== null"
	   mat-menu-item disabled="{{!selectedObj}}" (click)="editObj()">
      <mat-icon>edit</mat-icon>  
      <span style="font-size: 0.9em">Edit {{objLabel}}</span>
   </button>
   <button *ngIf="selectedObj && selectedObj !== null"   
            mat-menu-item disabled="{{!selectedObj}}" (click)="viewObj()">
      <mat-icon>domain</mat-icon>  
      <span style="font-size: 0.9em">View {{objLabel}}</span>
   </button>
   <!---
   <button *ngIf="userData && userData.uid && selectedObj && (selectedObj['owner']===userData.uid)"
	  mat-menu-item (click)="deleteObj()">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
    --->
  </mat-menu>

  <ng-template #hidden>
  </ng-template>

  <ng-template #loading>
    <div>Loading...</div>
  </ng-template>

  <div #grpTab>
     <!-- [titleFilter]="titleFilter.value" -->    
     <app-group-list #grpList class="left-content-scroll"
		     style="margin-top: 1rem; margin-left: 1rem; padding: 0; width: 80vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="iam"
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showAddButton]="true"
         [showEditButton]="false"
         [showDeleteButton]="false"		
         [showMenuButton]="false"		
         [showListViewButton]="true"
         [selectedObj]="selectedGroup"		   
         [objData]="objData"
	 (refreshListEvent)="refreshList()"
         (selectObjEvent)="setSelectedGroup($event)">
     </app-group-list>
     
  <mat-tab-group style="overflow: hidden !important;" (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="currentTab">
    <mat-tab class="form-tab">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">group</mat-icon>
        Info
      </ng-template>
       <mat-list>      
       <mat-divider></mat-divider>
       <div *ngIf="selectedGroup">
	 <!---
           [doSubmit$]="doSubmitTab$[0]"
	   [doReset$]="doResetTab$[0]"
           [doEdit$]="doEditTab$[0]"
           [doView$]="doView$"
	 ---->
       <app-group #objFormItem
           style="width: 100%"
           [iam]="iam"		
           [mode]="DisplayMode.VIEW"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="selectedGroup"
           [selectedObj]="selectedGroup"
           [useMenuActionDialog]="useMenuActionDialog"
           [showActions]="showActions"
           (modeEvent)="setMode($event)"		
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedGroupEvent($event)"
           (selectGroupEvent)="setSelectedGroupEvent($event)"		  
	   (deleteObjEvent)="onDeleteObj($event)"		
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()">
        </app-group>
        <mat-divider [inset]="true"></mat-divider>	
        </div>     
        <mat-divider style="color: gray; min-width: 100%"></mat-divider>
      </mat-list>
    </mat-tab>
    <mat-tab class="form-tab" label="Contacts">
      <ng-template mat-tab-label>
         <mat-icon class="tab-icon">contact_mail</mat-icon>
          Members
      </ng-template>
      <div *ngIf="selectedGroup">
	  <!--- (countMembersEvent)="this.handleGroupMemberCount($event)" --->
          <app-group-member-list #grpMemberList
           style="text-align: center; align-items: center; margin-bottom: 50px; width=100%"
             id="mbl-member-div2"             
             [template]=5
             [userData]="userData"
             [userInfo]="userInfo"
             [group]="selectedGroup"
             [groupUID]="selectedGroup.uid"
            ></app-group-member-list>
       <mat-divider [inset]="true"></mat-divider>	
       </div>     
    </mat-tab>
    <mat-tab class="form-tab" label="Contacts">
      <ng-template mat-tab-label>
         <mat-icon class="tab-icon">description</mat-icon>
          Files
      </ng-template>
      <div *ngIf="selectedGroup">
	 <!---           [objData]="selectedOrgUser"  -->
         <input matInput #titleFilterInput
                type="text"
	        style="width: 60%; font-size: 1rem;"
		placeholder="Enter Search filter"/>
         <app-group-file-list #grpFileList 
           class="left-content-scroll"
	   style="margin: 0; padding: 0; width: 75vw; max-width: 99vw; overflow-y: auto"
           [iam]="false"
           [mode]="'SL'"
           [showSearchButton]="false"
           [showSelectButton]="false"
           [showDoneButton]="false"
           [showMenuButton]="false"
           [showAddButton]="false"			      
           [showListViewButton]="false"
           [showTitleFilter]="false"
           [userInfo]="userInfo"
           [userData]="userData"
           [selectedObj]="selectedGroup"			      
           [selectedGroup]="selectedGroup"
           [titleFilter]="titleFilterInput.value"
           (refreshListEvent)="refreshData()">
         </app-group-file-list>
       <mat-divider [inset]="true"></mat-divider>	
       </div>     
    </mat-tab>
</mat-tab-group>

</div> <!-- app-org-tab -->

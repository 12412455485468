import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges, Input, Output,
         ViewChild, EventEmitter, ElementRef, OnDestroy }
         from '@angular/core';

import { FormControl, FormGroupDirective, FormBuilder, FormGroup, NgForm,
         Validators } from '@angular/forms';

import { ErrorStateMatcher } from '@angular/material/core';

// import { lastValueFrom } from 'rxjs';
import { Subject, Observable, Subscription } from 'rxjs';

import { SelectionModel } from '@angular/cdk/collections';

// import { SelectLists } from '../../mdtools/select-lists.common';

import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import { AuthService } from '../../service/auth.service';
import { ToasterService } from '../../service/toaster.service';
import { UserService } from '../../service/user.service';
import { GroupService } from '../../service/group.service';
import { DocumentService } from '../../service/document.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';

import { OrgModule } from '../../org/org.module';
import { GroupModule } from '../../group/group.module';

import { ActionPromptDialogComponent } from '../../dialog/action-prompt/action-prompt.dialog';
import { FormDialogComponent } from '../../dialog/form/form.dialog';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';
import { GroupData } from '../../group/model/groupdata.model';
import { DocumentData } from '../model/documentdata.model';
import { OrgData } from '../../org/model/orgdata.model';
import { OrgUserInfo } from '../../org/model/orguserinfo.model';
import { OrgUserData } from '../../org/model/orguserdata.model';

import * as DOC from './group-file.component';
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;


/** Error when invalid control is dirty, touched, or submitted. */
export class FormErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
   selector: 'app-group-file',
   templateUrl: './group-file.component.html',
   styleUrls: ['./group-file.component.css'],
})
export class GroupFileComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

   DisplayModel: typeof STATIC.Model = STATIC.Model;
   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   private oid: string;

   @Input()  iam = false;
   @Input()  mode = this.DisplayMode.SELECT;
   @Input()  userInfo: UserInfo | null;
   @Input()  userData: UserData | null;
   @Input()  template: number;
   @Input()  groupData: GroupData | null;
   @Input()  selectedObj: any | null;
   @Input()  selectedOrg: OrgData | null;   
   @Input()  selectedGroup: GroupData | null;   
   @Input()  objData: any | null;

   @Input()  titleFilter: any | null;

   @Input()  isSelected = false;
   @Input()  isObjAdmin = false;   
   @Input()  showActions = true;
   @Input()  doSubmit = false;
   @Input()  doReset = false;
   @Input()  uid: string | null = null;
   @Input()  nameLabel: string | null  = 'Group File Name';
   @Input()  useMenuActionDialog = true;
   @Input()  showFormTitle = false;
   @Input()  showInfoButton = false;
   @Input()  showMenuButton = false;
   @Input()  showDeleteButton = false;
   @Input()  doSubmit$: Subject<boolean>;
   @Input()  doReset$: Subject<boolean>;
   @Input()  doEdit$: Subject<boolean>;
   @Input()  doDelete$: Subject<boolean>;
   @Input()  doView$: Subject<boolean>;

   @Input()
   public get org_uid() {
       return this.oid;
   }
   public set org_uid(oid: string) {
       this.oid = oid;
       // this.get_org(this.oid);
       // this.changeDetector.markForCheck();
   }

   @Output() toggleObjDetail = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() selectGroupEvent = new EventEmitter<any>();
   @Output() refreshEvent = new EventEmitter<any>();
   @Output() submitEvent = new EventEmitter<any>();
   @Output() cancelEvent = new EventEmitter<any>();
   @Output() doneEvent = new EventEmitter<any>();
   @Output() prevEvent = new EventEmitter<any>();
   @Output() nextEvent = new EventEmitter<any>();
   @Output() modeEvent = new EventEmitter<any>();
   @Output() isObjAdminEvent = new EventEmitter<any>();

   // Dialog vars
   // public actionPromptDialog: MatDialog;
   // public objectDialog: MatDialog;
   // public helpDialog: MatDialog;

   // DialogRef vars
   actionPromptDialogRef: any = null;
   formDialogRef: any = null;
   helpDialogRef: any = null;

   // View vars
   objLabel = 'Group File';
   objFieldName = 'name';
   showDetail = false;
   viewOnly = false;
   isExpanded = false;
   showHidden = false;
   // isSelected = false;

   // Org Admin
   orgData: OrgData = null;
   // isObjAdmin = false;
   isOrgAdmin = false;
   isGroupAdmin = false;   

   // Form vars
   formFieldStyle = 'fill';  // or '' or outline
   objForm: FormGroup;
   // orgStatusForm: FormGroup;
   isAddMode: boolean;
   isFormReady: boolean;
   loading = false;
   submitted = false;


//   public orgTypes = LISTS.SelectLists.orgTypes;
//   public orgCategories = LISTS.SelectLists.orgCategories;
//   public statusCodes = LISTS.SelectLists.orgStatuses;
   // public stateCodes = LISTS.SelectLists.stateCodes;
//   public countryCodes = LISTS.SelectLists.countryCodes;

   public objData$: Observable<any | null> = null;

   public orgData$: Observable<any | null> = null;
   public groupData$: Observable<any | null> = null;
   public userData$: Observable<any | null> = null;   

   public orgTypes = LISTS.orgTypes;
   public orgCategories = LISTS.orgCategories;
   public statusCodes = LISTS.orgStatuses;
   // public stateCodes = LISTS.stateCodes;
   public countryCodes = LISTS.countryCodes;

   public errorMatcher = new FormErrorStateMatcher();

   orgNbrStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; margin-right: 1rem",
     selected: "font-weight: bold; color: #2274A5; margin-right: 1rem"
   }

   orgNameStyles: {[key: string]: string}= {
     default: "font-weight: normal; color: black; width: 100%;",
     selected: "font-weight: normal; color: #2274A5; margin-right: 1rem"
   }

   orgNbrStyle=this.orgNbrStyles.default;
   orgNameStyle=this.orgNameStyles.default;

   // Instance vars
   is_app_mgr = false;

   orgUserData: OrgUserData;
   orgUserSubscription: any;
   doSubmitSubscription: any;

   constructor(
      public toast: ToasterService,
      private el: ElementRef,
      private fb: FormBuilder,
      private auth: AuthService,
      public orgsvc: OrganizationService,
      public orgusersvc: OrgUserService,
      public groupsvc: GroupService,
      public documentsvc: DocumentService,      
      public usersvc: UserService,
      public actionPromptDialog: MatDialog,
      public formDialog: MatDialog,
      public helpDialog: MatDialog,
      ) {
      } // constructor

   async ngOnInit() {
      // console.log('group iam=', this.iam);
      // console.log('group objData=', this.objData);
      this.userData = this.auth.getTokenInfo();
      this.refreshData();
      if ( this.uid ) {
         this.objData$ = this.groupsvc.getGroup$( this.uid );
      }
      if ( this.doSubmit$ ) {
        this.doSubmit$.subscribe( v => {
           if ( v === true ) {
             // console.log('group doSubmit v=', v);
             this.onSubmit();
             // this.doSubmit = false;
           }
        });
      }
      if ( this.doEdit$ ) {
        this.doEdit$.subscribe( v => {
           if ( v === true ) {
             this.onEdit();
             // console.log('group doEdit v=', v);
           }
        });
      }
      if ( this.doDelete$ ) {
        this.doDelete$.subscribe( v => {
           if ( v === true ) {
             this.onDelete();
             // console.log('group doDelete v=', v);
           }
        });
      }
      if ( this.doReset$ ) {
        this.doReset$.subscribe( v => {
           if ( v === true ) {
             this.onReset();
             // console.log('group doReset v=', v);
           }
        });
      }
      if ( this.doView$ ) {
        this.doView$.subscribe( v => {
           if ( v === true ) {
             this.onView();
             // console.log('group doView v=', v);
           }
        });
      }
      this.setModeTemplate(this.mode);
      if (this.objData) {
        this.isObjAdmin = await this.getIsObjAdmin(this.objData, this.userData);
        // console.log('group init isObjAdmin=', this.isObjAdmin);
        this.getObjFieldRefs(this.objData);
      }
   }

   ngAfterViewInit() {
      // console.log('group avi selectedObj=', this.selectedObj);
      // console.log('group avi objData=', this.objData);
   }

   async ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      // console.log('group changes=', changes);
      if ( changes.uid ) {
         this.objData$ = this.documentsvc.getDocument$( changes.uid.currentValue );
         this.uid = changes.uid.currentValue;
         this.getObjFieldRefs(changes.objData.currentValue);     
         // this.getObjFieldRefs(this.selectedObj);      
         // console.log('group changes uid=', this.uid);
      }
      if ( changes.selectedObj ) {
         this.selectedObj = changes.selectedObj.currentValue;
         // this.getObjFieldRefs(this.selectedObj);
      }
      if ( changes.objData ) {
         this.setObjData(changes.objData.currentValue);
         this.getObjFieldRefs(changes.objData.currentValue);
         this.isObjAdmin = await this.getIsObjAdmin(this.objData, this.userData);
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
      }
      if ( changes.doReset ) {
         if ( changes.doReset.currentValue === true ) {
            this.resetForm();
            this.doReset = false;
         }
      }
      if ( changes.doSubmit ) {
          // console.log('group changes doSubmit objData=', this.objData);
      }
      // console.log('changes objData=', this.objData);
      this.refreshData();
   }

   ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     /***
     if ( this.doSubmit$ ) {
        this.doSubmit$.unsubscribe();
     }
     ****/
   }

   async get_org(uid) {
       this.orgData  = await this.orgsvc.getOrganization(uid);
   }

   getObjFieldRefs(obj) {
      // console.log('group-file getObjFieldRefs');
      // console.table(obj);       
      if ( obj && obj.users_uid ) {
       this.userData$ = this.usersvc.getUserInfo$(obj.users_uid);
      }
      if ( obj && obj.org_uid ) {
       this.orgData$ = this.orgsvc.getOrganization$(obj.org_uid);
      }
      if ( obj && obj.groups_uid ) {
       this.groupData$ = this.groupsvc.getGroup$(obj.groups_uid);
      }
   }

   async refreshData() {
      if (this.userData) {
          // console.log('org-list userdata=', this.userData);
          this.is_app_mgr = this.userData.iam;
          this.orgUserData = null;
      } else {
             console.log('group component WARNING userData not defined.');
        }

      // this.setModeTemplate(this.mode);

      // console.log('mode=', this.mode);
      // console.log('template=', this.template);
      // console.log('DisplayMode=', this.DisplayMode);
   }

   async setModeTemplate(m) {
      // console.log('setMode called m=', m);
      this.isAddMode = false;
      this.viewOnly = false;

      this.modeEvent.emit(m);
      this.isObjAdmin = await this.getIsObjAdmin(this.objData, this.userData);

      switch(m) {
         case this.DisplayMode.SELECT: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.COMBO: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.EXPAND_LIST: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             break;
         }
         case this.DisplayMode.SELECT_LIST: {
             this.template = this.DisplayTemplate.SELECT_LIST;
             break;
         }
         case this.DisplayMode.LIST: {
             this.template = this.DisplayTemplate.LIST;
             break;
         }
         case this.DisplayMode.FORM: {
             this.template = this.DisplayTemplate.FORM;
             break;
         }
         case this.DisplayMode.ADD: {
             this.template = this.DisplayTemplate.FORM;
             this.viewOnly = false;
             this.isAddMode = true;
             if (this.userData && this.isObjAdmin ) {
                 this.viewOnly = false;
                 this.isAddMode = true;
             } else {
                 this.viewOnly = true;
                 this.isAddMode = false;
             }
             this.isFormReady = false;
             this.createForm();
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.EDIT: {
             this.template = this.DisplayTemplate.FORM;
             console.log('setModeTemplate isObjAdmin=', this.isObjAdmin);            
             if (this.userData && this.isObjAdmin ) {
                 this.viewOnly = false;
             } else {
                 this.viewOnly = true;
             }
             this.isAddMode = false;
             this.isFormReady = false;
             this.createForm();
             this.setForm();
             /***
             if ( this.userData ) {
                this.objForm.patchValue({owner: this.userData.uid});
             }
             ***/
             if ( this.objData ) {
                this.getObjFieldRefs(this.objData);
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.VIEW: {
             this.template = this.DisplayTemplate.FORM;
             this.isAddMode = false;
             this.isFormReady = false;
             this.viewOnly = true;
             this.createForm();
             this.setForm();
             if ( this.userData ) {
                this.objForm.patchValue({owner: this.userData.uid});
             }
             this.isFormReady = true;
             break;
         }
         case this.DisplayMode.DELETE: {
             if ( !this.isObjAdmin ) {
               console.error('group-file(delete): user is not objAdmin!');
               break;
             }
             this.template = this.DisplayTemplate.DELETE;
             this.viewOnly = true;           
             this.isAddMode = false;
             this.isFormReady = false;
             break;
         }
         case this.DisplayMode.FIELD_NAME: {
             this.template = this.DisplayTemplate.FIELD_NAME;
             break;
         }
      }
      // console.log('org setModeTemplate mode=', this.mode);
      // console.log('org setModeTemplate template=', this.template);

   }

   public async setObjData(obj) {
      this.objData = obj;
      this.isObjAdmin = await this.getIsObjAdmin(obj, this.userData);
      // console.log('group setObjData isObjAdmin=', this.isObjAdmin);
   }

   async setSelectedObj() {
      this.selectedObj = this.objData;
      this.selectObjEvent.emit(this.objData);
   }

   setSelectListObj(evt) {
      // console.log('setSelectListObj evt=', evt);
      this.setSelectedObj();
      this.doneEvent.emit(this.objData);
   }

  async getIsObjAdmin(obj: any, uData: UserData) {
     let obj_admin = false;
     if (obj && 'users_uid' in obj && uData && 'uid' in uData) {
        if ( obj.users_uid == uData.uid ) {
            obj_admin = true;
        }
     }
     if (obj && 'groups_uid' in obj && uData && 'uid' in uData) {
        const grp = await this.groupsvc.getGroupPromise(obj.groups_uid);
        if ( grp.owner == uData.uid ) {
            obj_admin = true;
        }
     }
     // if ( uData.iam && this.iam ) {
     //   return true;
     // }
     // console.log('group-file-list isObjAdmin=', obj_admin);     
     return obj_admin;
  }
   async getIsOrgAdmin(org: OrgData, uData: UserData) {
      let org_admin = false;
      if (org && 'uid' in org && uData && 'uid' in uData) {
         const orgusr = await this.orgusersvc.getOrgUserByOrgAndUidPromise(org.uid, uData.uid);
         if ( orgusr && 'org_admin' in orgusr && orgusr.org_admin ) {
             org_admin = true;
         } else {
             org_admin = false;
           }
      }
      return org_admin;
   }

   compareObjs( o1: any, o2: any ) {
     if( !o1 || !o2 )
       { return false; }
     else {
        return o1.uid === o2.uid;
     }
   }

   onCancel() {
     this.cancelEvent.emit(this.objForm);
   }

   async onSubmit() {
     // console.log('onSubmit form=', this.objForm);
     // console.log('onSubmit form value=', this.objForm.value);
     // console.log('onSubmit raw form=', this.objForm.getRawValue());

     this.submitted = true;
     if (this.objForm.valid) {
       // alert('Form Submitted succesfully!!!\n Check the values in browser console.');
       // console.log('FORM IS VALID');
       // console.table(this.objForm.value);

       // add and edit ########
       if ( this.isAddMode ) {
          this.objData = await this.addRecord( this.objForm );
       } else {
             this.objData = await this.editRecord( this.objForm );
       }
       console.log('group onSubmit objData=', this.objData);
       this.setSelectedObj();
     } else {
          console.error('FORM IS NOT VALID');
          this.objForm.markAllAsTouched();
          this.scrollToFirstInvalidControl();
       }
   }

   onDelete() {
     this.deleteRecord( this.selectedObj );
   }

   onReset() {
     this.resetForm();
   }

   onEdit() {
     this.setModeTemplate(this.DisplayMode.EDIT);
     this.modeEvent.emit(this.DisplayMode.EDIT);
   }

   onView() {
     this.setModeTemplate(this.DisplayMode.VIEW);
   }

   private scrollToFirstInvalidControl1() {
     // "form .ng-invalid"
     const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
       "mat-form-field-invalid"
     );

     firstInvalidControl.focus(); //without smooth behavior
  }

   private scrollToFirstInvalidControl() {
      const form = document.getElementById('objForm'); // <-- your formID
      const firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
      firstInvalidControl.scrollIntoView();
      (firstInvalidControl as HTMLElement).focus();
   }

   viewObj() {
      // console.log('view obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.VIEW );
      } else {
         this.viewObjDialog();
        }
   }

   createObj() {
      // console.log('create bj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.ADD );
      } else {
         this.createObjDialog();
        }
   }

   editObj(dontUseDialog=false) {
      // console.log('edit obj called. dontUseDialog=', dontUseDialog);
      if ( dontUseDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.mode = this.DisplayMode.EDIT;
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
        return;
      }
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.EDIT );
        this.selectObjEvent.emit(this.objData);
        this.editObjEvent.emit(this.objData);
      } else {
         this.editObjDialog();
        }
   }

   deleteObj() {
      // console.log('delete obj called.');
      if (! this.useMenuActionDialog ) {
        this.setModeTemplate( this.DisplayMode.DELETE );
      } else {
         this.deleteObjDialog();
        }
   }

   titleAction() {
      if ( this.mode === this.DisplayMode.SELECT ||
           this.mode === this.DisplayMode.LIST  ||
           this.mode === this.DisplayMode.EXPAND_LIST ) {
         this.setSelectedObj();
      }
   }

   toggleDetail() {
      switch(this.template) {
          case this.DisplayTemplate.EXPAND_LIST: {
             this.template = this.DisplayTemplate.DETAIL;
             this.showDetail = true;
             break;
          }
          case this.DisplayTemplate.DETAIL: {
             this.template = this.DisplayTemplate.EXPAND_LIST;
             this.showDetail = false;
             break;
          }
      }
   }

   cancelObj() {
      this.cancelEvent.emit(true);
      // console.log('Cancel');
   }

   prevObj() {
      this.prevEvent.emit(true);
      // console.log('prevObj');
   }

   nextObj() {
      this.nextEvent.emit(true);
      // console.log('nextObj');
   }

   setSelectedOrg(o) {
     this.selectedOrg = o;
     if ( this.objForm && o && o.uid) {
        this.objForm.patchValue( {org_uid: o.uid} );
        this.objForm.patchValue( {org_name: o.org_name} );
     }
   }

   setSelectedGroup(g) {
     this.selectedGroup = g;
     if ( this.objForm && g && g.uid) {
        this.objForm.patchValue( {groups_uid: g.uid} );
        this.objForm.patchValue( {group_name: g.name} );
     }
   }

   createForm() {

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // console.log('createForm snow=', snow);
     // console.log('createForm snm=', snm);
     console.log('createForm viewOnly=', this.viewOnly);
     // [{value: '', disabled: true}],
     this.objForm = this.fb.group({
           uid: [{value: '', disabled: true}],
           name: [{value: '', disabled: true}],
           description: this.viewOnly ?
                 [{value: '', disabled: true}] :
                 [''],
           org_uid: [{value: '', disabled: true}],
           org_name: [{value: '', disabled: true}],        
           groups_uid: [{value: '', disabled: true}],
           group_name: [{value: '', disabled: true}],
           users_uid: [{value: '', disabled: true}],
           user_email: [{value: '', disabled: true}],
           created_at: [{value: '', disabled: true}],
           timestamp: [{value: '', disabled: true}]
     },{updateOn: 'blur'});

     // console.log('createForm =', this.objForm);
   }

   resetForm() {
      this.setForm();
      this.objForm.markAsPristine();
      this.objForm.markAsUntouched();
   }

   setForm() {

     if(!this.objData || !this.objData.uid || this.objData.uid == null) {
        return;
     }

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date(now);
     nextMonth.setMonth(nextMonth.getMonth() + 1);

     let snow = now.toISOString();
     snow = snow.substring(0, snow.indexOf('.'));
     let snm = nextMonth.toISOString();
     snm = snm.substring(0, snm.indexOf('.'));

     // console.log('setForm snow=', snow);
     // console.log('setForm snm=', snm);
     // console.log('setForm viewOnly=', this.viewOnly);
     // console.log('setForm isOrgAdmin=', this.isOrgAdmin);
     // console.log('setForm objData:')
     // console.table(this.objData);

     this.objForm.patchValue({uid: this.objData.uid ? this.objData.uid : ''});
     this.objForm.patchValue({name: this.objData.name});
     this.objForm.patchValue({description:
                              this.objData.description});
     this.objForm.patchValue({org_uid: this.objData.org_uid ?
                                       this.objData.org_uid : null});
     this.objForm.patchValue({org_name: ''});
     this.objForm.patchValue({groups_uid: this.objData.groups_uid ?
                                          this.objData.groups_uid : null});
     this.objForm.patchValue({group_name: ''});
     this.objForm.patchValue({users_uid: this.objData.users_uid ?
                                         this.objData.users_uid : null});
     this.objForm.patchValue({user_email: ''});
     // this.objForm.patchValue({created_at: snow});
     // this.objForm.patchValue({timestamp: snow});
     if ( this.isAddMode && this.userData ) {
        this.objForm.patchValue({users_uid: this.userData.uid});
     }
     if ( this.objData ) {
        this.getObjFieldRefs(this.objData);
     }
   } // setForm()

   clearForm() {

     this.submitted = false;

     const now = new Date();
     const nextMonth = new Date().setMonth(now.getMonth() + 1);

     this.objForm.patchValue(
        {  uid: null,
           name: '',
           description: '',        
           org_uid: null,
           org_name: '',
           groups_uid: null,       
           user_uid: null,
           user_email: '',
         });
     if ( this.isAddMode && this.userData ) {
        this.objForm.patchValue({users_uid: this.userData.uid});
     }
   }

   loadDocument(d) {
     this.selectObjEvent.emit(d);
   }

   async addRecord( form ) {
      // console.log('group addRecord:', form.getRawValue());
      const data = form.getRawValue();
      delete data.uid;
      delete data.org_name;
      delete data.group_name;      
      delete data.user_email;
      delete data.created_at;
      delete data.timestamp;
      delete data.users_uid;
      if (data.org_uid === '') {
        data.org_uid = null;
      }
      // console.log('group addRecord data:', data);
      await this.addObjData( data );
   }

   async editRecord( form ) {
      const uid = form.getRawValue().uid;
      const data = form.getRawValue();
      const data_keys = Object.keys(data);
      console.log('editRecord data_keys=', data_keys);
      for (const k of data_keys) {
         console.log('editRecord k=', k);
         if ( k !== 'description' ) {
            delete data[k];
         }
       }
      console.log('group editRecord data=', data );
      await this.editObjData( uid, data );
   }

   async deleteRecord(obj) {
      if ( obj ) {
        // console.log('group deleteRecord:', obj);
        await this.deleteObjData( obj );
      }
   }

   refreshList(obj) {
      // console.log('refreshList obj=', obj);
      this.refreshEvent.emit(obj);
   }

   /****
   async getOrgUserInfo(oid, uid) {
      let result = null;
      try {
         result = await this.orgusersvc.getOrgUserByOrgAndUidPromise(oid, uid);
      } catch (e)  {
          console.error('org - cant get orguserinfo e=', e);
      }
      return result;
   }
   ***/

   async addObjData( data ) {
      /**** MUST USE UPLOAD FORM   
      let result = null;
      try {
          console.table( 'add group data =', data );
          result = await this.groupsvc.addGroupPromise( data );
          this.submitEvent.emit(result);
          this.getObjFieldRefs(result);
          this.toast.pop('success', 'Add Group', 'Group Saved');
          // this.getObjFieldRefs(result);
          console.table( 'add group result =', result );
      } catch (e)  {
           console.error('org - cant add group e=', e);
           this.toast.pop('error', 'Add Group', 'Add Group Failed! e=', e);
      }
      // console.log('add group data=', result);
      return result;
      ****/
   }

   async editObjData( uid, data ) {
      let result = null;
      try {
         // console.table( 'edit group data =', data );
         result = await this.documentsvc.updateDocumentData(uid, data);
         this.setObjData(result);
         this.setSelectedObj();
         this.submitEvent.emit(result);
         this.getObjFieldRefs(result);
         console.table( 'edit group file Info result =', result );
         this.toast.pop('success', 'Update Group File Info', 'Group File Info Updated');
      } catch (e)  {
           console.error('org - cant edit orgdata e=', e);
           this.toast.pop('error', 'Update Group File Info ', 'Group File Info Update Failed! e=', e);
      }
      // console.log('edit group data=', result);
      return result;
   }

   async deleteObjData( obj ) {
      let result = null;
      try {
         // console.table( 'delete group uid =', data );
         result = await this.documentsvc.removeDocument(obj.uid);
         this.deleteObjEvent.emit(result);
         console.table( 'delete group result =', result );
         this.toast.pop('success', 'Delete Group File', 'Deleted Group File', obj.name);
         if ( this.selectedObj && this.selectedObj.uid === this.objData.uid ) {
            this.selectObjEvent.emit( null );
         }
      } catch (e)  {
           console.error('group - cant delete error e=', e);
           this.toast.pop('error', 'Delete Group File', 'Delete Group File Failed! for ', obj.name);
      }
      return result;
   }

   viewObjDialog() {
      this.openFormDialog( this.DisplayModel.GROUP, this.DisplayMode.VIEW,
                           this.objData);
   }

   createObjDialog() {
      this.openFormDialog( this.DisplayModel.GROUP, this.DisplayMode.ADD,
                           this.objData);
   }

   editObjDialog() {
      this.openFormDialog( 'GRP', 'E', this.objData);
   }

   deleteObjDialog() {
      this.openDeleteGroupFileDialog( this.objData );
   }

   openDeleteGroupFileDialog(d) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      // Pass the seletected group data
      const grpData = {
         fileUID: d.uid,
         fileName: d.name
         };

      const msg = 'Are you sure you want to permanently delete group file: ' +
                   d.name + '?';
      const msg2 = 'Note: All group documents will also be removed.';

      dialogConfig.data = {
         intent: 'deleteGroup',
         title: 'Delete Group File' + d.name,
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Delete',
         dialogData: grpData
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open( ActionPromptDialogComponent, dialogConfig);
      // Callback after intent button clicked
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               /***
               console.log('deleteGroupFile action-prompt intent=',
                            data.intent);
               console.log('deleteGroupFile action-prompt return=',
                            data.choice);
               console.log('deleteGroupFile action-prompt return=',
                            data.dialogData);
               ***/
            }
            // if data returned tax default action
            this.deleteObjData( this.objData );
          } // data
      );
    } // openDeleteFileDialog

   openFormDialog(model, mode, grpData) {
      const dialogConfig        = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      let title = 'Group File';
      switch (mode) {
        case this.DisplayMode.VIEW:
              title = 'View Group File Info';
              break;
        case this.DisplayMode.ADD:
              title = 'Upload Group File';
              break;
        case this.DisplayMode.EDIT:
              title = 'Edit Group File Info';
              break;
        case this.DisplayMode.DELETE:
              title = 'Delete Group File';
              break;
      }

      dialogConfig.data = {
         userData: this.userData,
         objModel: this.DisplayModel.GROUP_FILE,
         objMode: mode,
         objData: grpData,
         isObjAdmin: this.isObjAdmin,
         dialogTitle: title,
         /****
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

      // Open action prompt dialog (delete group intent)
      this.formDialogRef = this.formDialog.open( FormDialogComponent, dialogConfig);
      // Callback after intent button clicked
      // let intent = null;
      // let choice = null;
      let returnData = null;
      this.formDialogRef.componentInstance.saveEvent.subscribe(
         data => {
            if (data) {
               // intent = data.intent;
               // choice = data.choice;
               returnData = data.objData;
               console.log('formDialog return=',
                            data.objData);
            }
            // if data returned tax default action
            // this.deleteObjData( this.objData );
            // this.groupSelect(this.objData);
            setTimeout(() => {
               this.refreshEvent.emit();
            }, 1100);
            if ( returnData ) {
               this.objData = returnData;
               this.setSelectedObj();
               this.selectObjEvent.emit(this.objData);
               this.selectGroupEvent.emit(this.objData);
            }
          } // data
      );
    } // openGroupAddFormDialog

} // document

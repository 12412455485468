import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { ChangeDetectorRef, ChangeDetectionStrategy, Component, OnInit,
         Input, Output, ViewChild, AfterViewInit,
         ViewContainerRef, ViewChildren, Inject, EventEmitter, ElementRef,
         OnChanges, SimpleChanges, OnDestroy, QueryList } from '@angular/core';
import { MatDialogModule, MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';

import { ActionPromptDialogComponent } from '../dialog/action-prompt/action-prompt.dialog';
import { MatSort } from '@angular/material/sort';
import { ToasterService } from '../service/toaster.service';
// import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';

// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DocumentData } from '../document/model/documentdata.model';
import { GroupData } from '../group/model/groupdata.model';
import { GroupMember } from '../group/model/groupmember.model';
import { NoteData } from '../model/notedata.model';
import { MarkerData } from '../model/markerdata.model';
import { MarkerComment } from '../model/markercomment.model';
import { MarkerApproval } from '../model/markerapproval.model';

// Still needed?
// import { GroupMemberListComponent } from '../group/group-member-list/group-member-list.component';

import { AuthService } from '../service/auth.service';
import { MarkerService } from '../service/marker.service';
import { UserService } from '../service/user.service';
import { DocumentService } from '../service/document.service';
import { GroupMemberService } from '../service/group-member.service';
import { HyperlinkService } from '../service/hyperlink.service';
import { PlatformService } from '../service/platform.service';

import { UserData } from '../model/userdata.model';
import { UserInfo } from '../model/userinfo.model';

import { cleanHtml } from '../mdtools/htmltools.common';
// import { MDToolsModule } from '../mdtools/mdtools.module';


import * as MarkerDialog from './marker-dialog.component'
import * as PLATFORM from '../service/platform.service';

import * as HELP from '../service/help.service';

export const TYPE_UNKNOWN = 0;
export const TYPE_QUESTION = 1;
export const TYPE_COMMENT = 2;
export const TYPE_ISSUE = 3;
export const TYPE_APPROVAL = 4;

export const TEMPLATE_CLOSED = 0;
export const TEMPLATE_NEW_MARKER = 1;
export const TEMPLATE_EDIT_DESCRIPTION = 2;
export const TEMPLATE_ADD_COMMENT = 3;
export const TEMPLATE_DELETE_MARKER = 4;
export const TEMPLATE_DELETE_COMMENT = 5;
export const TEMPLATE_NEW_APPROVAL = 6;
export const TEMPLATE_EDIT_APPROVAL = 7;
export const TEMPLATE_ADD_APPROVAL_COMMENT = 8;
export const TEMPLATE_EDIT_APPROVAL_REQUESTS = 9;
export const TEMPLATE_CONFIRM_SCOPE = 10;
export const TEMPLATE_SHOW_APPROVAL_STATUS = 11;

export const APPROVAL_RESPONSE_PENDING = 'P';
export const APPROVAL_RESPONSE_YES = 'Y';
export const APPROVAL_RESPONSE_NO = 'N';

export const APPROVAL_TYPE_DOCUMENT = 'D';
export const APPROVAL_TYPE_PAGE = 'P';
export const APPROVAL_TYPE_TEXT = 'T';
export const APPROVAL_TYPE_AREA = 'A';

@Component({
   selector: 'app-marker-dialog',
   templateUrl: './marker-dialog.component.html',
   styleUrls: ['./marker-dialog.component.css'],
//   animations: [
//      trigger('detailExpand', [
//         state('collapsed', style({ height: '0px', minHeight: '0' })),
//         state('expanded', style({ height: '*' })),
//         transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
//      ]),
//   ],
})
export class MarkerDialogComponent implements OnInit, OnDestroy, AfterViewInit {

   private nData: NoteData;
   private DOCUMENT_NAME = '';

//   @Input()  set noteData(val: NoteData) {
      // console.log('previous noteData = ', this.NOTE_DATA);
//      // console.log('current noteData =', val);
//      this.nData = val;
//      this.noteDataChange(this.nData);
//   }

//   get noteData(): NoteData {
//      return this.nData;
//   }

   @Input() noteData: NoteData;

   @Input()  set usrInfo(val: UserInfo) {
      // console.log('previous noteData = ', this.NOTE_DATA);
      // console.log('current userInfo =', val);
      this.setUserInfo(val);
   }

   @Input()  set documentName(val: string) {
      console.log('previous documentName = ', this.DOCUMENT_NAME);
      console.log('current documentName =', val);
      this.DOCUMENT_NAME = val;
   }
   get documentName(): string {
      return this.DOCUMENT_NAME;
   }

   @Input()  type: number;
   @Input()  template: number;

   @Input()  orgUID: string;
   @Input()  groupUID: string;
   @Input()  documentUID: string;

   @Output() quitEvent = new EventEmitter<any>();
   @Output() cancelEvent = new EventEmitter<any>();
   @Output() removeEvent = new EventEmitter<any>();
   @Output() linkEvent = new EventEmitter<any>();
   @Output() linkCommentEvent = new EventEmitter<any>();
   @Output() handleInternalLinkEvent = new EventEmitter<any>();
   @Output() submitEvent = new EventEmitter<any>();
   @Output() updateEvent = new EventEmitter<any>();
   @Output() approvalEvent = new EventEmitter<any>();
   @Output() requestEvent = new EventEmitter<any>();
   @Output() scrollToNoteEvent = new EventEmitter<any>();
   @Output() scrollToPageEvent = new EventEmitter<any>();
   @Output() displayUserInfoEvent = new EventEmitter<any>();
   @Output() getPdfEvent = new EventEmitter<any>();
   @Output() selectTextEvent = new EventEmitter<any>();
   @Output() selectBoxEvent = new EventEmitter<any>();
   @Output() helpEvent = new EventEmitter<any>();

   @ViewChild('appMarkerDialog', {static: false}) appMarkerDialog: ElementRef;
   @ViewChild('descrText', {static: false}) descrText: ElementRef;
   @ViewChild('commentText', {static: false}) commentText: ElementRef;
   @ViewChild('grpMemberList', {static: false}) grpMemberList: MatSelectionList;

   approvalTypeDocument = APPROVAL_TYPE_DOCUMENT;
   approvalTypePage = APPROVAL_TYPE_PAGE;
   approvalTypeText = APPROVAL_TYPE_TEXT;
   approvalTypeArea = APPROVAL_TYPE_AREA;

   iconPathIssue = '/assets/issue_icon.png';
   iconPathQuestion = '/assets/question_icon.png';
   iconPathComment = '/assets/note_icon.png';
   iconPath = '/assets/issue_icon.png';

   // help context
   helpContext = HELP.MARKER_TOOL;

   // Dialog Reference
   actionPromptDialogRef: any = null;

   // Service Messaging Subscription
   markerSubscription: Subscription;

   userInfo: UserInfo;
   userUID = '';

   userApprovalRequested = false;
   userApprovalRequest = null;
   userApprovalResponse = APPROVAL_RESPONSE_PENDING;

   groupList: GroupData[] = [];

   markerDocID = null;
   markerUUID = null;

   id = '';
   dialogId = '';

   className = '';
   position = 'absolute';
   width = 32;
   height = 40;
   top = 0;
   left = 0;
   margin = 0;
   padding = 0;
   border = 0;
   backgroundColor = '#FDFFFC';
   borderTopRightRadius = 8;
   zIndex = '10000';

   itemType = 'question';
   itemDisplay = 'question';
   itemData: MarkerData;
   itemDocUID: null;
   itemDocName: '';

   title = '';
   description = '';
   commentData: any;
   comment;
   owner = '';
   created: any;
   name = '';

   // Confirm Dialog Variables
   confirmed = false;
   confirmTitle = 'Confirm';
   confirmMessage1 = 'Are you sure?';
   confirmMessage2 = '';
   lastTemplate = 1;
   oldApprovalType = MarkerDialog.APPROVAL_TYPE_DOCUMENT;
   newApprovalType = MarkerDialog.APPROVAL_TYPE_DOCUMENT;

   // Response Types
   approvalsPending = MarkerDialog.APPROVAL_RESPONSE_PENDING;
   approvalsYes = MarkerDialog.APPROVAL_RESPONSE_YES;
   approvalsNo = MarkerDialog.APPROVAL_RESPONSE_NO;

   // Template
   templateNewMarker = MarkerDialog.TEMPLATE_NEW_MARKER;
   templateEditDescription = MarkerDialog.TEMPLATE_EDIT_DESCRIPTION;
   templateAddComment = MarkerDialog.TEMPLATE_ADD_COMMENT;
   templateNewApproval = MarkerDialog.TEMPLATE_NEW_APPROVAL;
   templateEditApproval = MarkerDialog.TEMPLATE_EDIT_APPROVAL;
   templateAddApprovalComment = MarkerDialog.TEMPLATE_ADD_APPROVAL_COMMENT;
   templateEditApprovalRequests = MarkerDialog.TEMPLATE_EDIT_APPROVAL_REQUESTS;
   templateDeleteMarker = MarkerDialog.TEMPLATE_DELETE_MARKER;
   templateDeleteComment = MarkerDialog.TEMPLATE_DELETE_COMMENT;
   templateConfirmScope = MarkerDialog.TEMPLATE_CONFIRM_SCOPE;
   templateShowApprovalStatus = TEMPLATE_SHOW_APPROVAL_STATUS;
   
   // Style Variables
   markerStyle = 'width: 32px; height: 40px;';

   // View vars
   /* Main MARKERS toggle */
   showRequests = false;
   showResponses = false;
   showEditResponse = false;

   // Instance vars
   groupName: string;

   loading: boolean;

   // group member info
   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;
   selectedDocUID;
   selectedDocName;
   selectedGroupObj: any;

   selectedComment: number = null;

   selectAllMembers = false;

   linkSubscription: any;
   approvalSubscription: any;
   textSubscription: any;

   clDataList: any[];

   activeDocument: any;
   activeDocumentData: DocumentData = null;

   groupMembers: GroupMember[] = [];

   constructor(
      private auth: AuthService,
      private markersvc: MarkerService,
      private usersvc: UserService,
      private documentsvc: DocumentService,
      private groupmembersvc: GroupMemberService,
      private platformsvc: PlatformService,
      private toast: ToasterService,
      private hyperlinksvc: HyperlinkService,
      private cd: ChangeDetectorRef,
      public actionPromptDialog: MatDialog,
      ) {
          this.activeDocument = this.documentsvc.activeDocument;
      } // constructor

   ngOnInit() {
      // console.log('Marker userInfo=', this.userInfo);
      this.comment = '';
      this.title = '';
      this.clDataList = [];

      if (this.type) {
        this.helpContext = HELP.MARKER_TOOL;
        switch(this.type) {
           case MarkerDialog.TYPE_QUESTION:
                this.itemDisplay = "Question";
                break;
           case MarkerDialog.TYPE_COMMENT:
                this.itemDisplay = "Comment";
                break;
           case MarkerDialog.TYPE_ISSUE:
                this.itemDisplay = "Issue";
                break;
           case MarkerDialog.TYPE_APPROVAL:
                this.itemDisplay = "Approval";
                this.helpContext = HELP.APPROVAL_REQUEST;
                break;
           default:
                this.itemDisplay = "Add Marker";
                break;
        }
      }
   }

  async ngAfterViewInit() {
//      let width = this.appMarker.nativeElement.offsetWidth;
//      let height = this.appMarker.nativeElement.offsetHeight;
//        console.log('srcWidth:' + width);
//        console.log('template=' + this.template);
//        this.scrollToNoteEvent.emit('detail-container');
        // this.scrollToDialog('detail-container');
  }

  ngOnDestroy() {
     // unsubscribe to ensure no memory leaks
     if (this.linkSubscription) {
        this.linkSubscription.unsubscribe();
     }
     if (this.approvalSubscription) {
        this.approvalSubscription.unsubscribe();
     }
     if (this.textSubscription) {
        this.textSubscription.unsubscribe();
     }
  }

   // noteData change handler
   //
  noteDataChange(n) {
      // console.log('Marker noteData Change n = ', n);

      // console.log('MLC noteData Change noteData = ', this.noteData);
      // console.log("markerlist=", this.markerList);

  }

  async displayUserInfo(uuid: string) {
     let mbrInfo = '';
     //console.log('MLC uuid=', uuid);
     try{
        mbrInfo = await this.usersvc.getUserInfo(uuid);
        //console.log('MLC mbrInfo=', mbrInfo);
     } catch (e) {
        console.error('MLC error retrieving userInfo for uuid=' + uuid +
                    'e=' + e);
       }
     this.displayUserInfoEvent.emit(mbrInfo);
  }

  getCommentCount(m): number {
     if (m.comments && Array.isArray(m.comments)) {
         return m.comments.length;
     } else {
          return 0;
       }
  }

  getDate(m): string {
     let utcSeconds = 0;
     let dt   = '';
     if (m?.created_at) {
           utcSeconds = m.created_at;
           const d = new Date(0);
           d.setUTCSeconds(utcSeconds);
           dt = d.toISOString();
     }
     return dt;
  }

  scrollToPage(e) {
    console.log('ml: scroll to page=', e);
      this.scrollToPageEvent.emit(e);
  }

  scrollToNote(e) {
    console.log('ml: scroll to note=', e);
    this.scrollToNoteEvent.emit(e);
  }

  // SideNav functions
  //

  onClick(e) {
     console.log("marker onClick e=", e);
     // this.iconPath = this.iconPathQuestion;
     window.alert('marker clicked.');
  }

  setUserInfo(data: UserInfo) {
    this.userInfo = data;
    this.userUID = data.uid;
  }
  getUserInfo(): UserInfo {
    return this.userInfo;
  }

  setTemplate(t) {
     this.template = t;
  }
  getTemplate() {
     return this.template;
  }

  setItemData(data, setTemplate=true) {
     this.itemData = data;
     this.cd.detectChanges();
     //console.log("md owner=[" + this.itemData.owner + "]");
     //console.log("md itemData=", this.itemData);

     if ( setTemplate ) {
       if ( this.itemData.owner === '' || this.itemData.owner == null) {
          if ( this.itemData.type === 'approval' ) {
               this.setTemplate(MarkerDialog.TEMPLATE_NEW_APPROVAL);
          }  else {
               this.setTemplate(MarkerDialog.TEMPLATE_NEW_MARKER);
            }
       } else {
            if ( this.itemData.type === 'approval' ) {
               this.setTemplate(MarkerDialog.TEMPLATE_ADD_APPROVAL_COMMENT);
            } else {
                this.setTemplate(MarkerDialog.TEMPLATE_ADD_COMMENT);
              }
         }
     }

     if ( this.itemData.uuid ) {
       this.markerUUID = this.itemData.uuid;
     }
     this.userApprovalRequest = this.getUserApproval( this.userInfo.email );
  }

  async setGroupList(glist) {
     this.groupList = glist;
     //console.log('mdialog groupList=', this.groupList);
  }

  async setActiveDocument(duid) {
     this.activeDocument = duid;
     this.activeDocumentData = await this.documentsvc.getDocumentData(duid);
     //console.log('mdialog activeDocumentData=', this.activeDocumentData);
     if (this.itemData.type === 'approval') {
       this.groupMembers = await this.groupmembersvc.getGroupMembers(this.activeDocumentData.groups_uid);
       //console.log('mdialog groupMembers=', this.groupMembers);
     }
  }

  setTitle(t) {
     if (this.itemData) {
        this.itemData.title = t;
     }
  }
  setDescription(d) {
     if (this.itemData) {
        this.itemData.description = d;
        this.description = d;
     }
  }

  addComment(c: string) {
     // clean the comment html
     const str = cleanHtml(c);
     const cmt = new MarkerComment();
     cmt.comment = str;
     cmt.owner = this.userInfo.email;
     cmt.created_at = Date.now();
     this.itemData.comments.push( {
        owner: this.userInfo.email,
        comment: c,
        created_at: Date.now()
     });
     // DONT DO THIS, WILL MESS UP USER LAYER
     // this.itemData.uid = this.userInfo.uid;
  }

  getItemData(): MarkerData {
     return this.itemData;
  }

  handleLink(linkData) {
     const link = linkData.link;
     const cldata = linkData.cldata;
     if ( cldata !== null ) {
        this.clDataList.push(cldata);
     }
     //console.log('mdc clDataList=', this.clDataList);
     const pos = this.descrText.nativeElement.selectionEnd;
     let val = this.descrText.nativeElement.value;
     //console.log('pos=', pos);
     //console.log('val=', val);
     if ( pos !== null && val !== null ) {
        const output = val.substring(0, pos) + link + val.substring(pos);
        val = output;
        //console.log('output=', output);
        this.itemData.description = output;
     }
     // NOTE: MAY WANT TO HANDLE CROSS LINK HERE
     // By sending markerUUID to service function
     //
     if (this.linkSubscription) {
        this.linkSubscription.unsubscribe();
     }
  }

  handleCommentLink(linkData) {
     const link = linkData.link;
     const cldata = linkData.cldata;
     if ( cldata !== null ) {
        this.clDataList.push(cldata);
     }
     //console.log('mdc clDataList=', this.clDataList);
     const pos = this.commentText.nativeElement.selectionEnd || 0;
     if ( pos === 0 || this.comment === '' || this.comment === null) {
        this.comment = ' ' + link;
     } else {
         const val = this.comment;
         //console.log('pos=', pos);
         //console.log('val=', val);
         if ( pos !== null && val !== null ) {
            const output = val.substring(0, pos) + link + val.substring(pos);
            this.comment = output;
            //console.log('link cmt output=', output);
         }
    }
    if (this.linkSubscription) {
        this.linkSubscription.unsubscribe();
    }
  }

  select(e) {
     console.log('select e=', e);
  }

  emitCancel(e) {
//     window.alert('marker clicked.');
     this.cancelEvent.emit(this.itemData);
  }

  emitRemove(e) {
//     window.alert('marker remove clicked.');
     this.removeEvent.emit(this.itemData);
     this.template = this.lastTemplate;
  }

  emitEdit(e) {
//     window.alert('marker remove clicked.');
//     this.removeEvent.emit(this.itemData);
     this.template = this.templateEditDescription;
  }

  emitHelp(e) {
     this.helpEvent.emit(e);
  }

  emitLink(e) {
     const linkData = {
        "type": MarkerDialog.APPROVAL_TYPE_DOCUMENT,
        "item": this.itemData,
        "srcDocUID": this.itemDocUID,
        "srcDocName": this.itemDocName,
        "srcNoteID": this.itemData.uuid
     };


//     this.template = MarkerDialog.TEMPLATE_ADD_COMMENT;
//     this.linkSubscription = this.hyperlinksvc.getHyperlinkUpdates().subscribe({
//         next(link) {
//          console.log('got link observable=', link);
//            console.log('2-descrText=', this.descrText);
//          this.handleLink(link);
//       }
//     });
     this.linkSubscription = this.hyperlinksvc.getHyperlinkUpdates().subscribe(
       updatedLink => {
         //console.log('got link observable=', updatedLink);
         this.handleLink(updatedLink);
       }
     );

     this.linkEvent.emit(this.itemData);

     // this.hyperlinksvc.sendHyperlinkUpdate('Yahoo!', 'http://www.yahoo.com');
  }

  emitCommentLink(e) {
     const linkCommentData = {
        "type": 'C',
        "item": this.itemData,
        "itemDocUID": this.itemDocUID,
        "itemDocName": this.itemDocName
     };

     //console.log('comment=', this.comment);
     this.linkSubscription = this.hyperlinksvc.getHyperlinkUpdates().subscribe(
       updatedLink => {
         //console.log('got link observable=', updatedLink);
         this.handleCommentLink(updatedLink);
       }
     );
     this.linkCommentEvent.emit(this.itemData);     

     // this.hyperlinksvc.sendHyperlinkUpdate('Yahoo!', 'http://www.yahoo.com');
  }

  emitSelectText(e) {
     this.selectTextEvent.emit(this.itemData);
  }

  handleCrossLinks(newItem: boolean) {
    console.log('handleCrossLinks newItem=', newItem);
    this.clDataList.forEach( ( cl, index) => {
       console.log('mdc handleCrossLinks cl=', cl);
       this.hyperlinksvc.addCrossLinkComment(
       cl.origin, cl.title, cl.duid, cl.muid, cl.srcdocuid, cl.srcdata);
       // *** Note - need to save marker here or somewhere first!
       // this.openConfirmCrossLink( cl, newItem );
       this.createCrossLink(cl, true, newItem);
    });
  }

  createCrossLink(cldata, createLink: boolean, newItem: boolean ) {
     console.log('mdc createCrossLink clDataList=', this.clDataList);    
     console.log('mdc createCrossLink cldata=', cldata);  
     console.log('mdc createCrossLink create=', createLink);
     console.log('mdc createCrossLink newItem=', newItem);
     if( createLink ) {
       this.hyperlinksvc.addCrossLinkComment(
          cldata.origin, cldata.title, cldata.duid, cldata.muid,
          cldata.srcdocuid, cldata.srcdata);
     }
     this.clDataList.forEach( ( cl, index) => {
        if( cl === cldata ) {
           this.clDataList.splice( index, 1 );
        }
     });
     /****
     if ( this.clDataList.length === 0 ) {
       if ( newItem ) {
          this.submitEvent.emit(this.itemData);
       } else {
            this.updateEvent.emit(this.itemData);
         }
     }     
     ****/
     if ( newItem ) {
       this.submitEvent.emit(this.itemData);
     } else {
         this.updateEvent.emit(this.itemData);
       }
  }

  emitSubmit(e) {
//     window.alert('marker link clicked.');
//     this.setTitle(this.title);
//     this.setDescription(this.description);
     const str = cleanHtml(this.itemData.description);
     this.itemData.description = str;
     if( this.clDataList.length > 0 ) {
        this.handleCrossLinks(true);
     } else {
         this.submitEvent.emit(this.itemData);
       }
  }

   submitComment(e) {
     this.addComment(this.comment);
     console.log('submitComment clDataList=', this.clDataList);
     if( this.clDataList.length > 0 ) {
        // updateEvent will create the crosslink..
        // this.updateEvent.emit(this.itemData);     
        this.handleCrossLinks(false);
     } else {
         this.updateEvent.emit(this.itemData);
       }
   }

   submitEdit(e) {
     const str = cleanHtml(this.itemData.description);
     this.itemData.description = str;
     console.log('submitEdit desc =', this.itemData.description);
     if( this.clDataList.length > 0 ) {
        this.handleCrossLinks(false);
     } else {
         this.updateEvent.emit(this.itemData);
       }
   }

   scrollToDialog(id) {
         setTimeout(() => {
           if (this.platformsvc.OS === 'ios' || this.platformsvc.OS === 'macos' ){
             document.getElementById(id).scrollIntoView(true);
           } else {
               document.getElementById(id).scrollIntoView({block: 'start', inline: 'start'});
             }
        }, 100);
   }

   removeComment(e) {
      //console.log('mdc remove comment e=', e);
      //console.log('mdc remove comment index=', this.selectedComment);
      // Delete the comment in the itemData
      this.itemData.comments.splice(this.selectedComment, 1);
      // emit update to save itemData
      this.updateEvent.emit(this.itemData);
      this.template = this.lastTemplate;
   }


  showApprovalRequests(e) {
     //console.log('md showAprovalRequests');
     // this.requestEvent.emit(this.itemData);
     this.showRequests = true;
     this.lastTemplate = this.template;
     this.template = this.templateEditApprovalRequests;
  }

  hideApprovalRequests() {
     // this.updateEvent.emit(this.itemData);
     console.log('hide ar itemData=', this.itemData);
     this.showRequests = false;     
     if ( this.itemData.uuid && this.itemData.uuid !== null &&
          this.itemData.uuid.length > 0 ) {
            this.requestEvent.emit(this.itemData);
            this.template = this.lastTemplate;
     } else {
         this.submitEvent.emit(this.itemData);
       }
  }

  showApprovalStatus(e) {
     //console.log('md showAprovalStatus');
     this.lastTemplate = this.template;
     this.template = this.templateShowApprovalStatus;
  }

  hideApprovalStatus() {
     this.template = this.lastTemplate;
  }

// MOVED TO MAIN
/****
   async updateUserLayer() {
     console.log('updateUserLayer =', this.itemData);

     if (this.documentsvc.activeDocument) {

        const indLayerData: NoteData = {
            note: this.nData.note.filter((f:MarkerData) => f.owner === this.itemData.owner),
            question: this.nData.question.filter((f:MarkerData) => f.owner === this.itemData.owner),
            issue: this.nData.issue.filter((f:MarkerData) => f.owner === this.itemData.owner),
            approval: this.nData.issue.filter((f:MarkerData) => f.owner === this.itemData.owner)
        };
//       console.log('updateComments indLayerData=', indLayerData);

        ['note', 'question', 'issue', 'approval'].forEach((ntype) => {
           indLayerData[ntype].forEach((note, index) => {
              note.markerRef = null;
              note.dialogRef = null;
           });
        });

        const data = JSON.stringify({
            layer_data: indLayerData,
            doc_orientation: this.documentsvc.pdfRotation
        });

        //console.log('LAYER: ', this.itemData.uid);

        //  Update the user layer data with the modified item
        await this.markersvc.updateComments(this.itemData.uid, data);

        // Emit to send notification and remove dialog
        this.updateEvent.emit(this.itemData);
     }
  }
 ****/

  setClickLinkHandlers() {
     // Sets up the hyperlink interceptors for the marker content
     //
     //console.log('finding element for id=', this.id);
     const dialog = document.getElementById(this.id);
     //console.log('setting click handlers for:', dialog);
     const intLinks = dialog.getElementsByTagName('a');
     //console.log('intLinks=', intLinks);
     for( let i=0; i < intLinks.length; i++) {
        //console.log('intLinks[i]=', intLinks[i]);
//        this.render.listen(intLinks[i], this.linkIntercept, false);
        intLinks[i].addEventListener('click', this.linkIntercept.bind(this), false);
     }
//     const aLinks = dialog.getElementsByTagName('A');
//     for( let i=0; i < aLinks.length; i++) {
//        console.log('aLinks[i]=', aLinks[i]);
//        this.render.listen(aLinks[i], this.linkIntercept, false);
//        aLinks[i].addEventListener('click', this.linkIntercept.bind(this), false);
//     }
  }

  linkIntercept( _event ) {
     // Interceptor for hyperlinks contained in note marker text.
     // Important or will navigate to link without pre-processing.
     _event.preventDefault();
     _event.stopPropagation();
     const tEvent = _event || window.event;
     const ele = tEvent.target || tEvent.srcElement;
     //console.log('handleClickLink evt=', tEvent);
     //console.log('handleClickLink ele=', ele);
     //console.log('handleClickLink tag=', ele.tagName);

     const href = ele.getAttribute('href');
     let target = ele.getAttribute('target');
     const linkClass = ele.className;

     //console.log('linkIntercept href=', href);
     //console.log('linkIntercept target=', target);
     //console.log('linkIntercept class=', linkClass);

     if ( href === null ) {
       return;
     }

     if ( ( target === undefined ) ||
          ( target === null )      ||
          ( target === '' )        ||
          ( target !== '_blank' ) ) {
          target = '_top';
          //console.log('target=', target);
     }

     //console.log('marker-dialog linkIntercept href=', href);
     const url = href.split("?")[0];
     let server = url.split("//")[1];
     if ( server ) {
       server = server.replace('/','');
     } else {
         server = url.split("//")[0];
     }
     const paramsBlock = href.split("?")[1];

     /****
     let paramsMap = paramsBlock.split('&').reduce((p, c) => {
          let components = c.split('=');
          p[components[0]] = components[1]
          return p;
     }, new Map<string, string>());

     let docid = paramsMap.get("docid");
     let noteid = paramsMap.get('noteid');
     let page = paramsMap.get('page');

     //console.log("map=", paramsMap);
     const str = JSON.stringify( paramsMap );
     //console.log("str=", str);
     ****/

     //console.log('paramsBlock=', paramsBlock);

     let argsBlock;

     if ( paramsBlock ) {
        argsBlock = paramsBlock.split('&');
     }

     const origin = window.location.origin;

     let docid = '';
     let noteid = '';
     let page = '';

     if ( argsBlock ) {
       for (let i=0; i < argsBlock.length; i++) {
         const map = argsBlock[i].split('=');
         if ( map && map[0] === 'docid' && map[1] ) {
            docid = map[1];
         }
         if ( map && map[0] === 'noteid' && map[1] ) {
            noteid = map[1];
         }
         if ( map && map[0] === 'page' && map[1] ) {
            page = map[1];
         }
       }
     }
     //console.log('server=', server);
     //console.log('origin=', origin);

     //console.log('argsBlock=', argsBlock);
//     console.log('argVals=', argVals);
     //console.log('docid=', docid);
     //console.log('noteid=', noteid);
     //console.log('page=', page);
     //console.log('activeDocument=', this.documentsvc.activeDocument );

     const docobj = this.documentsvc.DocumentList.find(d => d.uid === docid);

     const linkData = {
        "docid": docid,
        "docobj": docobj,
        "noteid": noteid,
        "page": page,
        "server": server,
        "origin": origin,
        "href" : href
     }
     if ( docid && docid === this.documentsvc.activeDocument ) {
        // Internal link, same document
        //console.log('document link docid=', docid);
        if ( noteid ) {
           this.scrollToNote( noteid );
        }
        if ( page && !noteid ) {
           this.scrollToPage( page );
        }
     } else if ( server === 'markadoc' ) {
               const new_href = href.replace( server, origin );
               //console.log('internal link new_href=', new_href);
               this.handleInternalLinkEvent.emit(linkData);
// NOTE window methods with internal link ref will reload the app
//               window.open( new_href, target, 'noopener,noreferrer');
//               window.location.href = new_href;
            } else if ( server === 'markadoc-nt' ) {
                   //console.log('open md tab link data=', linkData);
                   this.openConfirmNewTab( linkData, 'mdTab' );
              } else {
                   //console.log('external link href=', href);
                   this.openConfirmExternalLink( href, 'extLink' );
                }

     return false;
  }

  public checkHref( $event )
  {
     //console.log( 'checkHref event=', $event);
     const origin = window.location.origin;

     let targetElement;

     if( ( $event.srcElement.nodeName.toUpperCase() === 'A') )
         targetElement = $event.srcElement;
     else if( $event.srcElement.parentElement.nodeName.toUpperCase() === 'A' )
              targetElement = $event.srcElement.parentElement;
          else
              return;

     //console.log( 'Found LINK:' );
     //console.log( targetElement.href );

     if( targetElement.href && !targetElement.href.includes(origin)) {
         console.log( "OUTBOUND LINK:" + $event.srcElement.href );
     }
  }

  openExternalLink(url: string) {
     //console.log('opening external link url=', url);
     window.open( url, '_blank', 'noopener,noreferrer');
  }

  openMarkadocNewTab(url: string) {
     //console.log('opening markadoc external link url=', url);
     window.open( url, '_blank', 'noopener,noreferrer');
  }

  validateExternalLink(url: string) {
     const scanURL = 'https://sitecheck.sucuri.net/results/' + url;
     //console.log('validate external link url=', url);
     window.open( scanURL, '_blank', 'noopener,noreferrer');
  }

  openConfirmExternalLink(url, intent) {
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      // Pass the seletected data
      const urlData = {
         "url": url
      }
      const msg = 'Are you sure you want to open this URL in another tab?';
      const msg2 = url;

      dialogConfig.data = {
         intent: intent,
         title: 'Open External URL',
         message: msg,
         message2: msg2,
         message1Color: 'red',
         button1Text: 'Yes',
         dialogData: url
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let returnIntent = null;
      let returnChoice = null;
      let returnData = null;
      this.actionPromptDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               returnIntent = data.intent;
               returnChoice = data.choice;
               returnData = data.data;
               console.log('action-prompt intent=',
                            data.intent);
               console.log('action-prompt choice=',
                            data.choice);
               console.log('action-prompt data=',
                            data.data);
            }
            // call openExternalURL here...
            //console.log( 'open link dialog returnChoice=', returnChoice);
            //console.log( 'open link dialog returnIntent=', returnIntent);
            //console.log( 'open link dialog returnData=', returnData);
            //console.log( 'open link dialog data=', data);
            switch( returnChoice ) {
               case 1:
                       this.openExternalLink( returnData );
                       break;
               case 2:
                       //console.log( 'switch choice=', returnChoice);
                       returnData.replace(/^(https?:|)\/\//, '');
                       this.validateExternalLink( returnData );
                       break;
            }
          } // data
      );
    }

  openConfirmNewTab(linkData, intent) {
      const dialogConfig        = new MatDialogConfig();

      const href = linkData.href;
      const doc = linkData.docobj;

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const msg = 'Are you sure you want to open this document in another browser tab?';
      const msg2 = doc.name;
      const msg3 = href;

      dialogConfig.data = {
         intent: intent,
         title: 'Open External URL',
         message: msg,
         message2: msg2,
         message1Color: 'red',
         button1Text: 'Open in a New Tab',
         button2Text: 'Open in This Tab',
         dialogData: linkData
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let returnIntent = null;
      let returnChoice = null;
      let returnData = null;
      this.actionPromptDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               returnIntent = data.intent;
               returnChoice = data.choice;
               returnData = data.data;
               //console.log('action-prompt intent=', data.intent);
               //console.log('action-prompt choice=', data.choice);
               //console.log('action-prompt data=', data.data);
            }
            // call openExternalURL here...
            //console.log( 'open link dialog returnChoice=', returnChoice);
            //console.log( 'open link dialog returnIntent=', returnIntent);
            //console.log( 'open link dialog returnData=', returnData);
            //console.log( 'open link dialog data=', data);
            switch( returnChoice ) {
               case 1: if ( returnIntent === 'mdTab' ) {
                         //console.log( 'newtab choice=', returnChoice);
                         //console.log( 'newtab choice=', linkData);
                         const new_href = href.replace( linkData.server, linkData.origin );
                         // this.openMarkadocNewTab( new_href );
                         this.openExternalLink( new_href );                                           }
                      break;
               case 2:
                      //console.log('internal link linkData=', linkData);
                      this.handleInternalLinkEvent.emit(linkData);
                      break;
            }
          } // data
      );
    }

  openConfirmCrossLink(cldata, newItem) {
      const intent = 'crosslink';
      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '95vw';
      dialogConfig.maxHeight    = '99vh';

      const msg = 'Add a cross link comment from ' + cldata.title +
                  'back to ' + cldata.srcdata.title;

      dialogConfig.data = {
         intent: intent,
         title: 'Create Cross Linking Comment?',
         message: msg,
         button1Text: 'Yes',
         dialogData: cldata
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after intent button clicked
      let returnIntent = null;
      let returnChoice = null;
      let returnData = null;
      this.actionPromptDialogRef.afterClosed().subscribe(
         data => {
            if (data) {
               returnIntent = data.intent;
               returnChoice = data.choice;
               returnData = data.data;
               //console.log('action-prompt intent=', data.intent);
               //console.log('action-prompt choice=', data.choice);
               //console.log('action-prompt data=', data.data);
            }
            // call openExternalURL here...
            //console.log( 'crosslink dialog returnChoice=', returnChoice);
            //console.log( 'crosslink dialog returnIntent=', returnIntent);
            //console.log( 'crosslink dialog returnData=', returnData);
            //console.log( 'crosslink dialog data=', data);
            switch( returnChoice ) {
               case 1:
                       console.log('openConfirmCrosslink returnChoice=', returnChoice);
                       this.createCrossLink( returnData, true, newItem );
                       // this.updateEvent.emit(this.itemData);
                       break;
               default:
                       console.log('openConfirmCrosslink returnChoice=', returnChoice);	       
                       // this.createCrossLink( returnData, false, newItem );
                       // this.updateEvent.emit(this.itemData);		       
                       break;
            }
       } // data
     );
   }


 // Approval handling functions

  emitEditApproval(e) {
//     window.alert('marker remove clicked.');
//     this.removeEvent.emit(this.itemData);
     this.template = this.templateEditApproval;
  }

  handleEditRequests(e) {
     this.template = MarkerDialog.TEMPLATE_EDIT_APPROVAL_REQUESTS;
  }


  onRequestSelected(e, v) {
     //console.log('request e=', e);
     //console.log('request v=', v);
     //console.log('request selected=', e.options);
     e.options.forEach( (mbr, i) => {
        //console.log('mbr.value=', mbr.value);
        //console.log('mbr.selected=', mbr.selected);
        if ( mbr.selected ) {
           // add request
           const x = this.addApprovalRequest( mbr.value.email, mbr.value.uid );
           console.log('added request for ' + mbr.value.email + ' x=', x);
           //console.log('approvals=', this.itemData.approvals);
        } else {
             // remove request
             const y = this.removeApprovalRequest( mbr.value.email );
           console.log('removed request for ' + mbr.value.email + ' y=', y);
             //console.log('approvals=', this.itemData.approvals);
          }
     });
     //v.forEach( (item, i) => {
     //   console.log('item.value=', item.value);
     //   console.log('item.selected=', item.selected);
     //});
     //console.log('request items=', this.grpMemberList.selectedOptions.selected);
  }

   getResponseCount(type: string): number {
      let cnt = 0;
      if( ! this.itemData.approvals || this.itemData.approvals.length === 0) {
         return cnt;
      } else {
           this.itemData.approvals.forEach( (ap, i) => {
              if ( ap.response === type ) {
                 cnt++;
              }
           });
           return cnt;
        }
   }

   approvalRequestCount(): number {
      if( ! this.itemData.approvals ) {
          // itemData.approvals does not exist error
          console.error('approvalRequestExists itemData.approvals is null or undefined or empty');
          return 0;
      } else {
          return this.itemData.approvals.length;
      }
   }

   getUserApproval(email: string): MarkerApproval | null {
      if ( this.itemData.approvals ) {
         const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
         if (index >= 0 ) {
            const ap = this.itemData.approvals[index];

            if (email === this.userInfo.email) {
               this.userApprovalRequest = ap;
               this.userApprovalRequested = true;
               this.userApprovalResponse = ap.response;
            }
            return ap;
         } else {
             return null;
           }
      } else {
          return null;
        }
   }

  getUserApprovalResponse (email: string): string | null {
      const ap = this.getUserApproval(email);
      if ( ap ) {
        switch (ap.response) {
            case 'P': return 'Pending';
            case 'Y': return 'Yes';
            case 'N': return 'No';
            default:  return 'Undefined';
        }
      } else {
           return null;
        }
   }

  approvalScopeChange(e) {
    // console.log('approval scope change e=', e);
    //console.log('approval scope change e.isUserInput=', e.isUserInput);
    //console.log('approval scope change e.source.value=', e.source.value);
    if( e.isUserInput ) {
       const respCnt = this.getResponseCount(MarkerDialog.APPROVAL_RESPONSE_YES) +    this.getResponseCount(MarkerDialog.APPROVAL_RESPONSE_NO);
       if ( respCnt > 0 ) {
          this.lastTemplate = this.template;
          this.oldApprovalType = this.itemData.approvalScope;
          this.newApprovalType = e.source.value;
          this.confirmed = false;
          this.confirmTitle = 'Change Approval Scope';
          this.confirmMessage1 = respCnt + ' users have already responded.';
          this.confirmMessage2 = 'Are you sure you want to change the scope?';
          this.template = this.templateConfirmScope;
       } else {
           this.changeApprovalScope( e.source.value );
         }
    }
  }

  changeApprovalScope(newType: string) {
       const oldApprovalScope = this.itemData.approvalScope;
       this.itemData.approvalScope = newType;
       //console.log('approvalScopeChange from ' + oldApprovalScope + ' to ' +
       //  newType );
       if ( this.itemData.approvals) {
          this.itemData.approvals.forEach((ap, i)=>{
             ap.scope = newType;
          });
       }
       let fromScope = 'unknown';
       let toScope = 'unknown';
       switch (oldApprovalScope) {
          case MarkerDialog.APPROVAL_TYPE_DOCUMENT:
              // this.setTitle('Document Approval');
              fromScope = 'Document';
              break;
          case MarkerDialog.APPROVAL_TYPE_PAGE:
              // this.setTitle('Page ' + this.itemData.pageNbr + ' Approval');
              fromScope = 'Page ' + this.itemData.pageNbr;
              break;
          case MarkerDialog.APPROVAL_TYPE_TEXT:
              // this.setTitle('Selected Text Approval');
              fromScope = 'Selected Text';
              break;
          case MarkerDialog.APPROVAL_TYPE_AREA:
              // this.setTitle('Selected Area Approval');
              fromScope = 'Selected Area';
              break;
       }
       switch (newType) {
          case MarkerDialog.APPROVAL_TYPE_DOCUMENT:
              // this.setTitle('Document Approval');
              toScope = 'Document';
              break;
          case MarkerDialog.APPROVAL_TYPE_PAGE:
              // this.setTitle('Page ' + this.itemData.pageNbr + ' Approval');
              toScope = 'Page ' + this.itemData.pageNbr;
              break;
          case MarkerDialog.APPROVAL_TYPE_TEXT:
              // this.setTitle('Selected Text Approval');
              toScope = 'Selected Text';
              break;
          case MarkerDialog.APPROVAL_TYPE_AREA:
              // this.setTitle('Selected Area Approval');
              toScope = 'Selected Area';
              break;
       }
       this.addComment('Approval type changed from ' + fromScope + ' to ' +
                       toScope );
  }

  approvalResponseChange(e) {
    //console.log('approval response change e=', e);
    //console.log('approval scope change e.isUserInput=', e.isUserInput);
    //console.log('approval scope change e.source.value=', e.source.value);
    if ( e.isUserInput ) {
       const oldResponse = this.getUserApprovalResponse(this.userInfo.email);
       this.updateApprovalRequest(this.userInfo.email, e.source.value);
       const newResponse = this.getUserApprovalResponse(this.userInfo.email);
       //console.log('Approval changed from ' + oldResponse +
       //            ' to ' + newResponse );
       this.addComment('Approval changed from ' + oldResponse +
                       ' to ' + newResponse );
       this.approvalEvent.emit(this.itemData);
    }
  }

  setApprovalScope(s: string) {
    console.log('set approval scope s=', s);
    //console.log('set approval itemData=', this.itemData);
  }

  setApprovalResponse(s: string) {
    console.log('set approval response s=', s);
    //console.log('set approval itemData=', this.itemData);
  }

   approvalRequestExists(email: string): boolean {
      if( ! this.itemData.approvals || this.itemData.approvals.length === 0 ) {
          // itemData.approvals does not exist error
          // console.error('approvalRequestExists itemData.approvals is null or undefined or empty');
          return false;
      }
      const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
      if( index === -1 ) {
         // console.log('approvalRequestExists is false for ', email);
         return false;
      } else {
          // console.log('approvalRequestExists is true for ', email);
          return true;
        }
   }

   approvalRequestIndex(email: string): number {
      if( ! this.itemData.approvals || this.itemData.approvals.length === 0 ) {
          // itemData.approvals does not exist error
          console.error('approvalRequestExists itemData.approvals is null or undefined or empty');
          return -1;
      }
      const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
      return index;
   }

   addApprovalRequest(email: string, uid: string) {
      if( this.itemData.approvals ) {
         //
         const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
         // item exists do nothing
         if (index > -1) {
           console.log('addApprovalRequest already exists!');
           return false;
         }
      } else {
          // itemData.approvals does not exist error
          console.error('addApprovalRequest itemData.approvals is null or undefined');
          return false;
        }

      // Add the approval request
      const ar = new MarkerApproval();
      ar.created_at = Date.now();
      ar.ownerUID = this.userInfo.uid;
      ar.ownerEmail = this.userInfo.email;
      ar.responderUID = uid;
      ar.responderEmail = email;
      ar.response = 'P';
      ar.responseTimestamp = null;
      // this.itemData.approvalsPending++;
      this.itemData.approvals.push(ar);
      if ( this.userInfo.email === email ) {
         this.userApprovalRequest = ar;
         this.userApprovalRequested = true;
      }
      return true;
   }

   updateApprovalRequest(email: string, response: string): boolean {
      if( ! this.itemData.approvals || this.itemData.approvals.length === 0 ) {
          // itemData.approvals does not exist error
          console.error('updateApprovalRequest itemData.approvals is null or undefined or empty');
          return false;
      }

      const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
      // if item not found, do nothing
      if (index === -1) {
        console.log('updateApprovalRequest does not exist!');
        return false;
      } else {
             const ar = this.itemData.approvals[index];
             // let oldResponse = ar.response;
             ar.response = response;
             ar.responseTimestamp = Date.now();
/******
             switch( oldResponse ) {
               case 'P':
                       this.itemData.approvalsPending--;
                       break;
               case 'Y':
                       this.itemData.approvalsYes--;
                       break;
               case 'N':
                       this.itemData.approvalsNo--;
                       break;
             }
             switch( response ) {
               case 'P':
                       this.itemData.approvalsPending++;
                       break;
               case 'Y':
                       this.itemData.approvalsYes++;
                       break;
               case 'N':
                       this.itemData.approvalsNo++;
                       break;
             }
**********/
             return true;
           }
   }

   removeApprovalRequest(email: string): boolean {
      if( ! this.itemData.approvals || this.itemData.approvals.length === 0 ) {
          // itemData.approvals does not exist error
          console.error('removeApprovalRequest itemData.approvals is null or undefined or empty');
          return false;
      }

      //
      const index = this.itemData.approvals.findIndex(object => object.responderEmail === email);
      // item exists do nothing
      if (index === -1) {
        console.log('removeApprovalRequest request not found!');
        return false;
      } else {
           const ar = this.itemData.approvals[index];
           if (this.userInfo.email === email) {
              this.userApprovalRequest = null;
              this.userApprovalRequested = false;
           }
           this.itemData.approvals.splice(index, 1);
           return true;
        }
    }

    setAll(checked: boolean) {
       this.selectAllMembers = checked;
       console.log('setAll checked=', checked);
       this.groupMembers.forEach( ( mbr, i ) => {
         //console.log('setAll mbr=', mbr);
         if ( checked) {
            this.addApprovalRequest( mbr.email, mbr.uid );
         } else {
              this.removeApprovalRequest( mbr.email );
          }
       });
    }

  } // marker-dialog

<div>
   <p style="font-size: 1rem; font-weight: bold; color: red; text-align: center;">
     <span style="text-align: center;">NOTICE</span><br>
     <!---
     <span style="text-align: center;">The user subaccount feature will be discontinued in the next version of Markadoc.</span><br>
     <span style="text-align: center;">In addition new and existing subaccounts are no longer be able to create new groups.</span>
     <span style="text-align: center;">Users with subaccounts should migrate them to a Company/Organization/Team user under the new feature and subscription plan.</span>
     <span style="text-align: center;">Any groups or files owned by a subaccount will have ownership changed to the pripary user that owns the linked subaccount before they are discontinued.</span>
     --->
     <span style="text-align: center;">Note: User subaccounts can not create new groups however than can collaborate and upload documents to any groups they are added to</span>
     <br>     
</p><br>
</div>
<p class="title">User Guest Accounts</p>
<div class="title" *ngIf="userInfo">
for {{userInfo.email}}
</div>
<div class='addsub-content'>
  <form #addform="ngForm" [formGroup]="newsub" (submit)="addSub()">
        <div class='sub_add_button'>
            <div class='email-input'>
                <mat-form-field style='width: 100%' appearance="outline">
                    <mat-label>Email</mat-label>
                    <input matInput formControlName="email" placeholder="user@domain.com">
                    <mat-icon matSuffix>mail</mat-icon>
                </mat-form-field>
            </div>
            <div>
                <button mat-raised-button type="submit">Add Guest</button>
            </div>
        </div>
    </form>
    <div class='sub_add_button'>
        <div class='email-input'>
            Used: {{ userSubacctCnt }}
        </div>
        <div>
            Available: {{userInfo.accounts - userSubacctCnt}} of {{ userInfo.accounts }} 
        </div>
       <button mat-raised-button
            (click)="refresh_subs()"
	    style="margin-left: 1rem">Refresh</button>
    </div>
   <form *ngIf="userSubacctCnt" [formGroup]="subs">
            <div formArrayName="accounts" *ngFor="let account of subs.get('accounts').value; let i = index;">
                <mat-list class='subaccount-list'>
                    <mat-list-item class='subaccount-list-item'>{{account.email}}</mat-list-item>
                    <mat-icon class='subaccount-item-icon' (click)="openRemoveSubaccountDialog(userInfo.uid, i)">remove_circle</mat-icon>
                </mat-list>
            </div>
    </form>
</div>

import { Injectable } from '@angular/core';

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

/* Dialog Components */
import { ActionPromptDialogComponent } from '../dialog/action-prompt/action-prompt.dialog';
import { FormDialogComponent } from '../dialog/form/form.dialog';

import * as STATIC from '../mdtools/statics.common';

@Injectable({
  providedIn: 'root',
})
export class FormDialogService {
  // Default Dialog Config

  DisplayModel: typeof STATIC.Model = STATIC.Model;
  DisplayMode: typeof STATIC.Mode = STATIC.Mode;
  DisplayTemplate: typeof STATIC.Template = STATIC.Template;

  dialogConfig: MatDialogConfig = {
    panelClass: 'panel-class'
  };

  dialogRef?: MatDialogRef<FormDialogComponent>;

  dialogState: unknown; // The dialog component can access the "Data that needs to be passed down to it" from here as well

  constructor(private dialog: MatDialog) {}

  /**
   *
   * @param @Optional dialogConfig Component wise dialog config.
   *  Components can pass down data in this config or they can assign the data to dialogState
   */
  open(dialogConfig?: MatDialogConfig): MatDialogRef<FormDialogComponent> {
    this.dialogRef = this.dialog.open(FormDialogComponent, {
      ...this.dialogConfig,
      ...dialogConfig,
    });

    this.dialogRef.afterClosed().subscribe((response: unknown) => {
      // Do something in the service level. ( All components )
      this.reset(); // Reset the dialogDataState
    });

    return this.dialogRef;
  }


  openGroupDialog(mode: string, userData, grpData): MatDialogRef<FormDialogComponent> {
      this.dialogConfig = new MatDialogConfig();
      this.dialogConfig.disableClose = true;
      this.dialogConfig.autoFocus    = true;
      this.dialogConfig.panelClass   = 'panel-class';
      this.dialogConfig.minWidth     = '50vw';
      this.dialogConfig.maxWidth     = '95vw';
      this.dialogConfig.maxHeight    = '99vh';

      let title = 'Group';
      switch (mode) {
        case this.DisplayMode.VIEW:
              title = 'View Group';
              break;
        case this.DisplayMode.ADD:
              title = 'Create Group';
              break;
        case this.DisplayMode.EDIT:
              title = 'Edit Group';
              break;
        case this.DisplayMode.DELETE:
              title = 'Delete Group';
              break;
      }

      this.dialogConfig.data = {
         userData: userData,
         objModel: this.DisplayModel.GROUP,
         objMode: mode,
         objData: grpData,
         dialogTitle: title,
         /****
         message: msg,
         message2: msg2,
         ****/
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Save',
      };

    this.dialogRef = this.dialog.open(FormDialogComponent, {
      ...this.dialogConfig
    });

    this.dialogRef.afterClosed().subscribe((response: unknown) => {
      // Do something in the service level. ( All components )
      this.reset(); // Reset the dialogDataState
    });

    return this.dialogRef;
  }

  reset(): void {
    this.dialogState = undefined;
  }
}


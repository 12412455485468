// #279 - CJ - This component is only built for ADD MEMBER and ADD MEMBERS
//             functionality. If a group-member component is built to display
//             group members in various formats it can emit an addMemberEvent
//             and addMultipleEvent so another component can handle the display
//             of this component to add members.
//
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { Component, OnInit, Input, Output, ViewChild, AfterViewInit,
         ViewContainerRef, Inject, EventEmitter, ElementRef, OnChanges,
         SimpleChanges } from '@angular/core';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { ToasterService } from '../../service/toaster.service';

import { environment } from '../../../environments/environment';

// import { lastValueFrom } from 'rxjs';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
// import { SubaccountEditComponent } from '../subaccount-edit/subaccount-edit.component';

import { GroupData } from '../model/groupdata.model';
import { GroupMember } from '../model/groupmember.model';

import { OrgData } from '../../org/model/orgdata.model';
import { OrgUserData } from '../../org/model/orguserdata.model';

// import { FileSelector} from '../model/fileselector.model';

import { AuthService } from '../../service/auth.service';
import { AuthorizationService } from '../../service/authorization.service';
import { GroupMemberService } from '../../service/group-member.service';

import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';

import { UserService } from '../../service/user.service';
import { UserSubAccountService } from '../../service/user-subaccount.service';
import { ValidationService } from '../../service/validation.service';

import { UserData } from '../../user-acct/model/userdata.model';
import { UserInfo } from '../../user-acct/model/userinfo.model';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v<>';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

export const ADD_MEMBER      = 1;
export const ADD_MULTIPLE    = 2;

export interface MemberData {
  uid: string;
  groups_uid: string;
  name: string;
  last_name: string;
  email: string;
  Company: string;
  phone: string;
}

@Component({
   selector: 'app-add-group-member',
   templateUrl: './add-group-member.component.html',
   styleUrls: ['./add-group-member.component.css'],
})
export class AddGroupMemberComponent implements OnInit, OnChanges, AfterViewInit {
   @Input()  template: number;
   // @Input()  orgUID: string;
   // @Input()  orgUnitUID: string;
   @Input()  userData: UserData;
   @Input()  userInfo: UserInfo;
   @Input()  group: GroupData;

   @Output() addMemberEvent = new EventEmitter<any>();
   @Output() refreshSubsEvent = new EventEmitter<any>();
   @Output() stepMessageEvent = new EventEmitter<any>();

   @ViewChild('groupmember', {static: false}) memberListView: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;

   // Constants
   ADD_MEMBER      = 1;
   ADD_MULTIPLE    = 2;

   // Service Messaging Subscription
   memberSubscription: Subscription;

   // View vars
   emailText: any;
   emailAddress: string;
   junkText: any;
   stepMessage: string;
   statusMessage = '';

   isOrgGroup = false;
   addGuestAccounts = false;
   addFreeAccounts = false;

   // Instance vars
   emailAddressList: string[];
   userUID: string;
   userSubList: any;
   userSubLimit: number;
   userSubAvail: number;

   isOrgGuestAdmin = false;
   addOrgGuestAccounts = false;
   orgGuestLimit = 0;
   orgGuestAvail = 0;

   groupName: string;
   loading: boolean;

   newUserCnt: number;
   newUserErr: number;
   newMemberCnt: number;
   newMemberErr: number;
   newFreeCnt: number;
   newFreeErr: number;
   newTokenCnt: number;
   newTokenErr: number;

   // group member info
   selectedGroupMemberObj: GroupMember;
   selectedGroupMember: string;
   selectedOrganization;
   selectedOrgUnit;
   selectedOwner;
   selectedName;
   selectedGroupObj: any;

   constructor(
      private auth: AuthService,
      private authorizationsvc: AuthorizationService,
      private groupmembersvc: GroupMemberService,
      private orgsvc: OrganizationService,
      private orgusersvc: OrgUserService,
      private usersvc: UserService,
      private usrsubacctsvc: UserSubAccountService,
      private validationsvc: ValidationService,
      private toast: ToasterService
      ) {
          this.userSubLimit = 0;
          if (this.userInfo) {
              this.userSubLimit = this.userInfo.accounts;
              // console.log('GM uinfo=', this.userInfo);
          } else {
                console.warn('AddGroupMemberComponent WARNING userInfo not defined.');
                 }
          if (this.group) {
              console.log('GM group=', this.group);
          } else {
              console.warn('AddGroupMemberComponent WARNING group not defined.');
                 }
      } // constructor


   async ngOnInit() {
      console.log('AGM userInfo=', this.userInfo);
      console.log('AGM this.group=', this.group );

      try {
        if ( this.userInfo && this.userInfo.accounts ) {
           this.userSubLimit = this.userInfo.accounts;
        } else {
           this.userSubLimit = 0;
          }
        this.userSubList = await this.get_subs(this.userInfo.uid);
        if ( this.userSubList ) {
          const used = this.userSubList.length;
          this.userSubAvail = this.userSubLimit - used;
        } else {
            this.userSubAvail = 0;
        }
        console.log('subs=', this.userSubList );
      } catch (e) {
           console.log('subs=', e );
        }
      if ( this.group ) {
        this.groupChange( this.group );
      } else {
          console.warn('addgrpmbr: No group on init..');
        }
   }

   async ngAfterViewInit() {
   }

   async ngOnChanges(changes: SimpleChanges) {
      if ( changes.group ) {
         this.group = changes.group.currentValue;
         this.groupChange( this.group );
      }
      if ( changes.userData ) {
         this.userData = changes.userData.currentValue;
         this.groupChange( this.group );
      }
      if ( changes.userInfo ) {
         this.userInfo = changes.userInfo.currentValue;
         this.groupChange( this.group );
      }
   }

   async groupChange( g: GroupData ) {
      // Check if this is an org group or user group.
      g.org_uid ? this.isOrgGroup = true : this.isOrgGroup = false;
      if ( this.isOrgGroup ) {
        // Get isOrgGuestAdmin
        this.isOrgGuestAdmin = await this.authorizationsvc.getIsOrgGuestAdmin( g.org_uid, this.userInfo.uid );
        console.log('addgrpmbr groupChange isOrgGuestAdmin =', this.isOrgGuestAdmin);
        // Get org guest limit and nbr guests available
	const org = await this.orgsvc.getOrganization( g.org_uid );
	this.orgGuestLimit = org['org_guest_account_limit'];
	const orgGuestUsed = await this.orgusersvc.getOrgUserGuestCountPromise( g.org_uid );
	console.log('AGM: orgGuestUsed=', orgGuestUsed);
	this.orgGuestAvail = this.orgGuestLimit - orgGuestUsed['count'];
      }
   }

   isOwnerAdmin(): boolean {
      // 279- CJ - Check if owner, will need to check if user is admin
      // also at a later date once we add admins
      if (this.group && this.userInfo) {
         if (this.group.owner === this.userInfo.uid) {
            return true;
         } else {
             return false;
             }
      }
   }

   checkAddMember() {
     const proceed = this.isOwnerAdmin();
     console.log('add-group-member proceed=', proceed);
     if (proceed ) {
           this.handleAddMember();
     } else {
           this.toast.pop('error', 'Error!',
           'You must be group owner or administrator to add a group member.');
           }
   }

   async handleAddMember() {
      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;

      // check to make sure a group is selected
      if (!addGroup || addGroup === null) {
         this.toast.pop('error', 'Add User to Group',
         'Cant find group id, please select a group.');
         this.stepMessage = 'Error cant find group id. Please try again.';
         this.stepMessageEvent.emit(this.stepMessage);
         console.log('handleAddUser error: Group is not selected');
         return;
      }

      // make sure the user owns the group (or has admin privs)
      const proceed = this.isOwnerAdmin();
      console.log('handleAddMember proceed=', proceed);
      if ( ! proceed ) {
           this.toast.pop('error', 'Error!',
           'You must be group owner or administrator to add a group member.');
           return;
        }

      this.doAddMember(this.emailAddress, true);
   }

   async handleAddMemberList() {
      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;

      // check to make sure a group is selected
      if (!addGroup || addGroup === null) {
         this.toast.pop('error', 'Add User to Group',
         'Cant find group id, please select a group.');
         this.stepMessage = 'Error cant find group id. Please try again.';
         this.stepMessageEvent.emit(this.stepMessage);
         console.log('handleAddUser error: Group is not selected');
         return;
      }

      // make sure the user owns the group (or has admin privs)
      const proceed = this.isOwnerAdmin();
      console.log('handleAddMember proceed=', proceed);
      if ( ! proceed ) {
           this.toast.pop('error', 'Error!',
           'You must be group owner or administrator to add a group member.');
           return;
      }
      this.doAddMemberList();
   }

   async doAddMember(userEmail, doToast) {
      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;
      console.log('doAddMember: userEmail=', userEmail);

      // validate email address and process
      if ( this.validationsvc.isValidEmailAddress(userEmail) === true) {

        // Future - See if user is already member of the group here..

        let g = null;
        // if the option is selected add user as guest account
        if (this.addGuestAccounts === true ) {
           // add guest subaccounts
           g = await this.doAddGuest(userEmail, doToast);
        }

        // if the option is selected add user as guest account
        if (this.addOrgGuestAccounts === true ) {
           // add Org guest account
           g = await this.doAddOrgGuest(userEmail, doToast);
        }

        // if the option is selected send user free trial account email
        if (this.addFreeAccounts === true ) {
           // send free trial invitations
           // const f = await this.doAddFree(userEmail, doToast);
        }

        console.log('agm guest g=', g);
        const usr = await this.usersvc.getUserByEmailPromise(userEmail);
        console.log('agm usr=', usr);
        if ( !usr ) {
           this.toast.pop('error', 'Error Adding Group Member!',
           'User Account ' + userEmail + ' does not exist!');
           return;
        }

        try {
           // ####### ISSUE HERE NOT WORKING AFTER ADD ORG GUEST!!! *******
           //
           const x = await this.groupmembersvc.addMemberPromise(this.userInfo.uid, userEmail, addGroup);
           this.stepMessage = 'Added ' + userEmail + ' to ' +
                              addGroupName;
           this.stepMessageEvent.emit(this.stepMessage);
           if (doToast) {
             this.toast.pop('success', 'Group Member Added',
             'User ' + userEmail + ' was added to group ' + this.group.name );
           }
           userEmail = '';
           this.newMemberCnt++;
         } catch (error) {
                console.log('processAddMember: add group member error=', error);
                this.stepMessage = 'Error Adding ' + userEmail + ' to '
                                   + addGroupName;
                this.stepMessageEvent.emit(this.stepMessage);
                if (doToast) {
                 this.toast.pop('error', 'Error Adding Group Member!',
                 'Unable to add ' + userEmail + ' to group ' + this.group.name +
                 ' ' + 'Error: ' + error.name + ' ' + error.message);
                }
                this.newMemberErr++;
                throw error;
          }
      } else {
          if (doToast) {
             this.toast.pop('error', 'Malformed Email Address!',
             'Please correct the error and try again');
          }
          this.newMemberErr++;
          throw false;
      }
  }

  async doAddMemberList() {
      console.log('doAddMemberList this.group =', this.group.uid);

      this.newMemberCnt = 0;
      this.newMemberErr = 0;
      this.newUserCnt = 0;
      this.newUserErr = 0;
      this.newFreeCnt = 0;
      this.newFreeErr = 0;

      const addGroup: GroupData = this.group;
      const addGroupUID = this.group.uid;
      const addGroupName = this.group.name;

      console.log('add-group-member: handleAddUserList group=', this.group);
      console.log('add-group-member: handleAddUserList emailText=', this.emailText);
      // clean up the text
      // this.emailText = this.validationsvc.extractEmailAddresses(this.emailText);
      console.log('agm: extracted emailText=', this.emailText);

      // parseEmailAddressList constructs the emailAddressList
      const cnt = this.parseEmailAddressList();
      console.log('add-group-member: emailAddressList=', this.emailAddressList);
      console.log('add-group-member: email cnt=', cnt);
      for (const a of this.emailAddressList) {
           console.log('doAddMemberList a=', a);
           try {
             const x = await this.doAddMember(a, false);
             this.emailAddressList = this.emailAddressList.filter(i => i !== a);
           } catch (e) {
               console.log('doAddMemberList error=', e);
           }

      } // for

      console.log('agm addmemberlist newMemberCnt =', this.newMemberCnt);
      console.log('agm addmemberlist newMemberErr =', this.newMemberErr);
      console.log('agm addmemberlist emailAddressList =', this.emailAddressList);

      if ( this.newMemberCnt === cnt ) {
         this.stepMessage = 'Added ' + this.newMemberCnt + ' accounts to ' + addGroupName;
         this.stepMessageEvent.emit(this.stepMessage);
         this.toast.pop('success', 'Add User to Group',
            'Added ' + this.newMemberCnt + ' accounts to ' + addGroupName);
      } else {
            this.stepMessage = 'Correct the errors and click Add Members or Click Add as Guest Accounts to create guest accounts for these users.';
            this.stepMessageEvent.emit(this.stepMessage);
            if ( this.newMemberCnt > 0 ) {
              this.toast.pop('success', 'Add User to Group',
              'Added ' + this.newMemberCnt + ' members to ' + addGroupName);
            }
            this.toast.pop('error', 'Add User to Group',
            'Failed to add ' + this.newMemberErr + ' members to ' + addGroupName);
            }

      if (this.addGuestAccounts === true) {
        if (this.newUserCnt === cnt) {
         this.stepMessage = 'Added ' + this.newUserCnt + ' accounts to ' + addGroupName;
         this.stepMessageEvent.emit(this.stepMessage);
         this.toast.pop('success', 'Add Guest Accounts',
            'Added ' + this.newUserCnt + ' guest accounts');
        } else {
            this.stepMessage = 'Correct the errors and click Add Members to try again';
            this.stepMessageEvent.emit(this.stepMessage);
            if ( this.newUserCnt > 0 ) {
              this.toast.pop('success', 'Add Guest Accounts',
              'Added ' + this.newUserCnt + ' user guest accounts');
            }
            this.toast.pop('error', 'Add Guest Accounts',
            'Failed to add ' + this.newUserErr + ' guest accounts');
        }
      }

      // clean up the email text box leaving any errors
      this.emailText = '';
      if ( this.emailAddressList && this.emailAddressList.length > 0 ) {
         for ( const adr of this.emailAddressList ) {
            this.emailText += adr + ';\n';
         }
      }
      this.emailText += this.junkText;
    }

    async doAddGuest(userEmail, doToast) {
        this.stepMessage = 'Adding guest account...';
        this.stepMessageEvent.emit(this.stepMessage);
        let nbrAccts = 0;
        // CJ - NOTE - NEED A CHECK HERE ON NBR AVAILABLE SUB ACCTS
        try {
           this.userSubList = await this.get_subs(this.userInfo.uid);
           nbrAccts = this.userSubList.length;
           console.log('agm: subList=', this.userSubList);
           console.log('agm: nbrAccts=', nbrAccts);
        } catch (error) {
            this.stepMessage = 'ERROR: Cant get the sub account list.';
            console.log('hau: cant get sublist');
          }
        if ( this.userInfo.accounts > nbrAccts ) {
          try {
            this.stepMessage = 'Adding Guest Account for ' + this.emailAddress;
            this.stepMessageEvent.emit(this.stepMessage);
            // const s = await this.addSub(userEmail);
            const s = await this.usrsubacctsvc.addUserSubAccount(this.userInfo.uid, userEmail);
            console.log('s=', s);
            setTimeout(() => {
            }, 50);
            if (doToast) {
              this.toast.pop('success', 'Add Guest Account', 'Added guest account ' + userEmail);
            }
            this.newUserCnt++;
          } catch (e) {
              this.stepMessage = 'Account exists for ' + this.emailAddress
              + ' or maximum guest accounts exceeded.';
              this.stepMessageEvent.emit(this.stepMessage);
              if (doToast) {
                this.toast.pop('error', 'Add Guest Account', 'Error adding guest account ' + userEmail);
              }
              this.newUserErr++;
          }
        } else {
            this.stepMessage = 'Guest Account limit reached: ' + this.userInfo.accounts + ' accounts exceeded.';
            if (doToast) {
              this.toast.pop('error', 'Add Guest Account',
              'Error guest account limit of ' + this.userInfo.accounts +
              ' accounts exceeded.');
            }
            this.stepMessageEvent.emit(this.stepMessage);
            this.newUserErr++;
        }
    }

    async doAddOrgGuest(userEmail, doToast) {
        this.stepMessage = 'Adding Company/Org. Guest Account...';
        this.stepMessageEvent.emit(this.stepMessage);
        const nbrGuestAccts = 0;
        // Get the organization info
        if ( !this.group && !this.group['org_uid'] ) {
          this.toast.pop('error', 'Add Company/Org. Guest Account', 'Error adding company/org guest account: group is not a Company/Org. Group!' + userEmail);
          return;
        }
        const org = await this.orgsvc.getOrganizationPromise( this.group['org_uid'] );
        if ( !org ) {
          this.toast.pop('error', 'Add Company/Org/Team Guest Account', 'Error Company/Org/Team not found!');
          return;
        }
        console.log('agm org=', org);
        const org_guest_limit = org['org_guest_account_limit'];
        const org_active_guest_limit = org['org_active_guest_account_limit'];
        let ou = null;

        ou = await this.orgusersvc.getOrgUserByOrgAndEmailPromise(org['uid'], userEmail);
        console.log('agm ou=', ou);
        if ( ou && ou['uid '] ) {
          this.toast.pop('error', 'Add Company/Org/Team Guest Account', 'Error Company/Org/Team User Already Exists!');
          return ou;
        }

        const org_guest_cnt = await this.orgusersvc.getOrgUserGuestCountPromise(org['uid']);
        const org_guest_avail = org_guest_limit - org_guest_cnt['count'];

        console.log('agm org_guest_cnt=', org_guest_cnt);
        console.log('agm org_guest_avail=', org_guest_avail);

        if ( org_guest_avail > 0 ) {
           this.stepMessage = 'Adding Company/Org/Team Guest Account for ' +
                              this.emailAddress;
           this.stepMessageEvent.emit(this.stepMessage);
           ou = await this.addOrgGuestUser(org['uid'], userEmail, doToast);
        } else {
            this.stepMessage = 'Guest Account limit reached: ' + this.userInfo.accounts + ' accounts exceeded.';
            if (doToast) {
              this.toast.pop('error', 'Add Company/Org/Team Guest Account',
              'Error guest account limit of ' + org_guest_limit +
              ' accounts exceeded.');
            }
            this.stepMessageEvent.emit(this.stepMessage);
            this.newUserErr++;
          }
        return ou;
    }

    async addOrgGuestUser( oid: string, email: string, doToast=true ) {
      console.log('add org guest user oid=', oid);
      console.log('add org guest user email=', email);

      const data: OrgUserData = new OrgUserData();
      delete data.uid;
      delete data.created;
      delete data.timestamp;
      data.org_uid = oid;
      data.org_user_email = email;
      data.org_user_type = 'G';
      data.org_user_active = true;
      data.org_admin = false;
      data.org_billing_admin = false;
      data.org_guest_user_admin = false;
      data.org_user_group_admin = false;

      let result = null;
      try {
          result = await this.orgusersvc.addOrgUserPromise( data );
          console.log('addOrgGuestUser result=', result);
          this.toast.pop('success', 'Add Company/Org. User', 'Company/Org/Team User Account Created.');
          console.log('add org user result=', result);
      } catch (e)  {
           console.error('addmbr org - cant add orgdata e=', e);
           this.toast.pop('error', 'Add Company/Org/Team Guest User', 'Add Company/Org/Team Guest User Failed! e=', e);
      }
      console.log('addmbr org result=', result);
      return result;
    }


    async doAddFree(userEmail, doToast) {
    }

    clearEmailAddress() {
      this.emailAddress = '';
    }
    clearEmailText() {
      this.emailText = '';
    }

    extractEmailAddresses(text) {
      const t = text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
      console.log(t.join('\n'));
    }

    parseEmailAddressList() {
      this.junkText = '';
      console.log(this.emailInput);
      // this.extractEmailAddresses(this.emailText);
      const tmp: string = this.emailText.toString( ).replace(STRIP_CHARS, '').replace(/[\s\b\f\n\r\t\v<>]+/, ' ');
      console.log('tmp=', tmp);
      const arr = tmp.split(/[,;]+/);
      const dups = [];

      console.log('parseEmailAddressList=', arr);
      for ( const s of arr ) {
         const e = s.trim();
         // console.log('e=', e);
         if (this.validationsvc.isValidEmailAddress(e) === true) {
           dups.push(e);
         } else {
                  this.junkText += e;
                }
      }
      // this.emailText = junkText;

      this.emailAddressList = [];

      dups.forEach((c) => {
          if (!this.emailAddressList.includes(c)) {
             this.emailAddressList.push(c);
          }
      });
      const cnt = this.emailAddressList.length;
      console.log('valid emails=', cnt);
      console.log('valid junk=[', this.junkText + ']');
      this.stepMessage = 'Found ' + cnt + ' valid email addresses';
      // using extractEmailAddresses
      if (this.junkText !== '') {
        this.stepMessage += ' Did NOT process remaining text.';
        this.emailText = this.junkText;
        }
      return cnt;
    }


    getUserInfo(): Promise<any> {
      return this.auth.getInfo().toPromise().then(
            (x: UserInfo) => {
                console.log('x=', x);
                return x;
               },
               (e) => {
                  console.log('ERROR: ', e);
                  throw e;
               });
    }

    async addSub(userEmail) {
        console.log('addSub userEmail=', userEmail);
        console.log('addSub userInfo=', this.userInfo);
        const data = {
            email: userEmail
        };
        let x = null;
        try {
           x = await this.usrsubacctsvc.addUserSubAccount(this.userInfo.uid, userEmail);
           this.newUserCnt++;
           console.log('add-user-group addSub:', userEmail);
        } catch (e) {
             this.newUserErr++;
             console.log('add-user-group addSub ${{userEmail}} ERROR:', e);
             // this.toast.pop('error', 'ERROR', 'Error adding sub..');
             throw e;
        }
        return x;
    }

    async get_subs(uid) {
       let sublist = [];
       try  {
          sublist = await this.usrsubacctsvc.getUserSubAccts(uid);
          // console.log('add-group-member sublist:', sublist);
          return sublist;
       } catch (e) {
           console.log(e);
           throw e;
       }
    }

   toggleAddOrgGuestAccounts() {
         console.log('toggle addguestaccts =', this.addGuestAccounts);
         this.addFreeAccounts = false;
   }

   toggleAddGuestAccounts() {
         console.log('toggle addguestaccts =', this.addGuestAccounts);
         this.addFreeAccounts = false;
   }

   toggleAddFreeAccounts() {
         console.log('toggle addfreeaccts =', this.addFreeAccounts);
         this.addGuestAccounts = false;
   }

} // add-group-member

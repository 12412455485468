import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { NgForm } from '@angular/forms';
import {MatDialog, MatDialogRef, MatDialogConfig} from '@angular/material/dialog';
import { ActionPromptDialogComponent } from '../dialog/action-prompt/action-prompt.dialog';
import { ToasterService } from '../service/toaster.service';
// import { HttpClient } from '@angular/common/http';

import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

import { LinkedUserInfo } from '../model/linkeduserinfo.model';

import { UserInfo } from '../model/userinfo.model';
import { UserData } from '../model/userdata.model';
import { AuthService } from '../service/auth.service';
import { UserSubAccountService } from '../service/user-subaccount.service';
import { UserService } from '../service/user.service';

@Component({
    selector: 'app-subaccount-edit',
    templateUrl: './subaccount-edit.component.html',
    styleUrls: ['./subaccount-edit.component.css']
})
export class SubaccountEditComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('addform') addform;
    userSubacctCnt = 0;
    userData: UserData;
    userInfo: UserInfo;
    sublist: UserInfo[];
    linkedUserInfo: LinkedUserInfo[];
    subs: FormGroup;
    newsub: FormGroup;
    usermap: any;
    email = '';

    //
    actionPromptDialogRef: any = null;

    // Service Messaging Subscription
    subacctSubscription: Subscription;

    constructor(
        private auth: AuthService,
        private subsvc: UserSubAccountService,
        private usersvc: UserService,
        private fb: FormBuilder,
        private toast: ToasterService,
        // private http: HttpClient,
        public  actionPromptDialog: MatDialog,
    ) {
        this.userInfo = new UserInfo();
        // this.refresh_subs();
    }

    ngOnInit(): void {
        this.subs = this.fb.group({
            accounts: this.fb.array([])
        });
        this.usermap = {};
        this.newsub = this.createSub();
    }

    ngAfterViewInit(): void {
        this.refresh_subs();

        // register subscription for when the service alters the sublist,
        // we refresh component subaccts list
        // this.subacctSubscription =
        //    this.subsvc.getUserSubAcctUpdates().subscribe( slist =>
        //                                 { console.log('slist=', slist);
        //                                   this.refresh_subs();
        //                               });
    }

    ngOnDestroy(): void {
        // unsubscribe to ensure no memory leaks
        if (this.subacctSubscription) {
           this.subacctSubscription.unsubscribe();
       }
    }

    getUserInfo() {
        this.userData = this.auth.getTokenInfo();
        const usr = this.usersvc.getUserInfo(this.userData.uid);
        return usr;
        /******
        return this.auth.getInfo()
               .toPromise()
               .then(
                     (x) => x as UserInfo,
                     (e) => {
                              console.log('ERROR: ', e);
                              // clearInterval(this.updateInterval);
                              // this.router.navigate(['/login']);
                              throw e;
                            }
                );
        ********/
    }

    async get_subs(uid) {
       let sublist = [];
       try  {
          sublist = await this.subsvc.getUserSubAccts(uid);
          // console.log('add-group-member sublist:', sublist);
          return sublist;
       } catch (e) {
           console.log(e);
           throw e;
       }
    }

    async refresh_subs() {
       try {
             this.userInfo = await this.getUserInfo();
             this.sublist = await this.subsvc.getUserSubAccts(this.userInfo.uid);
             this.linkedUserInfo = await this.subsvc.getLinkedUserInfoGuests(this.userInfo.uid);
             console.log('subs sublist=', this.sublist);
             console.log('subs linkedUserInfo=', this.linkedUserInfo);
             if ( this.sublist ) {
               this.userSubacctCnt = this.sublist.length;
               // this.render_subs(this.sublist);
               this.render_subs(this.linkedUserInfo);
             } else {
                 this.userSubacctCnt = 0;
               }
       } catch (e) {
                     console.log(e);
                     this.toast.pop('error', 'Error!', 'Error retrieving subaccount list.');
                   }
    }

    sort_subs(arr) {
        arr.patchValue(arr.value.sort((a, b) => (a.email > b.email) ? 1 : -1));
    }

    render_subs(data) {
        console.log('render_subs data=', data);
        const subUids = data.map((m) => m.user_uid);
        console.log('render_subs subUids', subUids);
        this.subs = this.fb.group({
            accounts: this.fb.array([])
        });
        data.forEach((sub, i) => {
            console.log('data for each i=', i);
            // let usr = null;
            const arr = this.subs.get('accounts');
            const fc = this.createSub();
            this.usermap[sub.user_email] = sub;
            fc.setValue({
                email: sub.user_email
            });
            (arr as FormArray).push(fc);
            this.sort_subs(arr);
        });

        /****
        subUids.forEach((sub, i) => {
            console.log('subUids for each i=', i);
            let usr = null;
            const arr = this.subs.get('accounts');
            const fc = this.createSub();
            try {
               usr = this.usersvc.getUserInfo(sub);
               this.usermap[usr.email] = sub;
               fc.setValue({
                   email: usr.email
               });
               (arr as FormArray).push(fc);
               this.sort_subs(arr);
            } catch (e) {
                (e) => console.error('ERROR: ', e)
              }

            this.http.get(environment.apiUrl + '/user/' + sub).subscribe(
                (x: UserInfo) => {
                    const arr = this.subs.get('accounts');
                    const fc = this.createSub();
                    this.usermap[x.email] = sub;
                    // console.log(this.usermap);
                    fc.setValue({
                        email: x.email
                    });
                    (arr as FormArray).push(fc);
                    this.sort_subs(arr);
                },
                (e) => console.log('ERROR: ', e)
            );
            ********/
    }
    createSub() {
        return this.fb.group({
            email: new FormControl('', [
                Validators.required,
                Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
            ])
        });
    }

    updateSubs() {
        console.log('updatesubs needs event emitter or action');
    }

    getAccounts() {
        // console.log('getAccounts subs=', this.subs);
        return this.subs.get('accounts') as FormArray;
    }

    async addSub() {
        console.log(this.newsub);
        console.log(this.newsub.status);
        if (this.newsub.status !== 'VALID') {
            this.toast.pop('error', 'Bad Format', 'Email address is invalid.');
            return;
        }
        // this.toast.pop('wait', 'Adding Subaccount', 'Processing request...');

        // Call the service
        try {
           const res = await this.subsvc.addUserSubAccount(this.userInfo.uid, this.newsub.get('email').value );
           this.toast.clear();
           this.toast.pop('success', 'Adding User', 'User (' + this.newsub.get('email').value + ') added.');
           this.addform.resetForm();
           this.refresh_subs();
        } catch (e) {
                console.log(e);
                this.toast.clear();
                if (e.status === 409) {
                    this.toast.pop('error', 'Adding User', 'User (' + this.newsub.get('email').value + ') already a sub account.');
                } else if (e.status === 403) {
                    this.toast.pop('error', 'Adding User', 'User (' + this.newsub.get('email').value + ') has existing account.');
                } else {
                    this.toast.pop('error', 'Adding User', 'User (' + this.newsub.get('email').value + ') could not be added.');
                }
        }
    }

    async removeSub(uuid, idx) {
        console.log('subaccount-edit removeSub=', idx);
        const accounts = this.getAccounts();
        console.log('subaccount-edit accounts=', accounts);
        console.log('subaccount-edit usermap=', this.usermap);
        const user = this.usermap[accounts.value[idx].email];
        console.log('subaccount-edit user=', user);
        const useruid = user['user_uid'];
        console.log('subaccount-edit useruid=', useruid);
        const email = accounts.value[idx].email;
        console.log('subaccount-edit email=', email);

        try {
           const r = await this.subsvc.removeUserSubAcct(uuid, useruid);
           this.toast.pop('success', 'Remove User', 'User (' + email + ') removed.');
           this.refresh_subs();
        } catch (e) {
             this.toast.pop('error', 'Error!', 'User (' + email + ') was NOT removed.');
             console.log(e);
        }
    }


    async openRemoveSubaccountDialog(uuid, idx) {
      const accounts = this.getAccounts();
      const useruid = this.usermap[accounts.value[idx].email];
      const email = accounts.value[idx].email;

      const dialogConfig        = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus    = true;
      dialogConfig.panelClass   = 'panel-class';
      dialogConfig.minWidth     = '50vw';
      dialogConfig.maxWidth     = '85vw';
      dialogConfig.maxHeight    = '99vh';

      console.log('removeSubacct email=', email);
      console.log('removeMemberDialog owner=', uuid);
      console.log('removeMemberDialog useruid=', useruid);
      console.log('removeMemberDialog useruidx=', idx);

      // Pass the seletected group member data
      const subAcctData = {
         ownerUID: uuid,
         subUID: useruid,
         subIDX: idx,
         subEmail: email
         };

      const msg = 'Are you sure you want to remove the subaccount for user ' +
                  email + ' from your account ?';
      const msg2 = '';

      dialogConfig.data = {
         intent: 'deleteSubaccount',
         title: 'Remove User Subaccount',
         message: msg,
         message2: msg2,
         button1Color: 'red',
         message1Color: 'red',
         button1Text: 'Remove',
         dialogData: subAcctData
      };

      // Open action prompt dialog (delete group intent)
      this.actionPromptDialogRef = this.actionPromptDialog.open(ActionPromptDialogComponent, dialogConfig);

      // Callback after save from dialog
      let intent = null;
      let choice = null;
      let returnData = null;
      this.actionPromptDialogRef.componentInstance.actionPromptEvent.subscribe(
         data => {
            if (data) {
               intent = data.intent;
               choice = data.choice;
               returnData = data.dialogData;
               console.log('action-prompt return=', data.dialogData);
            }
            this.removeSub(uuid, idx);
          } //
       );
    }

    /****

    old_render_subs(data) {
        const subUids = data.map((m) => m.user_uid);
        this.subs = this.fb.group({
            accounts: this.fb.array([])
        });
        subUids.forEach((sub, i) => {
            this.http.get(environment.apiUrl + '/user/' + sub).subscribe(
                (x: UserInfo) => {
                    const arr = this.subs.get('accounts');
                    const fc = this.createSub();
                    this.usermap[x.email] = sub;
                    // console.log(this.usermap);
                    fc.setValue({
                        email: x.email
                    });
                    (arr as FormArray).push(fc);
                    this.sort_subs(arr);
                },
                (e) => console.log('ERROR: ', e)
            );
        });
    }
    oldAddSub() {
        console.log(this.newsub);
        console.log(this.newsub.status);
        if (this.newsub.status !== 'VALID') {
            this.toast.pop('error', 'Bad Format',
                           'Email address is invalid.');
            return;
        }
        const data = {
            email: this.newsub.get('email').value
            captcha: grecaptcha.getResponse()
        };

        this.toast.pop('wait', 'Adding User', 'Processing request...');
        this.http.put(environment.apiUrl + '/linkeduser/' + this.userInfo.uid, data).subscribe(
            (x) => {
                this.http.get(environment.apiUrl + '/linkeduser/' + this.userInfo.uid).subscribe(
                    (ix) => {
                        this.toast.clear();
                        this.toast.pop('success', 'Add User', 'User (' + this.newsub.get('email').value + ') added.');
                        this.addform.resetForm();
                        this.refresh_subs();
                    },
                    (e) => console.log(e)
                );
            },
            (e) => {
                console.log(e);
                this.toast.clear();
                if (e.status === 409) {
                    this.toast.pop('error', 'Adding User', 'User (' + this.newsub.get('email').value + ') already a sub account.');
                } else if (e.status === 403) {
                    this.toast.pop('error', 'Adding User', 'User (' + this.newsub.get('email').value + ') has existing account.');
                } else {
                    this.toast.pop('error', 'Adding User', 'User (' + this.newsub.get('email').value + ') could not be added.');
                }
            }
        );
    }

    oldRemoveSub(id) {
        console.log('REMOVE', id);
        const accounts = this.getAccounts();
        const useruid = this.usermap[accounts.value[id].email];

        this.http.delete(environment.apiUrl + '/linkeduser/' + this.userInfo.uid + '/' + useruid).subscribe(
            (x) => {
                this.toast.pop('success', 'Remove Subaccount', 'User (' + accounts.value[id].email + ') removed.');
                this.refresh_subs();
            },
            (e) => console.log(e),
        );
    }
    ***/

}

import { Component, OnInit, Output, ViewChild, AfterViewInit, ViewContainerRef,
         OnDestroy, Inject, EventEmitter, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig,
         MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToasterService } from '../../service/toaster.service';
import { Form, FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

import { SubaccountEditComponent } from '../../subaccount-edit/subaccount-edit.component';

import { GroupData } from '../../group/model/groupdata.model';
import { FileSelector} from '../../model/fileselector.model';

import { AuthService } from '../../service/auth.service';
import { GroupService } from '../../service/group.service';

import { UserInfo } from '../../user-acct/model/userinfo.model';

export interface QuickStartInput {
   groupList?: GroupData[];
   organization?: any;
   groupUID?: string;
}

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

@Component({
   selector: 'app-quick-start',
   templateUrl: './quick-start.dialog.html',
   styleUrls: ['./quick-start.dialog.css']
})
export class QuickStartDialogComponent implements OnInit, AfterViewInit, OnDestroy {
   @Output() updateDocListEvent = new EventEmitter<any>();
   @Output() uploadFilesEvent = new EventEmitter<any>();
   @Output() refreshSubsEvent = new EventEmitter<any>();
   @Output() createGroupEvent = new EventEmitter<any>();

   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;


   // View vars
   isLinear: false;
   expertMode: boolean;
   // firstFormGroup: FormGroup;
   // secondFormGroup: FormGroup;
   // thirdFormGroup: FormGroup;
   emailText: any;
   emailAddress: string;
   stepMessage: string;

   // Instance vars
   emailAddressList: string[];
   userInfo: any;
   subList: any;
   subLimit: number;
   groupList: GroupData[];
   group: any;
   groupName: string;
   groupUID: string;
   newUserCnt: number;
   newMemberCnt: number;
   newUserErr: number;
   newMemberErr: number;

   selectedImageSize;
   selectedImageRotation;
   selectedImageSizeText;
   selectedImageRotationText;
   selectedAddMargins = false;
   selectedAddMarginsText = 'Off';
   selectedOrganization;
   selectedOwner;
   selectedGroup: string;
   selectedGroupObj: GroupData;

   selectedGroupSubscription: any;
   
   constructor(
      private auth: AuthService,
      private groupsvc: GroupService,      
      private formBuilder: FormBuilder,
      private toast: ToasterService,
      private dialogRef: MatDialogRef<QuickStartDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data
      ) {
          if ( data ) {
             this.groupList = data.groupList;
             this.userInfo  = data.userInfo;
          }
          this.expertMode = true;
          this.selectedGroup = null;
          if (data.groupList) {
             console.log('quick-start this.data.groupList.length=', this.data.groupList.length);
             if ( this.groupList[0] ) {
                this.group = this.groupList[0];
                this.selectedGroupObj = this.group;
                this.selectedGroup = this.group.uid;
                this.groupChange(this.selectedGroup);
             }
          }
          if (this.userInfo) {
             this.subLimit = this.userInfo.accounts;
             console.log('quickstart uinfo=',
                         this.userInfo);
          }
        }

   ngOnInit() {
   }

   ngAfterViewInit() {
      this.selectedGroupSubscription = this.groupsvc.selectedGroupSubject.subscribe(grp => {this.updateGroup(grp);});
   }

   ngOnDestroy() {
      this.selectedGroupSubscription.unsubscribe();
   }

   groupChange(g) {
     // console.log('userInfo = ', this.userInfo);
     console.log('groupChange selectedGroup = ', g);
     this.groupsvc.setSelectedGroupUID( g );
   }

   updateGroup(grp) {
     this.selectedGroupObj = grp;
     this.group = grp;
     this.selectedGroup = grp.uid;
     console.log('updateGroup = ', grp);
   }

   toggleMode() {
      if (this.expertMode === false) {
         this.expertMode = true;
      }
      else {
          this.expertMode = false;
      }
    // console.log('this.expertMode=', this.expertMode);
   }

   async createGroupEmit() {
      this.createGroupEvent.emit(true);
   }

   close() {
      this.dialogRef.close();
   }

   async save(launch: boolean) {
      const output = {
         launchUpload: launch,
         groupUID: this.selectedGroup

   };
      // console.log('quick-start: output=', output);
      this.dialogRef.close(output);
    }

    // CJ - Send message to main to updateDocumentList()
    sendUpdateDocList(d) {
        // Send updateDocumentList  event to any subscribers
        this.updateDocListEvent.emit(d);
    }

    handleAddFiles(): void {
      this.uploadFilesEvent.emit({group : this.selectedGroup});
    }

/****
    async handleAddMember(){
       this.emailText = this.emailAddress + '\n';
       // console.log('handleAddMember: ', this.emailText);
       await this.handleAddUserList(true);
    }

    async handleAddUserList(single: boolean) {
      this.newUserCnt = 0;
      this.newUserErr = 0;
      this.newMemberCnt = 0;
      this.newMemberErr = 0;

      let addGroup: GroupData = null;
      let addGroupUID = '';
      let addGroupName = '';

      if (!this.selectedGroup || this.selectedGroup === '') {
        this.stepMessage = 'ERROR: You must select a group first!';
        this.toast.pop('error', 'Add User to Group',
        'Please Select or Create a Group First.');
        return;
      } else {
         addGroupUID = this.selectedGroup;
         addGroup = await this.data.groupList.find(g => g.uid === this.selectedGroup);
         if (!addGroup) {
            this.stepMessage = 'Error cant find group id. Please try again.';
            this.toast.pop('error', 'Add User to Group',
            'Cant find group id, please restart the Quick Start dialog.');
            return;
         } else {
                  addGroupName = addGroup.name;
           }
      }
      const cnt = this.parseEmailAddressList();
      for (const a of this.emailAddressList) {
        try {
           await this.handleAddUser(a, addGroup, single);
           // console.log('addMember x=', x);
           this.stepMessage = 'Added ' + a + '  to ' + addGroupName;
        } catch (error) {
            console.log('quick-start: add group member - promise rejected');
            console.log('quick-start: add group member error=', error);
        }
      }
      // emit refreshSubsEvent
      this.refreshSubsEvent.emit(true);
      if ( this.subList ) {
         if (this.subList.length === this.userInfo.accounts ) {
           this.toast.pop('warn', 'Guest Sub-Accounts',
           'Guest account limit of ' + this.userInfo.accounts + ' has been reached.');
         }
      }
      const d = cnt - this.newUserCnt;
      // console.log('cnt=', cnt);
      // console.log('d=', d);
      // console.log('newUserCnt=', this.newUserCnt);
      // console.log('newUserErr=', this.newUserErr);
      // console.log('newMemberCnt=', this.newMemberCnt);
      // console.log('newMemberErr=', this.newMemberErr);
      if (this.newMemberCnt === cnt) {
         this.stepMessage = 'Added ' + this.newUserCnt + ' guest users and ' + this.newMemberCnt + ' accounts to ' + addGroupName;
         if (!single) {
            this.toast.pop('success', 'Add User to Group',
            'Added ' + this.newUserCnt + ' guest users and ' + this.newMemberCnt + ' accounts to ' + addGroupName);
         }
      } else {
           this.stepMessage = 'Added ' + this.newUserCnt + ' guest users and added ' + this.newMemberCnt;
	   this.stepMessage += ' users to ' + addGroupName + '. Failed to add ' + this.newMemberErr + ' users to the group.';
           if (!single) {
	      const msg = 'Added ' + this.newUserCnt + ' guest users and ' + this.newMemberCnt + ' accounts to ';
	      msg += addGroupName + '. Correct the remaining text in the Address List if necessary and click/tap Add Users again.';
              this.toast.pop('success', 'Add User to Group', msg);
              this.toast.pop('error', 'Add User to Group',
              'Failed to add ' + this.newMemberErr + ' members to ' + addGroupName);
            }
         }
    }

    async handleAddUser(userEmail: string, addGroup: GroupData, verbose: boolean) {
      if (!addGroup) {
         this.toast.pop('error', 'Add User to Group',
         'Cant find group id, please restart the Quick Start dialog.');
         this.stepMessage = 'Error cant find group id. Please try again.';
         return;
      }
      const addGroupName = addGroup.name;
      const addGroupUID = addGroup.uid;
      if ( this.validateEmail(userEmail) === true) {
        const cnt = 1;
        let   nbrAccts = 0;
        this.stepMessage = 'Adding ' + userEmail + ' to the group...';
        // CJ - NOTE - NEED A CHECK HERE ON NBR AVAILABLE SUB ACCTS
        try {
           this.subList = await this.get_subs(this.userInfo.uid);
           nbrAccts = this.subList.length;
           // console.log('hau: subList=', this.subList);
           // console.log('hau: nbrAccts=', nbrAccts);
        } catch (error) {
            this.stepMessage = 'ERROR: Cant get the sub account list.';
            console.log('hau: cant get sublist');
          }
        if ( nbrAccts < this.userInfo.accounts ) {
           try {
              this.stepMessage = 'Adding Sub-Account if New User for ' + userEmail;
              // console.log('addSub userInfo=', this.userInfo);
              const s = await this.addSub(userEmail);
              // console.log('s=', s);
              // refresh the subs list
              // this.refresh_subs(); NEEDS TRY/CATCH
              setTimeout(() => {
              }, 50);
          } catch (error) {
              this.stepMessage = 'Account exists for ' + userEmail + ' or maximum guest accounts exceeded.';
            }
        } else {
            this.stepMessage = 'Guest Account limit reached: ' + this.userInfo.accounts + ' accounts already in use.';
        }

        try {
           const x = await this.addMember(userEmail, addGroup);
           // console.log('addMember x=', x);
           this.stepMessage = 'Added ' + userEmail + ' to ' +
                              addGroupName;
           if (verbose) {
              this.toast.pop('success', 'Add User to Group',
              'Added ' + userEmail + ' to ' + addGroupName);
              }
           this.emailAddress = '';
        } catch (error) {
                console.log('quick-start: add group member error=', error);
                this.stepMessage = 'Error Adding ' + userEmail + ' to '
                                   + addGroupName;
                if (verbose) {
                   this.toast.pop('error', 'Add User to Group',
                   'Failed to add ' + userEmail + ' to ' + addGroupName);
                }
          }
        }
    }

    clearEmailAddress() {
      this.emailAddress = '';
    }
    clearEmailText() {
      this.emailText = '';
    }

    parseEmailAddressList() {
      let junkText = '';
      // console.log(this.emailInput);
      const tmp: string = this.emailText.toString( ).replace(STRIP_CHARS, '').replace(/[\s\b\f\n\r\t\v]+/, ' ');
      // console.log('tmp=', tmp);
      const arr = tmp.split(/[,;]+/);
      const dups = new Array();
      // console.log('parseEmailAddressList=', arr);
      for ( const s of arr ) {
         const e = s.trim();
         // console.log('e=', e);
         if (this.validateEmail(e) === true) {
           dups.push(e);
         } else {
                  junkText += e;
                }
      }
      this.emailText = junkText;
      this.emailAddressList = new Array();
      dups.forEach((c) => {
          if (!this.emailAddressList.includes(c)) {
             this.emailAddressList.push(c);
          }
      });
      const cnt = this.emailAddressList.length;
      // console.log('valid emails=', cnt);
      // console.log('valid junk=', junkText);
      this.stepMessage = 'Found ' + cnt + ' valid email addresses';
      if (junkText !== '') {
        this.stepMessage += ' Did NOT process remaining text.';
        this.emailText = junkText;
        }
      return cnt;
    }

    validateEmail(emailField){
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (reg.test(emailField) === false)
        {
            // alert('Invalid Email Address');
            return false;
        }
        return true;
    }

***/

} // quick-start

  <mat-menu #objMenu="matMenu">
  <button mat-menu-item enabled (click)="toggleDetail()">
    <mat-icon>domain</mat-icon>              
    <span style="font-size: 0.9em">View Details</span>
  </button>
  <button *ngIf="(userData && userData.iam) || (orgUserData && orgUserData.org_admin) && iam"
	  mat-menu-item enabled (click)="editObj()">
          <mat-icon>edit</mat-icon>  
          <span style="font-size: 0.9em">Edit Company/Org.</span>
  </button>
  <button *ngIf="userData && userData.iam && iam"	  
          mat-menu-item enabled (click)="createObj()">
          <mat-icon>add</mat-icon>        
          <span style="font-size: 0.9em">Add New Company/Org.</span>
  </button>
  <button *ngIf="userData && userData.iam && iam"
	  mat-menu-item enabled (click)="deleteObj()">
          <mat-icon>delete</mat-icon>
          <span style="font-size: 0.9em">Delete Company/Org.</span>
  </button>
  </mat-menu>

  
<ng-template #nodata>
   <span>No Data Found</span>
</ng-template>

<ng-template #loading>
  <div>
    <span>Loading Data...</span>
  </div>
</ng-template>

<ng-template #notax>
  <span style="margin-left: 1em">Non-Taxable</span>
</ng-template>

<!-- border: 1px solid #1A567A;  -->
<div>

<div #org *ngIf="template === DisplayTemplate.FIELD_NAME">
 <div *ngIf="(uid && objData$ | async) as obj">
        <mat-form-field class="form-field" [floatLabel]="'always'" appearance="">	  
           <mat-label class="form-label">{{nameLabel}}</mat-label>
           <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
           <input matInput placeholder=""
                  type="text" name="name" class="form-control"
		  [value]="obj.org_name">
        </mat-form-field>
  </div>	
</div>

<div #org *ngIf="template === DisplayTemplate.SIDENAV_NAME">
  <div *ngIf="objData">
    <span class="sidenav-name">{{objData.org_name}}</span>
  </div>
</div>

<div #org *ngIf="template === DisplayTemplate.LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 70vw !important;">
  <div #orgItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em;" (click)="titleAction()">
    <span *ngIf="objData && selectedObj && selectedObj.uid === objData.uid; else defaultObjName" style="display: flex; flex-flow: row; align-content: center; font-size 1.5rem; font-weight: bold; background: #1A567A; color: white; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em; justify-content: space-between;">
    <div style="align-self: flex-start">
        <mat-icon style="margin-right: 1rem; background: #1A567A; color: white; padding-top: 0em;">domain</mat-icon>    		
	{{objData.org_name}}
    </div>
    <mat-icon *ngIf="(objData && selectedObj) && objData.uid===selectedObj.uid"    style="align: end; align-self: end; font-size: 1.5rem; padding-top 0.5em; padding-bottom: 0.5em; padding-left: 0em; padding-right: 0em; background: #1A567A; color: white;">
	  check
    </mat-icon>
    </span>
    
    <ng-template #defaultObjName>
      <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 0.5em; padding-bottom: 0.5em; padding-right: 0.5em;">
        <mat-icon style="margin-top: 1rem; margin-right: 1rem;">domain</mat-icon>    			
        {{objData.org_name}}
      <span *ngIf="isObjAdmin" style="font-size: 0.8rem"> &#9733;</span>
      <span *ngIf="isOrgOwner" style="font-size: 0.8rem"> &#9733;</span>      
       </span>
    </ng-template>
    <button *ngIf="showDeleteButton"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.SELECT_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;">
  <div #grpItem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0em; margin-top: 0em; margin-bottom: 0em" (click)="titleAction()">
     <mat-icon *ngIf="objData && objData.org_uid" style="padding-left: 0.5rem; padding-top: 0.5em; padding-right: 0.5em">domain</mat-icon>    
    <span *ngIf="selectedObj && selectedObj.uid === objData.uid; else slNotSelected"
	  style="font-size 1.5rem; font-weight: bold; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      <mat-icon style="margin-top: 0.8rem; margin-right: 1rem; background: #1A567A; color: white;">domain</mat-icon>
      {{objData.org_name}}
      <span *ngIf="isObjAdmin" style="font-size: 0.8rem"> &#9733;</span>
      <span *ngIf="isOrgOwner" style="font-size: 0.8rem"> &#9733;</span>      
    </span>
    <ng-template #slNotSelected>
      <span style="font-size 1.5rem; font-weight: normal; background: white; color: #1A567A; width: 100%; padding-left: 0.7em; padding-top: 1em; padding-bottom: 1em; padding-right: 0.5em;" (click)="setSelectListObj($event)">
      <mat-icon style="margin-top: 0.8rem; margin-right: 1rem; background: #1A567A; color: white;">domain</mat-icon>     	
      {{objData.org_name}}
      <span *ngIf="isObjAdmin" style="font-size: 0.8rem"> &#9733;</span>
      <span *ngIf="isOrgOwner" style="font-size: 0.8rem"> &#9733;</span>      
    </span>
    </ng-template>
    <mat-icon *ngIf="selectedObj && selectedObj.uid === objData.uid"
	      style="align-self: center;padding-left: 0.4em; padding-right: 0.5em; padding-top: 0.525em; padding-bottom: 0.7em; background: white; color: #1A567A;">
	check
    </mat-icon>
    <button *ngIf="showDeleteButton && userData.iam && iam"
	    mat-stroked-button (click)="deleteObjDialog()"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; padding-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon  style="background: white; color: red;">delete</mat-icon>
    </button>
    <button *ngIf="showMenuButton" mat-stroked-button
	    [matMenuTriggerFor]="objMenu"
            style="align-self: center;margin-left: 1em; margin-right: 1em; padding-right: 0.5em; margin-top: 0.2em; padding-bottom: 0.2em; background: white; color: #1A567A;">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
</div>

<div #grp *ngIf="template === DisplayTemplate.EXPAND_LIST"
     style="display: flex; flex-flow: row; justify-content: space-between; align-content: center; min-width: 75vw !important; height: 100% !important;" (click)="setSelectedObj()">
  <div #orgitem style="display: flex; flex-flow: row; justify-content: flex-start; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="setSelectedObj()">
    <button mat-stroked-button
            (click)="toggleDetail()"
      style="margin-right: 1.5em; color: white; background-color: #2274A5;">
      <mat-icon>expand_more</mat-icon>
    </button>
    <span *ngIf="orgData && selectedOrg && selectedOrg.uid === orgData.uid; else defaultOrgNbr"
          (click)="setSelectedObj()"
          style="font-weight: bold; color: #2274A5; margin-right: 1rem">
          {{objData.org_client_nbr}}
    </span>
    <ng-template #defaultOrgNbr>
       <span (click)="setSelectedObj()"
          style="font-size: 1rem; font-weight: normal; color: black; margin-right: 1rem">
          {{objData.org_client_nbr}}
      </span>
    </ng-template>
    <span *ngIf="orgData && selectedOrg && selectedOrg.uid === orgData.uid; else defaultOrgName"
          style="font-size 1rem; font-weight: bold; color: #2274A5; width: 100%">
          {{objData.org_name}}
    </span>
    <ng-template #defaultOrgName>
       <span style="font-size: bold; font-weight: normal; color: black; width: 100%" (click)="setSelectedObj()"> 
         {{objData.org_name}}
       </span>
    </ng-template>
  </div>

  <div #objItemButtons (click)="setSelectedObj()"
       style="display: flex; flex-flow: row; justify-content: space-around; align-content: center; width: 100% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em;">
  <mat-icon style="color: #2274A5; align-self: flex-end;" *ngIf="selectedObj && selectedObj.uid === objData.uid">check</mat-icon>    
 </div>
</div>

<!-- border: 1px solid #1A567A;  -->
<div #objdetail *ngIf="template === DisplayTemplate.DETAIL"
     style="display: flex; flex-flow: column; justify-content: space-between; align-items: space-between; width: 90% !important; height: 100% !important; margin-left: 0.7em; margin-top: 1em; margin-bottom: 1em" (click)="toggleDetail()">
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
    <button *ngIf="this.mode === DisplayMode.LIST || this.mode === DisplayMode.EXPAND_LIST"
	  mat-stroked-button
          (click)="toggleDetail()"
          style="margin-right: 1.5em;">
    <mat-icon>expand_less</mat-icon>
    </button>
    <span style="margin-right: 1rem">{{objData.org_client_nbr}}</span>
    <span style="width: 100%;">{{objData.org_name}}</span>
    <mat-icon *ngIf="objData && selectedObj && (objData.uid===selectedObj.uid)"
        style="margin-left: 0.5em; margin-right: 0.4em; color: #2274A5">
	check
    </mat-icon>
  </div>
  <span style="margin-left: 1.5em; width: 100%"></span><br>
 <div style="display: flex; flex-flow: column; margin-left: 0.5em; margin-right: 1em; border: 1px solid #1A567A; width: 95%% !important;">  
  <div style="display:flex; flex-flow: row; justify-content: flex-start; margin-left: 0.5em">
  </div>
  <div style="display:flex; flex-flow: column; justify-content: space-between; margin-left: 0.5em">
    <span style="">Contact</span>
    <span style="">Email :{{objData.org_owner_email}}</span>
    <span style="">Name  : {{objData.org_owner_name}}</span>
    <span style="">Phone : {{objData.org_owner_phone}}</span>
    <span style="">Mobile: {{objData.org_owner_mobile_phone}}</span>
    <span style="">Fax   : {{objData.org_owner_fax}}</span><br>            
    <span style="">Addr  : {{objData.org_addr}}</span>
    <span style="">Addr2 : {{objData.org_addr_2}}</span>
    <span style="">City  : {{objData.org_city}}</span>
    <span style="">State : {{objData.org_state}}</span>
    <span style="">Zip: {{objData.org_postal_code}}</span>                    
  </div>
  <span style=""></span><BR>
  <div *ngIf="userData && userData.iam && iam">
    <span style="color: gray">ADMIN/MGR INFORMATION</span><BR><BR>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Type:</span>
       <span style="margin-left: 1em">{{objData.org_type}}</span>
    </div>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Category:</span>      
       <span style="margin-left: 1em">{{objData.org_category}},</span>
    </div>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Sales Tax:</span>           
       <span *ngIf="objData.org_taxable; else notax"
	     style="margin-left: 1em">Taxable</span>
    </div>
    <div style="display: flex; flex-flow: row; align-items: center">
       <span style="color: gray">Sales Ref Agent:</span>           
       <span style="margin-left: 1rem">{{objData.org_sales_referral_email}}
       </span>
    </div>
    <span style="color: gray">Limits:</span><BR>
    <span style="margin-left: 1rem">Employees: {{objData.org_ee_account_limit}}
    </span>
    <span style="margin-left: 1rem">Guests: {{objData.org_guest_account_limit}}
    </span>
    <span style="margin-left: 1rem">Storage: {{objData.org_storage_limit}}GB
    </span>
    <span style=""></span><BR>
  </div>
 </div>
</div>

<div #orgform *ngIf="template===DisplayTemplate.FORM">
 <div 
     style="display: flex; flex-flow: column; justify-content: space-around; align-content; center; min-width: 90vw; margin-left: 1rem; margin-right: 1rem; overflow: hidden !important; margin-bottom: 7rem;">  

  <div *ngIf="showFormTitle">
   <div class="form-title" *ngIf="isAddMode">Create {{objLabel}}</div>
   <div class="form-title" *ngIf="mode === DisplayMode.EDIT">Edit {{objLabel}}</div>
   <div class="form-title" *ngIf="mode === DisplayMode.VIEW">View {{objLabel}}</div>  
  </div>
    
  <form id="objForm" [formGroup]="objForm">

   <!--
   <mat-tab-group style="overflow: hidden !important;">
    
    <mat-tab class="form-tab" label="Info">
      -->
      
     <ng-template mat-tab-label>
        <mat-icon>domain</mat-icon>
	Setup
    </ng-template>
     
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [class.cdk-visually-hidden]="!showHidden" [style.display]="'none'" [floatLabel]="'always'">
      <mat-label class="form-label">UID</mat-label>      
      <input matInput placeholder="UID" [hidden]="!showHidden" 
             type="text" name="uid" class="form-control" formControlName="uid">
    </mat-form-field>
    
    <mat-form-field class="form-field-short-disabled"
		    [appearance]="formFieldStyle"
		    [floatLabel]="'always'"
		    [class.cdk-visually-hidden]="!userData.iam && !this.iam">
      <mat-label class="form-label">Clt #</mat-label>
      <input matInput placeholder="Client #" maxlength="10" size="10"
             type="text" name="org_client_nbr" class="form-control"
	     formControlName="org_client_nbr">
    </mat-form-field>  

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company/Org. Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
      <input matInput placeholder="Company/Org. Name"
             type="text" name="org_name" class="form-control" formControlName="org_name">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_name.touched || submitted) && objForm.controls.org_name.errors?.required">
	Company/Org. Name is Required or Format Error!</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Domain Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
      <input matInput placeholder="Primary Domain Name"
             type="text" name="org_primary_domain_name" class="form-control" formControlName="org_primary_domain_name">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_primary_domain_name.touched || submitted) && objForm.controls.org_primary_domain_name.errors?.required">
	Primary Domain Name is Required or Error in Format!</span>	
    </mat-error>
    
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company Address</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>
      <input matInput placeholder="Address"
             type="text" name="org_addr" class="form-control" formControlName="org_addr">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_addr.touched || submitted) && 
                objForm.controls.org_addr.errors?.required">
	Address is required.</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company Address Line 2</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>      
      <input matInput placeholder="Address Line 2"
             type="text" name="org_addr_2" class="form-control" formControlName="org_addr_2">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_addr_2.touched || submitted) && 
                objForm.controls.org_addr_2.errors?.required">
	Address line 2 is required.</span>	
    </mat-error>


    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">City</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>      
      <input matInput placeholder="City"
             type="text" name="org_city" class="form-control" formControlName="org_city">
    </mat-form-field>  
    <mat-error class="form-error">
      <span class="form-error"	
	*ngIf="(objForm.controls.org_city.touched || submitted) && objForm.controls.org_city.errors?.required">
	Please enter city for the main location.</span>	
    </mat-error>

    <!--
    <mat-form-field [formGroup]="objForm" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">US State Code</mat-label>
      <mat-select
	 name="org_state"	
         formControlName="org_state"
         placeholder="US State Code"
	 [required]="'true'">
         <mat-option class="form-option"
		    *ngFor="let state of stateCodes" [value]="state.code">
           {{state.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>
    <mat-error>
      <span class="form-error"
           *ngIf="(objForm.controls.org_state.touched || submitted) &&
                   objForm.controls.org_state.errors?.required">
           A Category is Required.</span>
    </mat-error>
    -->
    
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">State/Province/Region</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>      
      <input matInput placeholder="Enter State/Province"
             type="text" name="org_state" class="form-control" formControlName="org_state">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_state.touched || submitted) && 
                objForm.controls.org_state.errors?.required">
	State/Province/Region is Required.</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Zip/Postal Code</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">domain</mat-icon>      
      <input matInput placeholder="Zip Code/Postal Code"
             type="text" name="org_postal_code" class="form-control" formControlName="org_postal_code">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_postal_code.touched || submitted) && 
                objForm.controls.org_postal_code.errors?.required">
	Postal Code Required.</span>	
    </mat-error>

    <mat-form-field [formGroup]="objForm" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Country</mat-label>
      <mat-icon matPrefix style="font-size: 2rem; margin-right: 1rem">domain</mat-icon>            
      <mat-select class="form-select"
	 name="org_country_code"	
         formControlName="org_country_code"
         placeholder="Select a Country"
	 [required]="'true'">
         <mat-option class="form-option"
	    *ngFor="let country of countryCodes" [value]="country.code">
           {{country.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>
    <mat-error>
      <span class="form-error"
           *ngIf="(objForm.controls.org_country_code.touched || submitted) &&
                   objForm.controls.org_country_code.errors?.required">
           Country is Required</span>
    </mat-error><br>
    
    <mat-form-field [formGroup]="objForm" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Org. Type</mat-label>
      <mat-icon matPrefix style="font-size: 2rem; margin-right: 1rem">domain</mat-icon>            
      <mat-select class="form-select"
	 name="org_type"	
         formControlName="org_type"
         placeholder="Type of Business/Org."
	 [required]="'true'">
         <mat-option class="form-option"
		    *ngFor="let type of orgTypes" [value]="type.code">
           {{type.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>
    <mat-error>
      <span class="form-error"
           *ngIf="(objForm.controls.org_type.touched || submitted) &&
                   objForm.controls.org_type.errors?.required">
           Business/Org. Type is Required</span>
    </mat-error><br>

    <mat-form-field [formGroup]="objForm" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Bus. Category</mat-label>
      <mat-icon matPrefix style="font-size: 2rem; margin-right: 1rem">domain</mat-icon>      
      <mat-select class="form-select"
	 name="org_category"	
         formControlName="org_category"
         placeholder="Line of Business/Sector"
	 [required]="'true'">
         <mat-option class="form-option"
		    *ngFor="let cat of orgCategories" [value]="cat.code">
           {{cat.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>
    <mat-error>
      <span class="form-error"
           *ngIf="(objForm.controls.org_category.touched || submitted) &&
                   objForm.controls.org_category.errors?.required">
           A Category is Required.</span>
    </mat-error>

    <mat-form-field *ngIf="userData.iam && iam"  class="form-field"
		    [appearance]="formFieldStyle" [floatLabel]="'always'"
		    [class.cdk-visually-hidden]="!userData.iam && !this.iam">
      <mat-label class="form-label">Sales Referral Email</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Sales Referral Email"
             type="email" name="org_sales_referral_email" class="form-control" formControlName="org_sales_referral_email">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_sales_referral_email.touched || submitted) && 
               (objForm.controls.org_sales_referral_email.errors?.required)">
	Sales Referral Email is Required</span>
    </mat-error><br>


    <mat-form-field *ngIf="userData.iam && iam"
		    [formGroup]="objForm" [appearance]="formFieldStyle"
		    [floatLabel]="'always'"
		    [class.cdk-visually-hidden]="!userData.iam && !this.iam">
      <mat-label class="form-label">Status</mat-label>
      <mat-icon matPrefix style="font-size: 2rem; margin-right: 1rem">domain</mat-icon>            
      <mat-select class="form-select"
	 name="org_status"
         formControlName="org_status"
         placeholder="Company/Org. Status"
	 [required]="'true'">
         <mat-option class="form-option"
	    *ngFor="let status of statusCodes" [value]="status.code">
           {{status.name}}
         </mat-option>
       </mat-select>
    </mat-form-field>
    <mat-error>
      <span class="form-error"
           *ngIf="(objForm.controls.org_country_code.touched || submitted) &&
                   objForm.controls.org_country_code.errors?.required">
           Country is Required</span>
    </mat-error><br>
    
    <span style="margin-bottom: 0.3rem;">
          Primary Contact/Owner Information</span><br>
     
    <mat-form-field class="form-field" style="margin-top: 0.5rem"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact/Owner Email</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Enter Email Address"
             type="email" name="org_owner_email" class="form-control" formControlName="org_owner_email">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_owner_email.touched || submitted) && 
               (objForm.controls.org_owner_email.errors?.required)">
	Contact Email is Required</span>
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Enter First Name"
             type="text" name="org_owner_name" class="form-control" formControlName="org_owner_name">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_owner_name.touched || submitted) && 
               (objForm.controls.org_owner_name.errors?.required)">
	Contact First Name is Required</span>	
    </mat-error><br>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact Last Name</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Enter Last Name"
             type="text" name="org_owner_last_name" class="form-control" formControlName="org_owner_last_name">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_owner_last_name.touched || submitted) &&
               (objForm.controls.org_owner_last_name.errors?.required)">
	Contact Last Name is Required</span>	
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact Phone Nbr.</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Enter Phone Nbr."
             type="tel" name="org_owner_phone" class="form-control" formControlName="org_owner_phone">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_owner_phone.touched || submitted) &&
               (objForm.controls.org_owner_phone.errors?.required ||
                objForm.controls.org_owner_phone.errors?.pattern)">
	 Enter a correctly formatted phone nbr.</span>
    </mat-error>

    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact Mobile Phone</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Enter Mobile Phone Nbr."
             type="tel" name="org_owner_mobile_phone" class="form-control" formControlName="org_owner_mobile_phone">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_owner_mobile_phone.touched || submitted) &&
               (objForm.controls.org_owner_mobile_phone.errors?.required ||
                objForm.controls.org_owner_mobile_phone.errors?.pattern)">
	 Enter a correctly formatted phone nbr.</span>
    </mat-error>
    
    <mat-form-field class="form-field" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Primary Contact Fax Nbr.</mat-label>
      <mat-icon matPrefix style="margin-right: 1rem">person</mat-icon>      
      <input matInput placeholder="Enter Fax Nbr."
             type="tel" name="org_owner_fax" class="form-control" formControlName="org_owner_fax">
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	*ngIf="(objForm.controls.org_owner_fax.touched || submitted) &&
               (objForm.controls.org_owner_fax.errors?.required ||
                objForm.controls.org_owner_fax.errors?.pattern)">
	 Enter a correctly formatted phone nbr.</span>
    </mat-error>

    <mat-divider></mat-divider>
    <br>
    <span style="margin-bottom: 0.5rem; margin-right: 2rem; font-weight: bold">Company/Org/Team Subscription Based Limits</span>
    <button mat-stroked-button class="submit-btn" (click)="onCancel()">
            View/Update Subscription</button>
    <br>
    <br>

    <!---
      <span style="margin-bottom: 0.5rem; color: red;">IAM Mode: {{iam}}</span><br>
      <span style="margin-bottom: 0.5rem; color: red;">User: {{userData.user}} User IAM: {{userData.iam}}</span><br>
    ---->

    <div *ngIf="iam && userData.iam" style="text-align: left;">
      <br>
      <mat-divider></mat-divider>
      <span style="margin-bottom: 0.5rem; font-weight: bold; color: red;">WARNING: YOU ARE IN APP. MANAGER (superuser) MODE!! </span><br><br>
      <span style="margin-bottom: 0.5rem; color: red;">NOTICE and BE AWARE!!! Any changes to Limits, Quota, or Expiration will override the limits set by currently active subscription!!!</span><br><br>
      <span style="margin-bottom: 0.5rem; text-style: bold; color: red;">DO NOT: reduce any of the current limits or quotas under any circumstances!!!</span><br><br>
      <span style="margin-bottom: 0.5rem; text-style: underline;">IF NECESSARY: increase the values to resolve a support or subscription issue</span><br>
      <span style="margin-bottom: 0.5rem; text-style: underline;">BEFORE UPDATING: make a record of the current and updated values along with the subscription type for followup"</span><br><br>
      <span style="margin-bottom: 0.5rem; color: red;">NOTE: Any updates remain in effect until the subscription is changed by the user or updated by automatic subcription renewal/payment process that may be monthly or annual updates!</span><br>
      <mat-divider></mat-divider>      
      <br>
    </div>

    <br>
    
    <mat-form-field class="form-field-medium-box"
		    [class.cdk-visually-hidden]="!showHidden"
		    [style.display]="'none'"
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Employee/Member Account Limit</mat-label>
      <input matInput placeholder="Emp. Acct. Limit" maxlength="7" size="7"
             type="number" name="org_ee_account_limit" class="form-control"
	     formControlName="org_ee_account_limit">
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <mat-hint style="margin-top: 0; padding-top: 0;">	
       <ng-container *ngIf="orgEmpAcctsUsed$ | async as empAcctsUsed">
         {{empAcctsUsed.count}} Employee/Member Accounts In Use
       </ng-container>
      </mat-hint> 
    </mat-form-field>
    <mat-error>
      <span class="form-error"      
          n *ngIf="(objForm.controls.org_ee_account_limit.touched || submitted) && 
                   (objForm.controls.org_ee_account_limit.errors?.required ||
                    objForm.controls.org_ee_account_limit.errors?.min)">
	Emp/Member Account Limit - a number is required >= 0</span>
    </mat-error>
    <br>

    <mat-form-field class="form-field-medium-box" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Active Employee/Member Account Limit</mat-label>      
      <input matInput placeholder="Emp. Acct. Limit" maxlength="7" size="7"
             type="number" name="org_active_ee_account_limit" class="form-control"
	     formControlName="org_active_ee_account_limit">
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
      <mat-hint style="margin-top: 0; padding-top: 0;">	
       <ng-container *ngIf="orgEmpAcctsUsed$ | async as empAcctsUsed">
         {{empAcctsUsed.count}} Employee/Member Accounts In Use
       </ng-container>
      </mat-hint> 
    </mat-form-field>  
    <mat-error>
      <span class="form-error"      
          n *ngIf="(objForm.controls.org_active_ee_account_limit.touched || submitted) && 
                   (objForm.controls.org_active_ee_account_limit.errors?.required ||
                    objForm.controls.org_active_ee_account_limit.errors?.min)">
	Active Emp/Member Account Limit - a number is required >= 0</span>
    </mat-error>
    <br>
    
    <mat-form-field class="form-field-medium-box"
		    [class.cdk-visually-hidden]="!showHidden"
		    [style.display]="'none'"		    
		    [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Guest Account Limit</mat-label>
      <input matInput placeholder="Guest Acct. Limit"
             type="number" name="org_guest_account_limit" class="form-control"
	     formControlName="org_guest_account_limit">
      <mat-hint style="margin-top: 0; padding-top: 0;">
        <ng-container *ngIf="orgGuestAcctsUsed$ | async as guestAcctsUsed">
           {{guestAcctsUsed.count}} Active Guest Accounts in Use
        </ng-container>
        <ng-container *ngIf="orgGuestRecords$ | async as guestUserRecords">
           , {{guestUserRecords.count}} Guest User Records on File
        </ng-container>
       </mat-hint>
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	    *ngIf="(objForm.controls.org_guest_account_limit.touched ||
		    submitted) &&
            (objForm.controls.org_guest_account_limit.errors?.required ||
             objForm.controls.org_guest_account_limit.errors?.min)">
	     Guest Account Limit - a number is required >= 0</span>
    </mat-error>
    <br>

    <mat-form-field class="form-field-medium-box" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Active Guest Account Limit</mat-label>
      <input matInput placeholder="Active Guest Acct. Limit"
             type="number" name="org_active_guest_account_limit" class="form-control"
	     formControlName="org_active_guest_account_limit">
      <mat-hint style="margin-top: 0; padding-top: 0;">
        <ng-container *ngIf="orgGuestAcctsUsed$ | async as guestAcctsUsed">
           {{guestAcctsUsed.count}} Guest Accounts in Use
        </ng-container>
      </mat-hint>  
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	    *ngIf="(objForm.controls.org_active_guest_account_limit.touched || submitted) &&
	   (objForm.controls.org_active_guest_account_limit.errors?.required ||
            objForm.controls.org_active_guest_account_limit.errors?.min)">
	     Active Guest Account Limit - a number is required >= 0</span>
    </mat-error>
    <br>    
    
    <mat-form-field class="form-field-medium-box" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Storage Limit (Quota in GB)</mat-label>
      <input matInput placeholder="Guest Acct. Limit"
             type="number" name="org_storage_limit" class="form-control"
	     formControlName="org_storage_limit">
      <!--- <span matSuffix>GB</span> 
      <span *ngIf="orgStorageUsed$ | async as orgStorageUsed" matSuffix style="margin-left: 2rem;">
           {{ (((orgStorageUsed.sum / 1024) / 1024) / 1024) | number: '1.1-2' }} GB Used	
      </span>
      --->
      <mat-hint style="margin-top: 0; padding-top: 0;">      
        <ng-container *ngIf="orgStorageUsed$ | async as orgStorageUsed">
           {{ (((orgStorageUsed.sum / 1024) / 1024) / 1024) | number: '1.1-2' }} GB Used
        </ng-container>
      </mat-hint>  
    </mat-form-field>  
    <mat-error>
      <span class="form-error"
	    *ngIf="(objForm.controls.org_storage_limit.touched || submitted) &&
                   (objForm.controls.org_storage_limit.errors?.required || 
                    objForm.controls.org_storage_limit.errors?.min)">
	Storage Limit - a number is required >= 30</span>
    </mat-error>
    <br>

    <mat-form-field class="form-field-medium-box" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Company/Org/Team Subscription Expires</mat-label>
      <input matInput placeholder="Renewal/Expiration Date" 
             type="datetime-local" name="expires" class="form-control"
	     formControlName="expires">
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    </mat-form-field>  
    <mat-error>
      <span class="form-error"      
            *ngIf="(objForm.controls.expires.touched || submitted) && 
                   (objForm.controls.expires.errors?.required ||
                    objForm.controls.expires.errors?.dateTime ||
                    objForm.controls.expires.errors?.min)">
	    Expiration date must by mm/dd/yy hh:mm(:ss)!</span>
    </mat-error>
    <br>
    
    <mat-form-field class="form-field-short" [appearance]="formFieldStyle" [floatLabel]="'always'">
      <mat-label class="form-label">Record Created</mat-label>
      <input matInput placeholder="Created" 
             type="datetime-local" name="created" class="form-control"
	     formControlName="created">
      <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
    </mat-form-field>  
    <mat-error>
      <span class="form-error"      
            *ngIf="(objForm.controls.created.touched || submitted) && 
                   (objForm.controls.created.errors?.required  )">
	  Date created is required.
	  </span>
    </mat-error>

  <div class="form-bottom-div">
    <mat-divider></mat-divider>
    <span class="form-error"
    *ngIf="objForm && objForm.valid===false && submitted===true">
      Please fix errors on one or more tabs and try again
    </span>

    <div *ngIf="showActions" class="form-btn-div">
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="prevObj()">
          <mat-icon>arrow_back_ios</mat-icon>
       </button>
       <button mat-stroked-button class="submit-btn" (click)="onCancel()">
               Cancel</button>
       <button *ngIf="mode === DisplayMode.ADD"
	       mat-stroked-button class="normal-btn" (click)="resetForm()">
               Reset</button>
       <button *ngIf="mode !== DisplayMode.VIEW" class="submit-btn"
	       mat-stroked-button class="submit-btn" (click)="onSubmit()">
         Submit</button>
<!--       *ngIf="objData && userData && getIsObjAdmin(objData, userData) && (mode === DisplayMode.VIEW)" class="submit-btn" -->
       <button *ngIf="isObjAdmin && mode !== DisplayMode.EDIT && !isAddMode"
               mat-stroked-button class="submit-btn"(click)="editObj(true)">
               Edit</button>
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="nextObj()">
         <mat-icon>arrow_forward_ios</mat-icon>
         </button>
    </div>
  </div>    

    <!--
    </mat-tab>
    
    <mat-tab class="form-tab" label="Users">
      <ng-template mat-tab-label>
       <mat-icon>manage_accounts</mat-icon>
       {{objLabel}} Users
      </ng-template>
         <app-org-user-list #orgUserList
	   style="width: 100%"
           [mode]="'S'"
	   [userInfo]="userInfo"
           [userData]="userData"
           [showSearchButton]="false"
           [showSelectButton]="false"
           [showMenuButton]="true"
           [showListViewButton]="true"			    
           [selectedOrg]="selectedObj"
	   >
       </app-org-user-list>
    </mat-tab>

    <mat-tab class="form-tab" label="Info">
      <ng-template mat-tab-label>
       <mat-icon>contact_mail</mat-icon>
       Contacts
      </ng-template>
    </mat-tab>

  </mat-tab-group>
  -->
  <!---
  <div class="form-bottom-div">
    <mat-divider></mat-divider>
    <span class="form-error"
    *ngIf="objForm && objForm.valid===false && submitted===true">
      Please fix errors on one or more tabs and try again
    </span>

    <div *ngIf="showActions" class="form-btn-div">
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="prevObj()">
          <mat-icon>arrow_back_ios</mat-icon>
       </button>
       <button mat-stroked-button class="submit-btn" (click)="onCancel()">
               Cancel</button>
       <button *ngIf="mode === DisplayMode.ADD"
	       mat-stroked-button class="normal-btn" (click)="resetForm()">
               Reset</button>
       <button *ngIf="mode !== DisplayMode.VIEW" class="submit-btn"
	       mat-stroked-button class="submit-btn" (click)="onSubmit()">
         Submit</button>
       <button *ngIf="isObjAdmin && mode !== DisplayMode.EDIT && !isAddMode"
               mat-stroked-button class="submit-btn"(click)="editObj(true)">
               Edit</button>
       <button  *ngIf="!isAddMode"
          mat-stroked-button class="normal-btn" (click)="nextObj()">
         <mat-icon>arrow_forward_ios</mat-icon>
         </button>
    </div>
  </div>
  --->
  </form>
 </div>
</div>

 <!-- Delete Template -->
 <div #orgDelete *ngIf="template === DisplayTemplate.DELETE">
        <div *ngIf="selectedObj; else noObjSelected"
	     class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Delete Company/Org. {{selectedObj.org_name}}
	  </span>
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Are You Sure?
	  </span>
        </div>
	<ng-template #noObjSelected>
	  <div class="delete-msg">
          <span id="delete-text" style="margin-top: 2em; margin-left: 30%; font-size: 1em;">
	    Error: No Company/Org. selected!
	  </span>
          </div>
	</ng-template>
        <div class="form-btn-div">
             <button mat-stroked-button id="cancel-delete-obj" class="normal-btn" type="button" value="Cancel" (click)="onCancel()">Cancel</button>	  
             <button mat-stroked-button *ngIf="selectedObj" id="delete-obj" class="delete-btn" type="submit" value="Yes" (click)="deleteObjData(selectedObj.uid)">Yes</button>
        </div>
 </div>

<!-- org component -->    

  <mat-menu #objMenu="matMenu">
    <button mat-menu-item enabled (click)="setSelectedObj(null)">
          <mat-icon>search_off</mat-icon>  
          <span style="font-size: 0.9em">Clear Selection</span>
    </button>
    <button mat-menu-item enabled (click)="refreshList()">
          <mat-icon>refresh</mat-icon>  
          <span style="font-size: 0.9em">Refresh List</span>
    </button>
   <button *ngIf="userData.iam"
	    mat-menu-item  (click)="createObj()">
     <mat-icon>add</mat-icon>        
     <span style="font-size: 0.9em">Add New Company/Org.</span>
   </button>
   <button mat-menu-item disabled="{{!selectedObj}}" (click)="editObj()">
      <mat-icon>edit</mat-icon>  
      <span style="font-size: 0.9em">Edit Company/Org. Info</span>
   </button>
   <button mat-menu-item disabled="{{!selectedObj}}" (click)="viewObj()">
      <mat-icon>domain</mat-icon>  
      <span style="font-size: 0.9em">View Company/Org. Info</span>
   </button>
   <!---
   <button *ngIf="userData.iam"
	  mat-menu-item disabled (click)="deleteOrg()">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.9em">Delete Company/Org.</span>
  </button>
   --->
  </mat-menu>

  <mat-menu #listViewMenu="matMenu">
    <button mat-menu-item enabled (click)="setMode(DisplayMode.COMBO)">
          <mat-icon>input</mat-icon>  
          <span style="font-size: 0.9em">Combo Select</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT)">
          <mat-icon>arrow_drop_down</mat-icon>  
          <span style="font-size: 0.9em">Select</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.SELECT_LIST)">
          <mat-icon>view_list</mat-icon>  
          <span style="font-size: 0.9em">Select List</span>
    </button>
    <button mat-menu-item enabled (click)="setMode(DisplayMode.TABLE); refreshList()">
          <mat-icon>table_rows_narrow</mat-icon>  
          <span style="font-size: 0.9em">Table</span>
    </button>
  </mat-menu>
  
  <mat-menu #rowMenu="matMenu">    
    <ng-template matMenuContent let-obj="obj">
      <button mat-menu-item (click)="setSelectedObj(obj); viewObj()">
         <mat-icon>domain</mat-icon>  
         <span style="font-size: 0.9em">View Info</span>
      </button>
<!--      *ngIf="getIsObjAdmin(obj, userData)" -->
      <button *ngIf="isObjAdmin"
	      mat-menu-item (click)="setSelectedObj(obj); editObj()">
         <mat-icon>edit</mat-icon>  
         <span style="font-size: 0.9em">Edit {{objLabel}}</span>
      </button>
      <button *ngIf="isObjAdmin"
	      mat-menu-item (click)="setSelectedObj(obj); deleteObj()">
         <mat-icon>delete</mat-icon>
         <span style="font-size: 0.9em">Delete {{objLabel}}</span>
      </button>
    </ng-template>    
  </mat-menu>
  
  <mat-menu #objListMenu="matMenu">
    <button mat-menu-item enabled (click)="setSelectedObj(null)">
          <mat-icon>search_off</mat-icon>  
          <span style="font-size: 0.9em">Clear Selection</span>
    </button>
    <button mat-menu-item enabled (click)="refreshList()">
          <mat-icon>refresh</mat-icon>  
          <span style="font-size: 0.9em">Refresh List</span>
    </button>
    <!-- *ngIf="userData.iam" -->
   <button mat-menu-item  (click)="createObj()">
     <mat-icon>add</mat-icon>        
     <span style="font-size: 0.9em">Add {{objLabel}}</span>
   </button>
   <button *ngIf="selectedObj && selectedObj !== null"
	   mat-menu-item disabled="{{!selectedObj}}" (click)="editObj()">
      <mat-icon>edit</mat-icon>  
      <span style="font-size: 0.9em">Edit {{objLabel}}</span>
   </button>
   <button *ngIf="selectedObj && selectedObj !== null"   
            mat-menu-item disabled="{{!selectedObj}}" (click)="viewObj()">
      <mat-icon>domain</mat-icon>  
      <span style="font-size: 0.9em">View {{objLabel}}</span>
   </button>
   <!---
   <button *ngIf="userData && userData.uid && selectedObj && (selectedObj['owner']===userData.uid)"
	  mat-menu-item (click)="deleteObj()">
    <mat-icon>delete</mat-icon>
    <span style="font-size: 0.9em">Delete {{objLabel}}</span>
  </button>
    --->
  </mat-menu>

  <ng-template #hidden>
  </ng-template>

  <ng-template #loading>
    <div>Loading...</div>
  </ng-template>

  <div #orgTab>
     <app-org-list #orgList class="left-content-scroll"
		     style="margin-top: 1rem; margin-left: 1rem; padding: 0; width: 80vw; max-width: 99vw; height: 100%: overflow-y: auto"
	 [iam]="iam"
         [mode]="'C'"
         [userData]="userData"		     
         [userInfo]="userInfo"
         [showSearchButton]="false"
         [showSelectButton]="false"
         [showAddButton]="true"
         [showEditButton]="false"
         [showDeleteButton]="false"		
         [showMenuButton]="false"		
         [showListViewButton]="true"
         [selectedObj]="selectedOrg"
         [objData]="objData"
	 (refreshListEvent)="refreshList()"
         (selectObjEvent)="setSelectedOrg($event)"
         (selectOrgEvent)="setSelectedOrg($event)"    		   
         >
<!--                [titleFilter]="titleFilter.value" -->
     </app-org-list>
  <mat-tab-group style="overflow: hidden !important;" (selectedTabChange)="onTabChange($event)" [(selectedIndex)]="currentTab">
    <mat-tab *ngIf="selectedOrg" class="form-tab">
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">domain</mat-icon>
        Settings
      </ng-template>
       <mat-list>      
       <mat-divider></mat-divider>
       <div *ngIf="selectedOrg">
       <app-org #objFormItem
          style="width: 100%"
           [iam]="iam"		
           [mode]="DisplayMode.VIEW"
	   [userInfo]="userInfo"
           [userData]="userData"
           [objData]="selectedOrg"
           [selectedObj]="selectedOrg"
           [selectedOrg]="selectedOrg"		
           [useMenuActionDialog]="useMenuActionDialog"
           [showActions]="showActions"
           [doSubmit$]="doSubmitTab$[0]"
	   [doReset$]="doResetTab$[0]"
           [doEdit$]="doEditTab$[0]"
           [doView$]="doView$"
           (modeEvent)="setMode($event)"		
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedOrg($event)"
	   (deleteObjEvent)="onDeleteObj($event)"		
           (submitEvent)="onSubmit($event)"
	   (cancelEvent)="onDone()">
        </app-org>
        <mat-divider [inset]="true"></mat-divider>	
        </div>     
        <mat-divider style="color: gray; min-width: 100%"></mat-divider>
      </mat-list>
    </mat-tab>
    <mat-tab *ngIf="selectedOrg" class="form-tab"> 
      <ng-template mat-tab-label>
         <mat-icon class="tab-icon">group</mat-icon>
          Users
      </ng-template>
      <div *ngIf="selectedOrg">
       <div *ngIf="orgUserMode!==DisplayMode.VIEW && orgUserMode!==DisplayMode.EDIT">
	 <!---           [objData]="selectedOrgUser"  -->
       <app-org-user-list #objFormItem
           style="width: 100%"
           [iam]="iam"			  
           [mode]="'SL'"
	   [userInfo]="userInfo"
           [userData]="userData"
           [selectedObj]="selectedOrgUser"
           [selectedOrg]="selectedOrg"
           [useMenuActionDialog]="false"
           [showSearchButton]="false"
           [showSelectButton]="false"
           [showActions]="showActions"
           [showAddButton]="false"
           [showEditButton]="false"
           [showDeleteButton]="true"		
           [showMenuButton]="false"
           [showListViewButton]="false"
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedOrgUser($event)"		  
           (submitEvent)="onSubmit($event)"
           (deleteObjEvent)="onDeleteObj($event)"
           (cancelEvent)="onDone()"
	   (doneEvent)="onDone()">
       </app-org-user-list>
       </div>
       <mat-divider [inset]="true"></mat-divider>	
       </div>     
    </mat-tab>
    <mat-tab *ngIf="selectedOrg" class="form-tab" label="Contacts">
      <ng-template mat-tab-label>
         <mat-icon class="tab-icon">contact_mail</mat-icon>
          Contacts
      </ng-template>
      <div *ngIf="selectedOrg">
       <div *ngIf="orgUserMode!==DisplayMode.VIEW && orgUserMode!==DisplayMode.EDIT">
	 <!---           [objData]="selectedOrgUser"  -->
       <app-org-contact-list #objFormItem
           style="width: 100%"
           [iam]="iam"			  
           [mode]="'SL'"
	   [userInfo]="userInfo"
           [userData]="userData"
           [selectedObj]="selectedOrgContact"
           [selectedOrg]="selectedOrg"
           [useMenuActionDialog]="false"
           [showSearchButton]="false"
           [showSelectButton]="false"
           [showActions]="showActions"
           [showAddButton]="false"
           [showEditButton]="false"
           [showDeleteButton]="true"		
           [showMenuButton]="true"
           [showListViewButton]="true"
           (prevEvent)="prevObj()"
           (nextEvent)="nextObj()"
           (refreshEvent)="refreshList()"
           (selectObjEvent)="setSelectedOrgContact($event)"
           (submitEvent)="onSubmit($event)"
	   (deleteObjEvent)="onDeleteObj($event)"
           (cancelEvent)="onDone()"
	   (doneEvent)="onDone()">
       </app-org-contact-list>
       </div>
       <mat-divider [inset]="true"></mat-divider>	
       </div>     
    </mat-tab>
    <mat-tab *ngIf="selectedOrg" class="form-tab" label="Account">
      <ng-template mat-tab-label>
         <mat-icon class="tab-icon">account_balance</mat-icon>
         Subscription
         <!-- <mat-icon class="tab-icon">subscriptions</mat-icon> --->
      </ng-template>
    </mat-tab>
    <mat-tab *ngIf="selectedOrg" class="form-tab" label="Activity">
      <ng-template mat-tab-label>
         <mat-icon class="tab-icon">analytics</mat-icon>
          Activity
      </ng-template>
    </mat-tab>
</mat-tab-group>

</div> <!-- app-org-tab -->

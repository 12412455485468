import { Component, OnInit, AfterViewInit, OnChanges, SimpleChanges,
         OnDestroy,  Input, Output, ViewChild, ViewChildren, EventEmitter, ElementRef,
         ComponentRef } from '@angular/core';

import { ChangeDetectorRef } from '@angular/core';
import { NgFor, AsyncPipe} from '@angular/common';
// import { lastValueFrom } from 'rxjs';
import { Subscription, Subject, Observable, combineLatest } from 'rxjs';
import { filter, map, startWith, tap } from "rxjs/operators";

import { SelectionModel } from '@angular/cdk/collections';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

//import { OrgModule } from '../../org/org.module';
//import { OrgComponent } from '../../org/org.module';

import { ToasterService } from '../../service/toaster.service';

import { AuthService } from '../../service/auth.service';
import { OrganizationService } from '../../service/organization.service';
import { OrgUserService } from '../../service/orguser.service';

import { DocumentService } from '../../service/document.service';
import { GroupService } from '../../service/group.service';
import { GroupMemberService } from '../../service/group-member.service';
import { UserService } from '../../service/user.service';

import { UserData } from '../../model/userdata.model';
import { UserInfo } from '../../model/userinfo.model';

// import { OrgComponent } from '../../org/org.component';
// import { OrgListComponent } from '../../org-list/org-list.component';
// import { OrgUserComponent } from '../../org-user/org-user.component';
// import { OrgUserListComponent } from '../../org-user-list/org-user-list.component';

import { GroupComponent } from '../group/group.component';
import { GroupListComponent } from '../group-list/group-list.component';
import { GroupFileComponent } from '../group-file/group-file.component';
import { GroupFileListComponent } from '../group-file-list/group-file-list.component';
import { GroupMemberListComponent } from '../group-member-list/group-member-list.component';

import { GroupData } from '../model/groupdata.model';
import { GroupMember } from '../model/groupmember.model';
import { DocumentData } from '../../document/model/documentdata.model';

import { OrgData } from '../../org/model/orgdata.model';
import { OrgUserData } from '../../org/model/orguserdata.model';
import { OrgUserInfo } from '../../org/model/orguserinfo.model';
import { OrgContactData } from '../../org/model/orgcontactdata.model';

import { SortKey } from '../../pipe/sortkey.pipe';
import { FilterKeys } from '../../pipe/filterkeys.pipe';
import { NameFilter } from '../../pipe/name-filter.pipe';

// import * as GRPLIST from '../group-list.component'
// import * as ORG from '../../org/org/org.component'
import * as LISTS from '../../mdtools/select-lists.common';
import * as STATIC from '../../mdtools/statics.common';

export const SPACES_REGEX = '\\s+';
export const STRIP_CHARS = '\b\f\n\r\t\v';
export const EMAIL_SEPARATORS = '\b\f\n\r\t\v';
export const EMAIL_ADDRESS_ENTRY_LIMIT = 10000;

@Component({
   selector: 'app-group-tab',
   templateUrl: './group-tab.component.html',
   styleUrls: ['./group-tab.component.css'],
})
export class GroupTabComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

   DisplayMode: typeof STATIC.Mode = STATIC.Mode;
   DisplayTemplate: typeof STATIC.Template = STATIC.Template;

   ObjDisplayMode: typeof STATIC.Mode = STATIC.Mode;

   @Input()  mode = this.DisplayMode.SELECT;
   @Input()  iam = false;
   @Input()  showTitleFilter = true;
   @Input()  showSearchButton = true;
   @Input()  showSelectButton = true;
   @Input()  useMenuActionDialog = true;
   @Input()  showActions = true;
   @Input()  showAddButton = true;
   @Input()  showInfoButton = false;
   @Input()  showEditButton = false;
   @Input()  showDeleteButton = false;
   @Input()  showMenuButton = true;
   @Input()  showListViewButton = true;
   @Input()  showObjSelect = true;      
   @Input()  template = this.DisplayTemplate.SELECT;
   @Input()  objUID: string;
   @Input()  userInfo: UserInfo;
   @Input()  userData: UserData;
   @Input()  selectedObj: any | null = null;
   @Input()  selectedGroup: GroupData | null = null;
   @Input()  selectedGroupMember: GroupMember | null = null;
   @Input()  selectedGroupFile: DocumentData | null = null;
   
   @Input()  selectedOrg: OrgData | null = null;
   @Input()  selectedOrgUser: OrgUserData | OrgUserInfo | null = null;
   @Input()  selectedOrgContact: OrgContactData | null = null;
   
   @Input()  objData: any[];
   @Input()  title: string;
   @Input()  titleFilter = '';
   @Input()  doSubmit$: Subject<boolean>;
   @Input()  doReset$: Subject<boolean>;
   @Input()  doEdit$: Subject<boolean>;
   @Input()  doDelete$: Subject<boolean>;
   @Input()  doView$: Subject<boolean>;

   // @Output() getMembersEvent = new EventEmitter<any>();
   // @Output() addMemberEvent = new EventEmitter<any>();
   // @Output() removeMemberEvent = new EventEmitter<any>();

   // @Output() refreshListEvent = new EventEmitter<any>();
   @Output() refreshEvent = new EventEmitter<any>();   
   @Output() modeEvent = new EventEmitter<any>();
   @Output() viewObjEvent = new EventEmitter<any>();
   @Output() editObjEvent = new EventEmitter<any>();
   @Output() createObjEvent = new EventEmitter<any>();
   @Output() deleteObjEvent = new EventEmitter<any>();
   @Output() submitEvent = new EventEmitter<any>();   
   @Output() doneEvent = new EventEmitter<any>();
   
   @Output() selectObjEvent = new EventEmitter<any>();
   @Output() selectGroupEvent = new EventEmitter<any>();
   @Output() selectGroupMemberEvent = new EventEmitter<any>();
   @Output() selectGroupFileEvent = new EventEmitter<any>();   
   @Output() selectOrgEvent = new EventEmitter<any>();
   @Output() selectOrgUserEvent = new EventEmitter<any>();
   @Output() selectOrgContactEvent = new EventEmitter<any>();


   @ViewChild(MatTable, {static: false})table: MatTable<any>;
   @ViewChild(MatSort, { static: false }) sort: MatSort;
   @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
   @ViewChild('grpTable', {static: false}) orgTable: ElementRef;
   @ViewChild('grplist', {static: false}) orglist: ElementRef;
   @ViewChild('scrUp', {static: false}) scrollUp: ElementRef;
   @ViewChild('scrDn', {static: false}) scrollDown: ElementRef;
   @ViewChild('emailAddr', {static: true}) emailAddr;
   @ViewChild('emailInput', {static: true}) emailInput: ElementRef;
   @ViewChild('objListItem', {static: false}) objListItem: GroupComponent;
   @ViewChild('objFormItem', {static: false}) objFormItem: GroupComponent;
   @ViewChildren('objFormItem') objFormItems: any;
   //@ViewChild('objListItem', {static: false}) orgListItem: OrgComponent;
   //@ViewChild('orgFormItem', {static: false}) orgFormItem: OrgComponent;
   @ViewChild('autoCombo') public autoComplete: MatAutocomplete;
   @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

   // Tab
   maxIndex = 2;
   currentTab = 0;
   currentObj: any = null;
   currentObjMode: any = null;
   grpListMode = this.DisplayMode.SELECT_LIST;
   grpFileListMode = this.DisplayMode.SELECT_LIST;
   adminDisplayedColumns: string[];
   dataSource: MatTableDataSource<any>;
   selection = new SelectionModel<any>(true, []);
   // checked: boolean;
   // indeterminate: boolean;
   searchText: string;

   // Instance vars
   isObjAdmin = false;
   grpCount: number;
   grpList: GroupData[] = [];

   loading: boolean;

   // View
   objLabel = 'Group';
   toggleFiberOrgs = false;
   isOrgsVisible = false;
   filterValue: string;

   // Mode
   objMode = this.ObjDisplayMode.VIEW;
   prevMode: string = null;
   memberListMode = this.ObjDisplayMode.SELECT_LIST;
   fileListMode = this.ObjDisplayMode.SELECT_LIST;

   // Form
   comboForm: FormGroup;
   objNameInput = new FormControl('');
   objSelectForm: FormGroup;
   formFieldStyle = 'fill';  // or outline
   viewOnly = false;

   // subscriptions
   orgListSubscription: any;
   groupListSubscription: any;
   // orgUserSubscription: any;

   constructor(
      public toast: ToasterService,
      private fb: FormBuilder,
      private auth: AuthService,
      private usersvc: UserService,
      public groupsvc: GroupService,
      public groupmembersvc: GroupMemberService,
      public documentsvc: DocumentService,
      public orgsvc: OrganizationService,
      // public orgusersvc: OrgUserService,
      public changeDetectorRef: ChangeDetectorRef,
      ) {
      } // constructor

   ngOnInit(): void {
      console.log('group-tab iam=', this.iam);
      this.userData = this.auth.getTokenInfo();

      if (! this.userData) {
        console.error('GroupTabComponent WARNING userData not defined.');
      }

      // if (! this.userInfo) {
      //  console.error('GroupTabComponent WARNING userInfo not defined.');
      // }

      this.refreshList();

      if ( !this.template ) {
         this.template = this.DisplayTemplate.SELECT;
      }

      this.objNameInput = new FormControl();
      this.comboForm = new FormGroup( {
         objNameInput: new FormControl()
      });

      if (this.objData) {
         console.log('group-tab afterViewInit objData=', this.objData);
         this.setSelectedObj(this.objData);
      }
      if (this.selectedObj) {
         console.log('group-tab afterViewInit selectedObj=', this.selectedObj);
         this.setSelectedObj(this.selectedObj);
      }
      if (this.selectedOrg) {
         console.log('group-tab onInit selectedOrg=', this.selectedOrg);
         this.setSelectedOrg(this.selectedOrg);
      }
      if (this.selectedGroup) {
         console.log('group-tab onInit selectedGroup=', this.selectedGroup);
         this.setSelectedGroup(this.selectedGroup);
      }
      /***
      if (this.selectedOrgUser) {
         // console.log('group-tab onInit selectedOrgUser=', this.selectedOrgUser);
         this.setSelectedOrgUser(this.selectedOrgUser);
      }
      if (this.selectedOrgContact) {
         // console.log('group-tab onInit selectedOrgContact=', this.selectedOrgContact);
         this.setSelectedOrgContact(this.selectedOrgContact);
      }
      ****/
      if (this.selectedObj) {
         // console.log('group-tab onInit selectedOrgUser=', this.selectedOrgUser);
         this.setSelectedObj(this.selectedObj);
      }

      // Subscribe to action observables
      //
      /***
      if ( this.doSubmit$ ) {
        this.doSubmit$.subscribe( v => {
           console.log('group-tab onInit doReset v=', v);
           if ( v === true ) {
             this.submit();
             // this.doSubmit = false;
           }
        });
      }
      if ( this.doReset$ ) {
        this.doReset$.subscribe( v => {
           if ( v === true ) {
             this.reset();
             // this.doSubmit = false;
           }
        });
      }
      if ( this.doEdit$ ) {
        this.doEdit$.subscribe( v => {
           if ( v === true ) {
             this.edit();
           }
        });
      }
      if ( this.doDelete$ ) {
        this.doDelete$.subscribe( v => {
           if ( v === true ) {
             this.delete();
           }
        });
      }
      if ( this.doView$ ) {
        this.doView$.subscribe( v => {
           console.log('group-tab onInit doView v=', v);
           if ( v === true ) {
             this.view();
           }
        });
       }
      ***/

      // console.log('group-tab onInit: doSubmitTab$ =', this.doSubmitTab$);
   }

   async ngAfterViewInit(): Promise<void> {
      // this.refreshGroupTab();
      this.userData = this.auth.getTokenInfo();

      if (this.selectedObj) {
       this.setSelectedObj(this.selectedObj);
      }
      if (this.selectedGroup) {
       this.setSelectedGroup(this.selectedGroup);
      }

      // this.getCurrentTabObj();

      console.log('group-tab selectedGroup=', this.selectedGroup);

      //console.log('group-tab _auto=',
      //             this._auto.autocomplete.options.toArray());

      if (this.selectedObj) {
         console.log('group-tab afterViewInit selectedObj=', this.selectedObj);
         this.setSelectedObj(this.selectedObj);
      }
      if (this.selectedOrg) {
         console.log('group-tab onInit selectedOrg=', this.selectedOrg);
         this.setSelectedOrg(this.selectedOrg);
      }
      /****
      if (this.selectedOrgUser) {
         // console.log('group-tab onInit selectedOrgUser=', this.selectedOrgUser);
         this.setSelectedOrgUser(this.selectedOrgUser);
      }
      if (this.selectedOrgContact) {
         // console.log('group-tab onInit selectedOrgContact=', this.selectedOrgContact);
         this.setSelectedOrgContact(this.selectedOrgContact);
      }
      ***/
      if (this.selectedGroup) {
         console.log('group-tab onInit selectedGroup=', this.selectedGroup);
         this.setSelectedGroup(this.selectedGroup);
      }
      if (this.selectedGroupFile) {
         console.log('group-tab onInit selectedGroupFile=', this.selectedGroupFile);
         this.setSelectedGroupFile(this.selectedGroupFile);
      }
      if (this.selectedGroupMember) {
         console.log('group-tab onInit selectedGroupMember=', this.selectedGroupMember);
         this.setSelectedGroupMember(this.selectedGroupMember);
      }
      if (this.selectedObj) {
         // console.log('group-tab onInit selectedOrgUser=', this.selectedOrgUser);
         this.setSelectedObj(this.selectedObj);
      }
   }

   ngOnChanges(changes: SimpleChanges) {
     // here you will get the data from parent once the input param is change
      // console.log('group-tab changes=', changes);
      if ( changes.selectedObj?.currentValue ) {
         this.setSelectedObj(changes.selectedObj.currentValue);
      }
      if ( changes.mode ) {
         this.mode = changes.mode.currentValue;
         this.setMode(this.mode);
      }
      if ( changes.titleFilter ) {
         this.applyTableFilter( changes.titleFilter.currentValue );
      }
      if ( changes.selectedOrg ) {
         this.setSelectedOrg( changes.selectedOrg.currentValue );
         console.log('group-tab selectedOrg=', this.selectedOrg);
      }
      if ( changes.selectedGroup ) {
         this.setSelectedGroup( changes.selectedGroup.currentValue );
         console.log('group-tab selectedGroup=', this.selectedGroup);
      }
      if ( changes.selectedGroupFile ) {
         this.setSelectedGroupFile( changes.selectedGroupFile.currentValue );
         console.log('group-tab selectedGroupFile=', this.selectedGroupFile);
      }
      if ( changes.selectedGroupMember ) {
         this.setSelectedGroup( changes.selectedGroupMember.currentValue );
         console.log('group-tab selectedGroupMember=', this.selectedGroupMember);
      }
      
      /***
      if ( changes.selectedOrgUser ) {
         this.setSelectedOrgUser( changes.selectedOrgUser.currentValue );
         console.log('group-tab selectedOrgUser=', this.selectedOrgUser);
      }
      if ( changes.selectedOrgContact ) {
         this.setSelectedOrgContact( changes.selectedOrgContact.currentValue );
         console.log('group-tab selectedOrgContact=', this.selectedOrgContact);
      }
      ****/
      
      if ( changes.selectedObj ) {
         this.setSelectedObj( changes.selectedObj.currentValue );
         console.log('group-tab selectedObj=', this.selectedObj);
      }


   }

   applyTableFilter(val) {
     if ( this.dataSource && val ) {
        this.dataSource.filter = val.trim().toLowerCase();
     }
   }

   clearTableFilter(val) {
     if (this.dataSource) {
        this.dataSource.filter = '';
     }
   }

   ngOnDestroy(): void {
     // unsubscribe to ensure no memory leaks
     if( this.orgListSubscription ) {
        this.orgListSubscription.unsubscribe();
     }
     if( this.groupListSubscription ) {
        this.groupListSubscription.unsubscribe();
     }
     // console.log('group-tab ondestroy');
   }

   private _filter(value: string): GroupData[] {
     return this.groupsvc.userGroupList.filter(obj =>
       obj.name.toLowerCase().includes(value.toLowerCase())
     );
   }

   /****
   private _filterOrgNames(value: string): OrgData[] {
     // if ( value == '' ) { return this.orgsvc.OrgList }
     // console.log('filter org names filterValue =', filterValue);
     // const newlist = this.orgsvc.OrgList;
     // const olist = newlist.filter(o => {
     //   if (o.org_name.toLowerCase().includes(filterValue)) {
     //    return o;
     //   }
     // });
     // console.log('filter olist = ', olist );
     // return olist;
     // if( value && value !== null) {
     //   if (value == '')
     //     { return new Array<OrgData>(); }
        const filterValue = (''+value).toLowerCase();
        if ( this.groupsvc.userGroupList ) {
           return this.groupsvc.userGroupList.filter(g => g.name.toLowerCase().includes(filterValue));
        } else {
             return null;
          }
     //}
   }
   ******/

   clearComboInput() {
      this.objNameInput.patchValue('');
   }

   setComboOption(uid: string) {
      const opts = this._auto.autocomplete.options.toArray();
      if ( opts ) {
        const item = opts.find( o => o.value.uid === uid );
        if (item) {
          this.objNameInput.setValue(item.value);
        }
      }
   }

   async refreshList() {
     this.setMode(this.mode);

     // console.log('group-tab refreshList');

     /*****
     if ( this.userData ) {
       if ( this.mode === this.DisplayMode.TABLE ) {
          this.refreshObjTable();
     } else {
           console.log('group-tab refreshList');
           this.groupsvc.getUserGroupData$( this.userData.uid );
       }
     }
     *****/

     // Send to parent component to update its content.
     this.refreshEvent.emit();
     
     if ( this.userData && this.userData.iam && this.iam) {
         // console.log('App Mgr get all orgs...');
         if ( this.mode === this.DisplayMode.TABLE ) {
            this.refreshObjTable();
         } else {
              // console.log('group-tab refresh get all groups...');
              this.groupsvc.getAllGroups(this.userData);
           }
     } else {
            // console.log('Get user orgs...');
            if ( this.mode === this.DisplayMode.TABLE ) {
                this.refreshObjTable();
            } else {
                  this.groupsvc.getUserGroups( this.userData.uid );
              }
        }
   }

   createForm() {
      this.objSelectForm = this.fb.group({
               org_select_field: this.viewOnly ?
                                 [{value: '', disabled: true}] :
                                 [''] });
   }

   setForm() {
     if ( this.objSelectForm && this.selectedObj ) {
        this.objSelectForm.patchValue(
           { obj_select_field: this.selectedObj ? this.selectedObj.uid : null
           }
        );
     }
   }

   async refreshObjTable() {
      /****
      if ( this.userData && this.userData.iam && this.iam) {
          this.grpList = await this.groupsvc.getAllGroups(this.userData);
      } else {
            this.grpList = await this.groupsvc.getUserGroups( this.userData.uid );
        }

      this.dataSource = new MatTableDataSource<OrgData>(this.orgsvc.OrgList);

      if ( this.paginator ) {
         this.dataSource.paginator = this.paginator;
      }
      if ( this.sort ) {
         this.dataSource.sort = this.sort;
      }
      *********/
   }

   setTemplate(t) {
      this.template = t;
      // console.log('setTemplate t=', t);
   }

   setPrevMode() {
      if ( this.prevMode !== null ) {
          this.setMode( this.prevMode );
      }
   }

   toggleShowObjSelect() {
      this.showObjSelect = !this.showObjSelect;
   }

   setMode(m) {
     this.mode = m;
     this.modeEvent.emit(this.mode);
   }

   setGroupMemberMode(m) {
     this.mode = m;
     this.modeEvent.emit(this.mode);
   }

  toggleChecked(o) {
     // console.log('org-list toggle checked e=', o);
     if ( this.selectedObj && this.selectedObj.uid === o.uid ) {
         this.setSelectedObj(null);
     } else {
          this.setSelectedObj(o);
       }
  }

  onSelectionChange(evt) {
      // console.log('group-tab grpSelectionChange evt=', evt);
      this.setSelectedObj(evt.value);
  }

  setSelectedObjEvent(event) {
      // console.log('group-tab setSelectedObjEvent called event=', event);
      this.setSelectedObj(event.option.value);
      this.selectObjEvent.emit(event.option.value);
  }

  setSelectedOrgEvent(event) {
      // console.log('group-tab setSelectedOrgEvent called event=', event);
      this.setSelectedOrg(event.option.value);
  }

  setSelectedOrgUserEvent(event) {
      // console.log('group-tab setSelectedOrgUserEvent called event=', event);
      this.setSelectedOrgUser(event.option.value);
  }

  setSelectedOrgContactEvent(event) {
      // console.log('group-tab setSelectedOrgContactEvent called event=', event);
      this.setSelectedOrgContact(event.option.value);
  }

  setSelectedOrg(e) {
      console.log('group-tab selectedOrg=', this.selectedOrg);
      if ( e === "NONE" ) {
         this.selectedOrg = null;
      } else {
           this.selectedOrg = e;
        }
      this.selectOrgEvent.emit(e);
  }

  setSelectedOrgUser(e) {
      console.log('group-tab selectedOrgUser=', this.selectedOrgUser);
      if ( e === "NONE" ) {
         this.selectedOrgUser = null;
      } else {
           this.selectedOrgUser = e;
        }
      this.selectOrgUserEvent.emit(e);
  }

  setSelectedOrgContact(e) {
      console.log('group-tab selectedOrgContact=', this.selectedOrgContact);
      if ( e === "NONE" ) {
         this.selectedOrgContact = null;
      } else {
           this.selectedOrgContact = e;
        }
      this.selectOrgContactEvent.emit(e);
  }

  setSelectedGroupEvent(event) {
      console.log('group-tab setSelectedOrgEvent called event=', event);
      this.setSelectedGroup(event.option.value);
  }

  setSelectedGroup(e) {
      console.log('group-tab selectedGroup=', this.selectedGroup);
      if ( e === "NONE" ) {
         this.selectedGroup = null;
      } else {
           this.selectedGroup = e;
        }
      if ( this.objNameInput && this.selectedGroup ) {
        this.objNameInput.patchValue(this.selectedGroup.name);
      } else {
           this.objNameInput.patchValue('');
        }
      this.selectGroupEvent.emit(e);
      this.isObjAdmin = this.getIsObjAdmin(this.selectedGroup, this.userData);            
  }
  
  setSelectedGroupMember(e) {
      console.log('group-tab selectedGroupMember=', this.selectedGroupMember);
      if ( e === "NONE" ) {
         this.selectedGroupMember = null;
      } else {
           this.selectedGroupMember = e;
        }
      this.selectGroupMemberEvent.emit(e);
  }

  setSelectedGroupFile(e) {
      console.log('group-tab selectedGroupFile=', this.selectedGroupFile);
      if ( e === "NONE" ) {
         this.selectedGroupFile = null;
      } else {
           this.selectedGroupFile = e;
        }
      this.selectGroupFileEvent.emit(e);
  }

  async setSelectedObj(e) {
      console.log('group-tab setSelectedObj e=', e);

      if ( e === "NONE" ) {
         this.selectedObj = null;
      } else {
           this.selectedObj = e;
           this.setForm();
        }
      this.selectObjEvent.emit(e);

      if ( this.objNameInput && this.selectedObj ) {
        this.objNameInput.patchValue(this.selectedObj.name);
        // this.comboForm.patchValue({objNameInput: this.selectedObj.org_name});
        // console.log('patched value for objNameInput=', this.selectedObj.org_name);
      } else {
           this.objNameInput.patchValue('');
           // console.log('patched EMPTY value for objNameInput');
        }

      if ( this.mode == this.DisplayMode.SELECT ) {
         this.template = this.DisplayTemplate.SELECT;
      }
      if ( this.objFormItem ) {
         this.objFormItem.setObjData(this.selectedObj);
      }
      this.isObjAdmin = this.getIsObjAdmin(this.selectedObj, this.userData);
      this.setSelectedGroup(e);      
  }

  nextObj() {
    let idx = 0;

    // console.log('next: value = ', this.orgsvc.getOrgListData());

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid == this.selectedObj.uid) {
                                   return true;
                                }
       })
       // console.log('nextObj current idx=', idx);
       // console.log('nextObj selected=', this.selectedObj);
    }
    if ( idx >= 0 && (idx+1) < this.groupsvc.getUserGroupList().length ) {
       this.setSelectedObj(this.orgsvc.OrgList[idx+1]);
       // console.log('new selectedObj =', this.selectedObj);
    }
  }

  prevObj() {
    let idx = 0;

    if ( this.selectedObj ) {
       idx = this.groupsvc.getUserGroupList().findIndex( element => {
                                if (element.uid === this.selectedObj.uid) {
                                   return true;
                                }
       })
       // console.log('prevOrg current idx=', idx);
    }
    if ( idx >= 0 ) {
       if ( idx === 0 ) {
           this.selectedObj = null;
       } else {
           this.setSelectedObj(this.orgsvc.OrgList[idx-1]);
         }
    }
  }

  viewObj() {
     console.log('view obj.');
     this.viewObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.VIEW);
     this.getCurrentTabObj();
  }

  createObj() {
     console.log('create obj.');
     this.createObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.ADD);
  }

  editObj() {
     console.log('edit obj.');
     this.editObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.EDIT);
     this.getCurrentTabObj();
  }

  deleteObj() {
     console.log('delete obj.');
     this.deleteObjEvent.emit(this.selectedObj);
     this.setMode(this.DisplayMode.DELETE);
  }

  onDeleteObj(e) {
     this.deleteObjEvent.emit(e);
     console.log('group-tab: delete obj event emitted.');     
  }

  onSubmit(obj: any) {
    console.log('group-tab onSubmit obj=', obj);
    this.showObjSelect = true;
    this.selectedObj = obj;
    this.setPrevMode();
    this.refreshList();
    this.objNameInput.patchValue( obj.name );
    this.doneEvent.emit(this.selectedObj);
    this.selectObjEvent.emit(this.selectedObj);
    console.log('group-tab onSubmit selectedObj=', this.selectedObj);
  }

  submit() {
    console.log('group-tab submit');
    // this.setPrevMode();
    // this.objNameInput.patchValue( obj.org_name );
    // this.doneEvent.emit(this.selectedObj);
    // this.selectObjEvent.emit(this.selectedObj);
    if ( this.objFormItem ) {
      // console.log('form-dialog submit currentObj=', this.objFormItem);
      this.objFormItem.onSubmit();
    }
    this.refreshList();
    // console.log('group-tab onSubmit selectedObj=', this.selectedObj);
    // this.doSubmitTab$[this.currentTab].next(true);
  }

  /*****
  reset() {
    this.doResetTab$[this.currentTab].next(true);
  }

  edit() {
    this.doEditTab$[this.currentTab].next(true);
  }

  delete() {
    this.doDeleteTab$[this.currentTab].next(true);
  }

  view() {
    this.doViewTab$[this.currentTab].next(true);
  }
  ********/
  
  onCancel() {
     console.log('group-tab onCancel event.');
     this.showObjSelect = true;
     this.setPrevMode();
  }

  onDone() {
     console.log('group-tab onDone event.');
     this.doneEvent.emit(this.selectedOrg);
     this.showObjSelect = true;
     this.setPrevMode();
     // this.orgUserMode = this.DisplayMode.SELECT_LIST;
  }

  getCurrentTabObj() {
     const objArray = this.objFormItems.toArray();
     this.currentObj = objArray[this.currentTab];
     // console.log('group-tab currentObj=', this.currentObj );
     if (this.currentObj && this.currentObj.mode) {
       this.mode = this.currentObj.mode;
       this.modeEvent.emit(this.currentObj.mode);
       // console.log('group-tab currentObj mode=', this.currentObj.mode );
     } else {
         this.mode = this.DisplayMode.VIEW;
       }
  }

  onTabChange(e) {
     this.currentTab = e.index;
     // console.log('onTabChange e=', e);
     // console.log('index=', this.currentTab);
     // console.log('objFormItem=', this.objFormItem);
     // console.log('objFormItems=', this.objFormItems);
     this.getCurrentTabObj();
  }

  getOptionName(o) {
     // console.log('org-list getOptionName o=', o);
     const name = o ? o.org_name : undefined;
     /****
     let owns = '';
     const star = '&#9733';
     if (this.userData && this.userData.uid && o && o.owner &&
         this.userData.uid===o.owner) {
         owns = star;
     }
     ****/
     // console.log('org-list getOptionName name=', name);
     return name;
  }

  /***
  displayGroupName(grp){
     if(grp) {
        return grp.name;
     }
  }
  ***/

  /***
  updateLocalItem(newItem){
    //let idx = this.objList.findIndex(obj => obj.uid === newItem.uid);
    //this.objList[idx] = newItem;
    //this.objList = Object.assign([], this.objList);
  }
  ***/

  updateItem(newItem) {
     this.groupsvc.updateItem(newItem);
     this.setSelectedObj(newItem);
  }

  compareObjs(o1: any, o2: any): boolean {
     if ( o1 == null || o2 == null )
        { return false; }
     const result = o1.uid === o2.uid;
     // console.log('compare objs result=', result);
     return result;
  }

  getIsObjAdmin(obj: any, uData: UserData) {
     let obj_admin = false;
     if (obj && 'uid' in obj && uData && 'uid' in uData) {
        if ( obj.owner === uData.uid ) {
            obj_admin = true;
        } else {
            obj_admin = false;
          }
     }
     if ( uData.iam && this.iam ) {
        return true;
     }
     return obj_admin;
  }


  // Table Pagination Change
  onTableChangePage(pe:PageEvent) {
    console.log(pe.pageIndex);
    console.log(pe.pageSize);
  }

  // Table manipulation functions
  //
  public applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.filterValue = filterValue;
    this.dataSource.filter = filterValue;
    // console.log('ObjListComponent filterValue=', filterValue);
  }

  // Whether the number of selected elements matches the total number
  // of rows.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection.  */
  masterToggle(): void {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

   // Scroll functions
   // member list scroll
   scroll_down(): void {
      // console.log('OLC orglist = ', this.orglist);
      const md = this.orglist.nativeElement;
      const su = this.scrollUp.nativeElement;
      const sd = this.scrollDown.nativeElement;
      const scrollPosition = md.scrollTop;
      const scrollHeight = md.scrollHeight;
      // console.log('md = ', md);
      // console.log('su = ', su);
      // console.log('sd = ', sd);

      if ( scrollPosition === (scrollHeight - 135)){
          sd.style.opacity = '.4';
      } else {
          sd.style.opacity = '1';
      }
      su.style.opacity = '1';
      md.scrollTop += 20;
    }

    scroll_up(): void {
       const md = this.orglist.nativeElement;
       const su = this.scrollUp.nativeElement;
       const sd = this.scrollDown.nativeElement;
       const scrollPositionTop = md.scrollTop;
       // const scrollHeight = md.scrollHeight;
       // console.log('md = ', md);
       // console.log('su = ', su);
       // console.log('sd = ', sd);

       if (scrollPositionTop === 0 ) {
            su.style.opacity = '.4';
        } else {
            su.style.opacity = '1';
        }
       sd.style.opacity = '1';
       md.scrollTop -= 20;
    }

    openCreateGroupDialog() {
    }
    openRenameGroupDialog(g) {
    }
    openDeleteGroupDialog(guid, name) {
    }
    refreshData() {
    }
} // group-tab
